import React from "react";
import { Link } from "react-router-dom";

import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import RoundedButton from "components/Buttons/RoundedButton";

export default function BillingAddressGrid({ labelWidthXs, valueWidthXs, classes, billingAddress }) {
  return (
    <>
      <div className={classes.subheader}>
        <Typography variant="h6">Billing Address</Typography>
        <RoundedButton color="secondary" size="small" startIcon={<EditIcon />} component={Link} to="/account/billingAddress/edit">
          Edit
        </RoundedButton>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={labelWidthXs}>
          Address 1
        </Grid>
        <Grid item xs={valueWidthXs} className={classes.value}>
          {billingAddress ? billingAddress.address1 : "-"}
        </Grid>

        <Grid item xs={labelWidthXs}>
          Address 2
        </Grid>
        <Grid item xs={valueWidthXs} className={classes.value}>
          {billingAddress ? billingAddress.address2 : "-"}
        </Grid>

        <Grid item xs={labelWidthXs}>
          City
        </Grid>
        <Grid item xs={valueWidthXs} className={classes.value}>
          {billingAddress ? billingAddress.city : "-"}
        </Grid>

        <Grid item xs={labelWidthXs}>
          State / Province
        </Grid>
        <Grid item xs={valueWidthXs} className={classes.value}>
          {billingAddress ? billingAddress.region : "-"}
        </Grid>

        <Grid item xs={labelWidthXs}>
          Zip
        </Grid>
        <Grid item xs={valueWidthXs} className={classes.value}>
          {billingAddress ? billingAddress.postal_code : "-"}
        </Grid>

        <Grid item xs={labelWidthXs}>
          Country
        </Grid>
        <Grid item xs={valueWidthXs} className={classes.value}>
          {billingAddress ? billingAddress.country : "-"}
        </Grid>
      </Grid>
    </>
  );
}
