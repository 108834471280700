import React from "react";
import { Link } from "react-router-dom";

import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import RoundedButton from "components/Buttons/RoundedButton";

export default function PrefferedShippingGrid({ labelWidthXs, valueWidthXs, classes, companyInfo }) {
  return (
    <>
      <div className={classes.subheader}>
        <Typography variant="h6">Preferred Shipping Carrier</Typography>
        <RoundedButton
          color="secondary"
          size="small"
          startIcon={<EditIcon />}
          component={Link}
          to="/account/preferredCarrier/edit"
        >
          Edit
        </RoundedButton>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={labelWidthXs}>
          Carrier
        </Grid>
        <Grid item xs={valueWidthXs} className={classes.value}>
          {companyInfo.preferred_carrier_name || "-"}
        </Grid>

        <Grid item xs={labelWidthXs}>
          Account Number
        </Grid>
        <Grid item xs={valueWidthXs} className={classes.value}>
          {companyInfo.preferred_carrier_account || "-"}
        </Grid>
      </Grid>
    </>
  );
}
