import React from "react";
import { useHistory } from "react-router-dom";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import Card from "./Card";
import TestCard from "./TestCard";
import AddTestModal from "./AddTestModal";
import TableCell from "components/Tables/TableCell";
import RoundedButton from "components/Buttons/RoundedButton";
import Spinner from "components/Spinner";
import { useOrder } from "context/order";
import { formatNumberSuffix } from "utils/format";

const styles = (theme) => ({
  marginTop: {
    marginTop: theme.spacing(2)
  },
  subheader: {
    fontSize: 28
  },
  description: {
    color: "#666",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium
  },
  red: {
    color: theme.palette.error.main
  },
  comment: {
    color: theme.palette.info.main,
    fontWeight: theme.typography.fontWeightMedium,
    margin: theme.spacing(0, 0, 0, 1)
  },
  tableCell: {
    padding: 10
  },
  price: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 18
  }
});

function OrderCoatingCard({ classes, index, coating, onEdit, onDelete, onClear }) {
  const commentEl = React.useRef(null);
  const [showAddTestModal, setShowAddTestModal] = React.useState(false);

  const { updateOrderItemComment, addTestsToCoating, deleteTestFromCoating, testsLoading, cartType } = useOrder();
  const history = useHistory();

  function toggleAddTestModal() {
    setShowAddTestModal(!showAddTestModal);
  }

  function handleAddTests(tests) {
    addTestsToCoating(coating, tests);
    toggleAddTestModal();
  }

  const handleAddTestResults = (id) => () => {
    history.push(`/new/order/${coating.id}/tests/${id}`);
  };

  const handleDeleteTestResults = (test) => () => {
    deleteTestFromCoating(coating, test);
  };

  const handleUndoDeleteTestResults = (test) => () => {
    addTestsToCoating(coating, [test]);
  };

  const [testsPresent, testsNotPresent] = React.useMemo(() => {
    const testsPresent = [],
      testsNotPresent = [];
    coating.tests.forEach((test) => {
      if (test.present) {
        testsPresent.push(test);
      }

      if (!test.present || test.deleted) {
        testsNotPresent.push(test);
      }
    });

    return [testsPresent, testsNotPresent];
  }, [coating]);

  const debouncedSaveComment = React.useRef(debounce(() => updateOrderItemComment({ coating }, commentEl.current.value), 1000));

  return (
    <Card title={"Coating"} id={coating.coating_string} onEdit={onEdit} cartType={cartType} onDelete={onDelete} onClear={onClear}>
      <Grid container>
        <Grid style={{ margin: "16px 0 0 auto" }} item xs={12}>
          <Typography variant="h6" className={classes.subheader}>
            Item Information
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Coating</TableCell>
                <TableCell className={classes.tableCell}>Submission</TableCell>
                <TableCell className={classes.tableCell}>Type</TableCell>
                <TableCell className={classes.tableCell}>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell}>{coating.coating_string}</TableCell>
                <TableCell className={classes.tableCell}>{formatNumberSuffix(coating.submission_count)}</TableCell>
                <TableCell className={classes.tableCell}>{coating.full ? "Full" : "Partial"}</TableCell>
                <TableCell className={`${classes.tableCell} ${classes.price}`}>
                  ${coating.price.toFixed(2) || "Issue reteriving price"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>

      <Grid container spacing={6} className={classes.marginTop}>
        <Grid item xs={6}>
          <Typography variant="h6" className={classes.subheader}>
            Tests
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={7}>
              <Typography className={classes.description} style={{ marginBottom: 0 }} paragraph>
                Asterisk ( <span className={classes.red}>*</span> ) denotes that test results are required. Deleting one of these
                tests will require a deviation.
              </Typography>
            </Grid>
            <Grid item xs={5}>
              {/* <RoundedButton color="primary" size="small" onClick={toggleAddTestModal} disabled={testsLoading}> */}
              {/*   Add Another Test */}
              {/* </RoundedButton> */}
            </Grid>
          </Grid>

          {testsLoading ? (
            <Spinner />
          ) : (
            testsPresent.map((test) => (
              <TestCard
                key={test.id}
                coating={coating}
                test={test}
                onAdd={handleAddTestResults(test.id)}
                onDelete={handleDeleteTestResults(test)}
                onUndoDelete={handleUndoDeleteTestResults(test)}
              />
            ))
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" className={classes.subheader}>
            Additional Information
          </Typography>
          <Typography className={classes.description} paragraph>
            You can provide any other relevant information pertaining to this coating here.
          </Typography>

          <Typography className={classes.comment}>Comment:</Typography>

          <TextField
            multiline
            rows={8}
            variant="outlined"
            fullWidth
            defaultValue={coating.comment}
            inputRef={commentEl}
            onChange={debouncedSaveComment.current}
          />
        </Grid>
      </Grid>

      {showAddTestModal && <AddTestModal onCancel={toggleAddTestModal} onAdd={handleAddTests} tests={testsNotPresent} />}
    </Card>
  );
}

export default withStyles(styles)(OrderCoatingCard);
