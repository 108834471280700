import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";

import { useOrder } from "context/order";
import Modal from "components/Modal";

const COATINGS = [];

const styles = (theme) => ({
  red: {
    color: theme.palette.error.main
  }
});

const SavedProcedureModal = ({ classes, onCancel, onAdd }) => {
  const { procedures, fetchProcedures } = useOrder();
  const [selectedProcedureId, setSelectedProcedureId] = React.useState("");

  React.useEffect(() => {
    fetchProcedures();
  }, []);

  function handleChangeProcedure({ target }) {
    setSelectedProcedureId(target.value);
  }

  function handleAdd() {
    const selectedProcedure = procedures.find(({ id }) => id === selectedProcedureId);
    onAdd(selectedProcedure);
  }

  return (
    <Modal
      open={true}
      title="Saved Procedure"
      actions={
        <>
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>

          <Button className={classes.red} variant="text" disabled={!selectedProcedureId} onClick={handleAdd}>
            Add To Order
          </Button>
        </>
      }
    >
      <Typography paragraph>
        Select an existing procedure from the drop down, then click 'ADD' to add it to your order.
      </Typography>

      <FormControl variant="outlined" fullWidth>
        <InputLabel id="select-procedure-label-1">Procedure</InputLabel>
        <Select
          labelId="select-procedure-label-1"
          variant="outlined"
          value={selectedProcedureId}
          label="Procedure"
          onChange={handleChangeProcedure}
        >
          {procedures.length ? (
            procedures.map(({ id, title }) => (
              <MenuItem key={id} value={id}>
                {title}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No procedures available</MenuItem>
          )}
        </Select>
      </FormControl>
    </Modal>
  );
};

export default withStyles(styles)(SavedProcedureModal);
