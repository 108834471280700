import React from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import HttpsIcon from "@material-ui/icons/Https";

import RoundedButton from "components/Buttons/RoundedButton";
import BackButton from "components/Buttons/BackButton";
import PageHeader from "components/PageHeader";
import Spinner from "components/Spinner";
import { useGlobalUI } from "context/globalUI";
import { parseQueryString } from "utils/qs";
import { useAPI, RESOURCES } from "utils/api";

const styles = (theme) => ({
  green: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 20
  },
  red: { color: theme.palette.error.main },
  bold: {
    fontWeight: theme.typography.fontWeightMedium
  },
  light: {
    fontWeight: theme.typography.fontWeightLight
  },
  sectionHeader: {
    marginBottom: theme.spacing(2)
  },
  textContainer: {
    width: "80%"
  },
  summaryContainer: {
    margin: theme.spacing(2, 0),
    borderRadius: 4,
    padding: theme.spacing(1, 2)
  },
  summary: {
    display: "flex",
    justifyContent: "space-between"
  },
  summaryHeader: {
    fontSize: 24,
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.info.main}`
  },
  summaryKey: {
    fontSize: 20
  },
  summaryValue: {
    fontSize: 20,
    fontWeight: theme.typography.fontWeightLight
  },
  detailsContainer: {
    width: "80%"
  },
  paymentInstructions: {
    marginBottom: theme.spacing(2)
  },
  stripElementContainer: {
    width: "100%",
    border: "1px solid #d5d5d5",
    borderRadius: "4px",
    padding: `${theme.spacing(3)}px`,
    fontSize: "18px"
  },
  stripeContainer: {
    display: "flex",
    alignItems: "center"
  },
  stripElementContainer: {
    height: "auto",
    maxWidth: "500px",
    border: "1px solid #d5d5d5",
    borderRadius: "4px",
    padding: "32px"
  },
  stripeImg: {
    maxWidth: 182
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative"
  },
  securityIcon: {
    color: theme.palette.secondary.main,
    fontSize: 60,
    marginTop: `-${theme.spacing(2)}px`
  },
  securePayment: {
    position: "absolute",
    top: "28%",
    transform: "translate(16px, -50%)",
    fontWeight: theme.typography.fontWeightLight
  },
  payBtnContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2)
  },
  payButton: {
    height: 44,
    borderWidth: 4
  }
});

function Payment({ classes }) {
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [orderItemId, setOrderItemId] = React.useState(null);
  const [itemType, setItemType] = React.useState(null);
  const [priorityPrice, setPriorityPrice] = React.useState(0);
  const [salesTax, setSalesTax] = React.useState(0);

  const { navDrawer, snackbar } = useGlobalUI();
  const location = useLocation();
  const history = useHistory();
  const api = useAPI();
  const stripe = useStripe();
  const elements = useElements();

  React.useEffect(() => {
    const qs = parseQueryString(location.search);
    if (!!qs.order_item_id && !!qs.item_type) {
      setItemType(qs.item_type);
      setOrderItemId(qs.order_item_id);
      fetchPriorityPrice();
      fetchSalesTax();
    } else {
      return history.push("/home");
    }
  }, []);

  async function fetchPriorityPrice() {
    try {
      const response = await api.callWithAuth({ method: "GET", url: RESOURCES.settings("priority_price") });
      const formattedVal = await Number(response.value);
      await setPriorityPrice(formattedVal);
    } catch (e) {
      setLoading(false);
      return;
    }
  }

  async function fetchSalesTax() {
    try {
      const response = await api.callWithAuth({ method: "GET", url: RESOURCES.settings("sales_tax") });
      const formattedVal = await Number(response.value);
      await setSalesTax(formattedVal);
    } catch (e) {
      setLoading(false);
      return;
    }
    setLoading(false);
  }

  function getPrices() {
    let subTotal, tax, grandTotal;
    subTotal = priorityPrice.toFixed(2);
    tax = (priorityPrice * salesTax).toFixed(2);
    grandTotal = Number(tax) + Number(subTotal);
    return { subTotal, tax, grandTotal };
  }

  async function handleConfirm() {
    setSaving(true);
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement
    });
    if (error) {
      snackbar.open({ message: error.message, color: "error" });
      setSaving(false);
      return;
    }

    stripe.createToken(cardElement).then(({ token, error }) => {
      if (error) {
        snackbar.open({ message: error.message, color: "error" });
        setSaving(false);
        return;
      } else {
        postRequest(token.id);
      }
    });
  }

  async function postRequest(token) {
    const payload = { order_total: parseFloat(getPrices().grandTotal), stripe_token: token };

    try {
      await api.callWithAuth({
        method: "POST",
        url: RESOURCES.requestPriority(orderItemId),
        axiosOptions: { data: payload },
        successMessage: "Request submitted successfully!"
      });
    } catch (e) {
      return;
    } finally {
      setSaving(false);
    }

    history.goBack();
  }

  if (loading) {
    return <Spinner fullScreen />;
  }

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader
        title="Priority Scheduling Request & Payment"
        hint={`Provide your payment below for the priority scheduling fee.`}
      >
        <BackButton goBack={true} />
      </PageHeader>

      <Box className={classes.textContainer}>
        <Typography variant="h5" className={classes.sectionHeader}>
          How It Works...
        </Typography>
        <Typography paragraph>
          Your request for priority scheduling will be reviewed and you you will be notified by email of your priority status.
          Payment is required at the time of request, bu the transaction will only be 'Pending' until the review is complete.
        </Typography>
        <Typography paragraph>
          <span className={classes.bold}>If priority status is granted: </span>The transaction will complete upon approval. You
          will be notified via email and your card account will be charged the grand total listed below.
        </Typography>
        <Typography paragraph>
          <span className={classes.bold}>If priority status is denied: </span>The transaction will be cancelled. You will be
          notified via email and your card account WILL NOT be charged.
        </Typography>
        {/* <Typography paragraph className={classes.red}> */}
        {/*   <span className={classes.bold}>Estimated priority timeline for {itemType}:</span> 14 days */}
        {/* </Typography> */}
      </Box>

      <Grid container className={classes.detailsContainer} spacing={4}>
        <Grid item xs={6} className={classes.summaryContainer}>
          <Typography variant="h6" className={classes.summaryHeader}>
            Priority Fee Summary
          </Typography>
          <div className={classes.summary}>
            <Typography className={classes.summaryKey}>Priority Fee</Typography>
            <Typography className={classes.summaryValue}>{`$${getPrices().subTotal}`}</Typography>
          </div>
          <div className={classes.summary}>
            <Typography className={classes.summaryKey}>Tax</Typography>
            <Typography className={classes.summaryValue}>{`$${getPrices().tax}`}</Typography>
          </div>
          <div className={classes.summary}>
            <Typography className={classes.summaryKey}>Grand Total</Typography>
            <Typography className={classes.summaryValue}>{`$${getPrices().grandTotal}`}</Typography>
          </div>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" className={classes.summaryHeader}>
            Payment Information
          </Typography>
          <Typography className={classes.paymentInstructions}>
            Use this input to provide your card number, expiration date, cvc number, and postal code.
          </Typography>
          <Grid className={classes.stripeContainer} container spacing={3}>
            <Grid className={classes.iconContainer} item xs={12} sm={4}>
              <div>
                <Typography className={classes.securePayment}>SECURE PAYMENTS</Typography>
                <img className={classes.stripeImg} src="/assets/powered-by-stripe.svg" />
              </div>
              <HttpsIcon className={classes.securityIcon} />
            </Grid>
            <Grid item xs={12} sm={8} className={classes.stripElementContainer}>
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: "18px",
                      padding: "20px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4"
                      }
                    },
                    invalid: { color: "#9E1C36" },
                    complete: { color: "#259E33" }
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={classes.payBtnContainer} xs={6}>
          {saving ? (
            <Spinner />
          ) : (
            <RoundedButton onClick={handleConfirm} className={classes.payButton} color="primary">
              Pay & Submit
            </RoundedButton>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Payment);
