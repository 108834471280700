import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CancelIcon from "@material-ui/icons/Cancel";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import RoundedButton from "components/Buttons/RoundedButton";

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(6)
  },
  open: {},
  header: {
    borderBottom: `6px solid ${theme.palette.info.main}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1)
  },
  title: {
    fontSize: 36,
    color: theme.palette.info.main,
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: "nowrap",
    flexGrow: 1

    // "& > :last-child": {
    //   fontSize: 24,
    //   transition: theme.transitions.create(["font-size", "color"], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: 200
    //   })
    // },

    // "&:not($open) > :last-child": {
    //   display: "inline-block",
    //   fontSize: 14,
    //   color: "#666",
    //   marginLeft: theme.spacing(2),
    //   width: 175,
    //   whiteSpace: "nowrap",
    //   textOverflow: "ellipsis",
    //   overflow: "hidden"
    // },

    // "& > :first-child": {
    //   transition: theme.transitions.create("font-size", {
    //     easing: theme.transitions.easing.sharp,
    //     duration: 200
    //   })
    // },

    // "&$open > :first-child": {
    //   fontSize: 0
    // }
  },
  content: {
    padding: theme.spacing(1),
    position: "relative"
  },
  status: {
    margin: theme.spacing(0, 2),
    fontWeight: theme.typography.fontWeightMedium,
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap"
  },
  statusText: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.error.main
  },
  actions: {
    fontSiz: 14,
    marginBottom: theme.spacing(1),
    "& > :last-child": {
      marginLeft: theme.spacing(3)
    }
  },
  deleteButton: theme.buttons.red
});

function Card({ classes, title, id, children, onEdit, onDelete, onClear, cartType }) {
  const [open, setOpen] = React.useState(false);

  function toggle() {
    setOpen(!open);
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={`${classes.title} ${open ? classes.open : ""}`}>
          <span>{`Order Item: ${title}`}</span>
          {/* <span>{id}</span> */}
        </div>
        <div className={classes.actions}>
          {/* {onEdit && (
            <RoundedButton size="small" startIcon={<EditOutlinedIcon />} color="secondary" onClick={onEdit}>
              Edit
            </RoundedButton>
          )} */}
          <RoundedButton
            size="small"
            startIcon={<DeleteOutlinedIcon />}
            className={classes.deleteButton}
            to={"/home"}
            href={"/home"}
            onClick={cartType !== "normal" ? onClear : onDelete}
          >
            Remove
          </RoundedButton>
        </div>
        {/* <div className={classes.status}> */}
        {/*   Order Process Status: */}
        {/*   <CancelIcon className={classes.statusText} /> */}
        {/*   <span className={classes.statusText}>Incomplete</span> */}
        {/* </div> */}
        {/* <IconButton onClick={toggle}>{open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</IconButton> */}
      </div>

      <Collapse in={true}>
        <div className={classes.content}>{children}</div>
      </Collapse>
    </div>
  );
}

export default withStyles(styles)(Card);
