import React from "react";
import pick from "lodash/pick";
import { useParams, Prompt, useHistory, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import { useAPI, RESOURCES } from "utils/api";
import { useUserCompany } from "context/userCompany";
import { useGlobalUI } from "context/globalUI";
import ResetPasswordModal from "./children/ResetPasswordModal";
import RoundedButton from "components/Buttons/RoundedButton";
import TextField from "components/TextField";
import PageHeader from "components/PageHeader";
import Spinner from "components/Spinner";
import BackButton from "components/Buttons/BackButton";
import { parseQueryString } from "utils/qs";

const VALIDATION_RULES = {
  first_name: { required: true },
  last_name: { required: true },
  email: {
    required: true
  },
  phone: {
    required: true,
    pattern: {
      value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
      message: "Invalid phone number"
    }
  }
};

function EditAdmin({ classes }) {
  const { register, errors, handleSubmit, watch, reset, control, formState, setValue } = useForm({ mode: "onBlur" });
  const data = watch();

  const api = useAPI();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { companyInfo, updateData } = useUserCompany();
  const { snackbar, navDrawer } = useGlobalUI();

  const [id, setId] = React.useState(params.id ? +params.id : null);
  const [user, setUser] = React.useState(null);
  const [showResetPasswordModal, setShowResetPasswordModal] = React.useState(false);
  // const [loading, setLoading] = React.useState(!!params.id);
  const [loading, setLoading] = React.useState(!!params.id);
  const [saving, setSaving] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [pageType, setPageType] = React.useState(null);

  React.useEffect(() => {
    const qs = parseQueryString(location.search);
    setPageType(Object.keys(qs)[0]);

    if (id) {
      fetchUser(id);
    }
  }, []);

  const fetchUser = async (id) => {
    const result = await api.callWithAuth({ method: "GET", url: `${RESOURCES.users}/${id}` });
    setUser(result);
    reset(pick(result, ["first_name", "last_name", "email", "phone", "allow_view_orders"]));
    setLoading(false);
  };

  const toggleResetPasswordModal = () => {
    setShowResetPasswordModal(!showResetPasswordModal);
  };

  const contentMap = (contentType) => {
    return {
      super: {
        pageTitle: `${id ? "Edit" : "Add"} Super Admin`,
        breadcrumb: `${id ? "Edit" : "Add"} Super Admin`,
        pageHeader: "Admin Details",
        url: id ? `${RESOURCES.users}/${id}` : "/companies/super/users",
        successMessage: "Super Admin was created successfully!"
      },
      metAdmin: {
        pageTitle: `${id ? "Edit" : "Add"} Metcut Admin`,
        breadcrumb: `${id ? "Edit" : "Add"} Metcut Admin`,
        pageHeader: "Admin Details",
        url: id ? `${RESOURCES.users}/${id}` : "/companies/system/users",
        successMessage: "Metcut Admin was created successfully!"
      },
      ge: {
        pageTitle: `${id ? "Edit" : "Add"} GE User`,
        breadcrumb: `${id ? "Edit" : "Add"} GE User`,
        pageHeader: "User Details",
        url: id ? `${RESOURCES.users}/${id}` : "/companies/ge/users",
        successMessage: "GE User was created successfully!"
      },
      geAdmin: {
        pageTitle: `${id ? "Edit" : "Add"} GE Admin`,
        breadcrumb: `${id ? "Edit" : "Add"} GE Admin`,
        pageHeader: "Admin Details",
        url: id ? `${RESOURCES.users}/${id}` : "/companies/ge/admins",
        successMessage: "GE Admin was created successfully!"
      }
    }[pageType || "metAdmin"][contentType];
  };

  const handleSave = async () => {
    setSaving(true);
    const url = contentMap("url");
    const successMessage = contentMap("successMessage");
    const phone = data.phone ? `+${data.phone}` : "";

    if (!phone) {
      setSaving(false);
      return snackbar.open({ message: "Phone number is required", color: "error" });
    }

    try {
      await api.callWithAuth({
        method: id ? "PUT" : "POST",
        url,
        axiosOptions: { data: { ...data, phone } },
        successMessage
      });

      await updateData();
    } catch (e) {
      return;
    } finally {
      setSaving(false);
    }

    history.goBack();
  };

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <Prompt when={formState.dirty && !formState.isSubmitted} message="Really?" />

      <PageHeader
        title={contentMap("pageTitle")}
        breadcrumbs={["Manage Users", `${contentMap("breadcrumb")}`]}
        hint={
          "Provide the user details in the form. The new user will get an email prompting them log in with their temporary password."
        }
      >
        <BackButton url={"/manage/users"} />
      </PageHeader>

      <>
        <Typography variant="h5">{contentMap("pageHeader")}</Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            {loading ? (
              <Spinner />
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="first_name"
                    inputRef={register(VALIDATION_RULES.first_name)}
                    error={!!errors.first_name}
                    label="First Name"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    empty={!data.first_name}
                    fullWidth
                    autoFocus={!params.id}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="last_name"
                    inputRef={register(VALIDATION_RULES.last_name)}
                    error={!!errors.last_name}
                    label="Last Name"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    empty={!data.last_name}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="email"
                    inputRef={register(VALIDATION_RULES.email)}
                    error={!!errors.email}
                    label="Email"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    empty={!data.email}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    as={
                      <PhoneInput
                        name="phone"
                        containerClass={classes.phoneInput}
                        inputClass={classes.inputPhone}
                        country="us"
                        prefix="+"
                      />
                    }
                    // rules={{ required: true }}
                    name="phone"
                    control={control}
                    defaultValue={data.phone}
                  />
                </Grid>

                {error && (
                  <Grid item xs={12}>
                    <Typography color="error">{error}</Typography>
                  </Grid>
                )}

                {id && (
                  <Grid item xs={12} align="center">
                    <RoundedButton className={classes.redButton} onClick={toggleResetPasswordModal} size="large">
                      RESET PASSWORD
                    </RoundedButton>
                  </Grid>
                )}
                <Grid item xs={12} align="center">
                  {saving ? (
                    <Spinner />
                  ) : (
                    <RoundedButton color="primary" onClick={handleSubmit(handleSave)} size="large">
                      {id ? "EDIT USER" : "ADD USER"}
                    </RoundedButton>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {showResetPasswordModal && (
          <ResetPasswordModal onCancel={toggleResetPasswordModal} user={user} onSuccess={toggleResetPasswordModal} />
        )}
      </>
    </div>
  );
}

const styles = (theme) => ({
  backButton: theme.buttons.back,
  systemTypeContainer: {
    border: "1px solid lightGray",
    padding: theme.spacing(2, 3),
    display: "flex",
    width: "max-content",
    marginBottom: theme.spacing(4)
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium
  },
  value: {
    fontWeight: theme.typography.fontWeightLight
  },
  right: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    border: "1px solid #707070",
    borderRadius: 4,
    color: "#666",
    fontSize: 18
  },
  checkbox: {
    alignItems: "start"
  },
  checkboxLabel: {
    padding: theme.spacing(1)
  },
  helperText: {
    fontSize: 12,
    color: "#666"
  },
  redButton: {
    ...theme.buttons.red,
    margin: theme.spacing(2, 0)
  },
  phoneInput: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  inputPhone: {
    "&$focused": {
      color: theme.palette.secondary.main
    }
  }
});

export default withStyles(styles)(EditAdmin);
