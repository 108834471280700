import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

import { useGlobalUI } from "context/globalUI";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  anchorOrigin: {
    marginLeft: 100
  },
  success: {
    backgroundColor: theme.palette.secondary.main
  },
  info: {
    backgroundColor: theme.palette.info.main
  },
  error: {
    backgrounColor: theme.palette.error.main
  }
}));

export default function CustomSnackbar() {
  const {
    snackbar: { close, isOpen, position, message, color }
  } = useGlobalUI();
  const classes = useStyles();

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    close();
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: position ? position.vertical : "bottom", horizontal: position ? position.horizontal : "left" }}
        classes={{ anchorOriginBottomLeft: classes.anchorOrigin }}
        open={isOpen}
        autoHideDuration={8000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} className={classes[color]} severity={color}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
