import React from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({ children }) {
  const location = useLocation();

  React.useEffect(() => {
    const scroller = setTimeout(scroll, 500);

    return () => {
      clearTimeout(scroller);
    };
  }, [location]);

  function scroll() {
    return window.scrollTo(0, 0);
  }

  return <>{children}</>;
}
