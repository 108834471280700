import React, { Fragment, useState, useEffect, useMemo } from "react";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  menuItem: {
    backgroundColor: "#FFF",
    display: "flex",
    alignItems: "center"
  },
  menuItemIcon: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1)
  }
});

function ContextMenu({ options = [], onIconClick, iconContent, classes, ...rest }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOptions, setMenuOptions] = useState([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (options.length > 0) setMenuOptions(options);
  }, [options, anchorEl]);

  const handleOpen = (event) => {
    if (onIconClick) onIconClick();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (option) => {
    option.itemHandler({ ...rest });
    handleClose();
  };

  const renderIcon = (iconContent) => {
    return (
      <IconButton size={"small"} aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleOpen}>
        {iconContent ? (
          <Fragment>
            {iconContent}
            <MoreVertIcon />
          </Fragment>
        ) : (
          <MoreVertIcon />
        )}
      </IconButton>
    );
  };

  const setOptions = useMemo(
    () => (options) => {
      return options.map((option, idx) => {
        return (
          <MenuItem key={idx} onClick={() => handleItemClick(option)} className={classes.menuItem}>
            <span className={classes.menuItemIcon}>{option.icon && option.icon}</span>
            {option.name}
          </MenuItem>
        );
      });
    },
    [options]
  );

  return (
    <Fragment>
      <Menu
        variant="menu"
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ vertical: "center", horizontal: "right" }}
      >
        {menuOptions.length > 0 && setOptions(menuOptions)}
      </Menu>
      {renderIcon(iconContent)}
    </Fragment>
  );
}

export default withStyles(styles)(ContextMenu);
