import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";

export default withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  primary: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 20,
    color: "inherit"
  },
  secondary: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 20,
    color: "inherit"
  }
}))(ListItemText);
