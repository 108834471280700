import React from "react";
import pick from "lodash/pick";
import { useParams, Prompt, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";

import { useAPI, RESOURCES } from "utils/api";
import { useGlobalUI } from "context/globalUI";
import { useUserCompany } from "context/userCompany";
import ResetPasswordModal from "./children/ResetPasswordModal";
import RoundedButton from "components/Buttons/RoundedButton";
import TextField from "components/TextField";
import PageHeader from "components/PageHeader";
import Spinner from "components/Spinner";
import BackButton from "components/Buttons/BackButton";
import MakeUserAdminModal from "./children/MakeUserAdminModal";

const VALIDATION_RULES = {
  first_name: { required: true },
  last_name: { required: true },
  email: {
    required: true
  },
  phone: {
    required: true,
    pattern: {
      value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
      message: "Invalid phone number"
    }
  }
};

const styles = (theme) => ({
  backButton: theme.buttons.back,
  value: {
    fontWeight: theme.typography.fontWeightLight
  },
  systemTypeContainer: {
    border: "1px solid lightGray",
    padding: theme.spacing(2, 3),
    display: "flex",
    width: "max-content",
    marginBottom: theme.spacing(4)
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium
  },
  right: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    border: "1px solid #707070",
    borderRadius: 4,
    color: "#666",
    fontSize: 18
  },
  checkbox: {
    alignItems: "start"
  },
  checkboxLabel: {
    padding: theme.spacing(1)
  },
  helperText: {
    fontSize: 12,
    color: "#666"
  },
  redButton: {
    ...theme.buttons.red,
    margin: theme.spacing(2, 0)
  },
  changeAdminButton: {
    marginBottom: theme.spacing(2),
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main
  },
  phoneInput: {
    margin: theme.spacing(2, 0, 6, 0)
  },
  inputPhone: {
    focus: theme.palette.secondary.main
  }
});

function EditSupplierUser({ classes }) {
  const { register, errors, handleSubmit, watch, reset, control, formState } = useForm({ mode: "onBlur" });
  const data = watch();

  const api = useAPI();
  const params = useParams();
  const history = useHistory();
  const { companyInfo } = useUserCompany();
  const { snackbar, navDrawer } = useGlobalUI();

  const [companyId, setCompanyId] = React.useState(params.company_id ? +params.company_id : null);
  const [userId, setUserId] = React.useState(params.user_id ? +params.user_id : null);
  const [user, setUser] = React.useState(null);
  const [supplier, setSupplier] = React.useState(null);
  const [showResetPasswordModal, setShowResetPasswordModal] = React.useState(false);
  const [makeUserAdmin, setMakeUserAdmin] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const makeCalls = async (companyId, userId) => {
      await fetchUser(companyId, userId);
      await fetchSupplier(companyId);
    };
    if (companyId && userId) {
      makeCalls(companyId, userId);
    }
  }, []);

  async function fetchSupplier(company_id) {
    const supplier = await api.callWithAuth({ method: "GET", url: RESOURCES.currentCompany(company_id) });
    setSupplier(supplier);
    setLoading(false);
  }

  async function fetchUser(company_id, user_id) {
    const result = await api.callWithAuth({ method: "GET", url: `${RESOURCES.companies}/${company_id}/users/${user_id}` });
    setUser(result);
    reset(pick(result, ["first_name", "last_name", "email", "phone", "allow_view_orders"]));
  }

  function toggleResetPasswordModal() {
    setShowResetPasswordModal(!showResetPasswordModal);
  }
  function toggleMakeUserAdminModal() {
    setMakeUserAdmin(!makeUserAdmin);
  }

  async function confirmUserAdmin() {
    setLoading(true);
    try {
      await api.callWithAuth({
        method: "PUT",
        url: RESOURCES.changeCompanyAdmin(companyId, userId),
        successMessage: "Company administrator was successfully changed"
      });
    } catch (e) {
      setLoading(false);
      return;
    }
    toggleMakeUserAdminModal();
    setLoading(false);
  }

  async function handleSave() {
    setSaving(true);
    const phone = data.phone ? `+${data.phone}` : "";

    if (!phone) {
      setSaving(false);
      return snackbar.open({ message: "Phone number is required", color: "error" });
    }

    try {
      await api.callWithAuth({
        method: "PUT",
        url: `${RESOURCES.companies}/${companyId}/users/${userId}`,
        axiosOptions: { data: { ...data, phone } },
        successMessage: "Details for this user were successfully updated!"
      });
    } catch (e) {
      return;
    } finally {
      setSaving(false);
    }

    history.goBack();
  }

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <Prompt when={formState.dirty && !formState.isSubmitted} message="Really?" />

      <PageHeader
        title={userId ? "Edit Supplier User" : "Add New User"}
        breadcrumbs={["Manage Supplier Account", `${userId ? "Edit Supplier User" : "Add New Supplier User"}`]}
        hint={
          userId
            ? "Edit the existing user details below. Any changes made will be applied the next time the user opens their account."
            : "Provide the user details in the form. The new user will get an email prompting them log in with their temporary password."
        }
      >
        {loading ? <Spinner /> : <BackButton url={`/admin/suppliers/${companyId}`} />}
      </PageHeader>

      {loading ? (
        ""
      ) : (
        <div className={classes.systemTypeContainer}>
          <Typography>
            <span className={classes.bold}>System User Type: </span>
            {user.formatted_role_name}
          </Typography>
        </div>
      )}

      {loading ? (
        <Spinner fullScreen={true} />
      ) : (
        <>
          <Typography variant="h5">
            {userId ? `Edit User Details for ${user.first_name} ${user.last_name}` : "New User Details"}
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="first_name"
                    inputRef={register(VALIDATION_RULES.first_name)}
                    error={!!errors.first_name}
                    label="First Name"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    empty={!data.first_name}
                    fullWidth
                    autoFocus={!params.id}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="last_name"
                    inputRef={register(VALIDATION_RULES.last_name)}
                    error={!!errors.last_name}
                    label="Last Name"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    empty={!data.last_name}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="email"
                    inputRef={register(VALIDATION_RULES.email)}
                    error={!!errors.email}
                    label="Email"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    empty={!data.email}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    as={
                      <PhoneInput
                        name="phone"
                        containerClass={classes.phoneInput}
                        inputClass={classes.inputPhone}
                        country="us"
                        prefix="+"
                      />
                    }
                    // rules={{ required: true }}
                    name="phone"
                    control={control}
                    defaultValue={data.phone || ""}
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <FormControlLabel
                    classes={{
                      root: classes.checkbox,
                      label: classes.checkboxLabel
                    }}
                    control={
                      <Controller
                        as={<Checkbox inputRef={register()} color="secondary" />}
                        control={control}
                        name="allow_view_orders"
                      />
                    }
                    label={
                      <>
                        <Typography>Allow this user to view all orders made by any user from this company.</Typography>
                        <Typography className={classes.helperText}>
                          If unselected, this user will be able to view only orders they made.
                        </Typography>
                      </>
                    }
                  />
                </Grid> */}

                {error && (
                  <Grid item xs={12}>
                    <Typography color="error">{error}</Typography>
                  </Grid>
                )}

                {userId && (
                  <Grid item xs={12} align="center">
                    <RoundedButton className={classes.redButton} onClick={toggleResetPasswordModal} size="large">
                      RESET PASSWORD
                    </RoundedButton>
                  </Grid>
                )}
                {userId && (
                  <Grid item xs={12} align="center">
                    <RoundedButton
                      disabled={user.role === "CompanyAdmin"}
                      className={classes.changeAdminButton}
                      onClick={toggleMakeUserAdminModal}
                      size="large"
                    >
                      MAKE USER ACCOUNT ADMIN
                    </RoundedButton>
                  </Grid>
                )}
                <Grid item xs={12} align="center">
                  {saving ? (
                    <Spinner />
                  ) : (
                    <RoundedButton color="primary" onClick={handleSubmit(handleSave)} size="large">
                      {userId ? "SAVE" : "ADD NEW USER"}
                    </RoundedButton>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div className={classes.right}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    GE Vendor Code
                  </Grid>
                  <Grid item xs={7} className={classes.value}>
                    {supplier.ge_vendor_code}
                  </Grid>

                  <Grid item xs={5}>
                    Company
                  </Grid>
                  <Grid item xs={7} className={classes.value}>
                    {supplier.name}
                  </Grid>

                  <Grid item xs={5}>
                    Address 1
                  </Grid>
                  <Grid item xs={7} className={classes.value}>
                    {supplier.address1}
                  </Grid>

                  <Grid item xs={5}>
                    Address 2
                  </Grid>
                  <Grid item xs={7} className={classes.value}>
                    {supplier.address2}
                  </Grid>

                  <Grid item xs={5}>
                    City
                  </Grid>
                  <Grid item xs={7} className={classes.value}>
                    {supplier.city}
                  </Grid>

                  <Grid item xs={5}>
                    State/Province
                  </Grid>
                  <Grid item xs={7} className={classes.value}>
                    {supplier.region}
                  </Grid>

                  <Grid item xs={5}>
                    Zip/Postal Code
                  </Grid>
                  <Grid item xs={7} className={classes.value}>
                    {supplier.postal_code}
                  </Grid>

                  <Grid item xs={5}>
                    Country
                  </Grid>
                  <Grid item xs={7} className={classes.value}>
                    {supplier.country}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          {showResetPasswordModal && (
            <ResetPasswordModal onCancel={toggleResetPasswordModal} user={user} onSuccess={toggleResetPasswordModal} />
          )}
          {makeUserAdmin && (
            <MakeUserAdminModal
              onClose={toggleMakeUserAdminModal}
              user={user}
              supplier={supplier}
              loading={loading}
              handleConfirm={confirmUserAdmin}
            />
          )}
        </>
      )}
    </div>
  );
}

export default withStyles(styles)(EditSupplierUser);
