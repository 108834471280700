import React from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachmentOutlinedIcon from "@material-ui/icons/AttachmentOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import { RESOURCES, useAPI } from "utils/api";
import { useOrder } from "context/order";
import Card from "./Card";
import TableCell from "components/Tables/TableCell";
import RoundedButton from "components/Buttons/RoundedButton";

const styles = (theme) => ({
  marginTop: {
    marginTop: theme.spacing(2)
  },
  subheader: {
    fontSize: 28,
    color: theme.palette.primary.main
  },
  description: {
    color: "#666",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium
  },
  comment: {
    color: theme.palette.info.main,
    fontWeight: theme.typography.fontWeightMedium,
    margin: theme.spacing(0, 0, 1, 1)
  },
  label: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium
  },
  value: {
    fontSize: 20
  },
  chip: {
    borderRadius: 0,
    padding: theme.spacing(1),
    height: "auto"
  },
  chipLabel: {
    padding: theme.spacing(0, 3),
    color: theme.palette.info.main,
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: "normal"
  },
  input: {
    visibility: "hidden",
    position: "absolute",
    left: -9999,
    top: 0
  },
  tableCell: {
    padding: 10
  },
  price: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 18
  }
});

const OrderProcedureCard = ({ classes, index, procedure, onEdit, onDelete, onClear }) => {
  const { callWithAuth } = useAPI();
  const {
    updateOrderItemComment,
    addAttachmentToOrderProcedure,
    removeAttachmentFromOrderProcedure,
    cartType,
    order
  } = useOrder();

  const commentEl = React.useRef(null);
  const fileUploaderEl = React.useRef(null);
  const blobRef = React.useRef(null);

  const [fileUploading, setFileUploading] = React.useState(false);
  const [filename, setFilename] = React.useState("");

  async function handleChooseFile(event) {
    event.preventDefault();
    if (!event.target.files.length) {
      return;
    }

    blobRef.current = event.target.files[0];
    setFileUploading(true);

    const formData = new FormData();
    //HERE ME: order.id
    formData.append("attachment", blobRef.current, blobRef.current.name);

    let result;

    try {
      result = await callWithAuth({
        method: "POST",
        url: RESOURCES.attachments(order.id),
        axiosOptions: { data: formData }
      });

      /*result.guid, */
      await addAttachmentToOrderProcedure(procedure, result.guid);
    } catch (e) {
      return;
    } finally {
      setFileUploading(false);
    }
  }

  function handleUpload() {
    fileUploaderEl.current.click();
  }

  async function handleDelete() {
    if (fileUploading) {
      return;
    }

    blobRef.current = null;
    setFileUploading(true);

    await removeAttachmentFromOrderProcedure(procedure);

    setFileUploading(false);
  }

  function handleAttachmentClick() {
    window.open(procedure.attachment_url);
  }

  const debouncedSaveComment = React.useRef(debounce(() => updateOrderItemComment({ procedure }, commentEl.current.value), 1000));

  return (
    <Card
      title="Procedure"
      id={procedure.procedure_number}
      cartType={cartType}
      onEdit={onEdit}
      onDelete={onDelete}
      onClear={onClear}
    >
      <Grid container>
        <Grid style={{ margin: "16px 0 0 auto" }} item xs={12}>
          <Typography variant="h6" className={classes.subheader} paragraph>
            Procedure Details
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Number</TableCell>
                <TableCell className={classes.tableCell}>Title</TableCell>
                <TableCell className={classes.tableCell}>Revision</TableCell>
                <TableCell className={classes.tableCell}>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell}>{procedure.procedure_number}</TableCell>
                <TableCell className={classes.tableCell}>{procedure.title}</TableCell>
                <TableCell className={classes.tableCell}>{procedure.revision}</TableCell>
                <TableCell className={`${classes.tableCell} ${classes.price}`}>${procedure.price.toFixed(2)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>

      <Grid container spacing={5} className={classes.marginTop}>
        <Grid item xs={6} className={classes.borderRight}>
          <Typography variant="h6" className={classes.subheader} paragraph>
            Procedure Docs
          </Typography>
          <Typography className={classes.description} paragraph>
            Use the button to upload a the relevant documentation for this procedure.
          </Typography>

          <RoundedButton color="primary" onClick={handleUpload} disabled={procedure.attachment_filename || fileUploading}>
            Upload Docs
          </RoundedButton>

          {(procedure.attachment_filename || fileUploading) && (
            <Box mt={2}>
              <Chip
                clickable={!fileUploading}
                label={fileUploading ? <CircularProgress size={16} /> : procedure.attachment_filename}
                classes={{
                  root: classes.chip,
                  label: classes.chipLabel
                }}
                onDelete={handleDelete}
                onClick={handleAttachmentClick}
                icon={<AttachmentOutlinedIcon />}
                deleteIcon={<CloseOutlinedIcon />}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" className={classes.subheader} paragraph>
            Additional Information
          </Typography>
          <Typography className={classes.description} paragraph>
            You can provide any other relevant information pertaining to this procedure here.
          </Typography>

          <Typography className={classes.comment}>Comment:</Typography>

          <TextField
            multiline
            rows={5}
            variant="outlined"
            fullWidth
            defaultValue={procedure.comment}
            inputRef={commentEl}
            onChange={debouncedSaveComment.current}
          />
        </Grid>
      </Grid>

      <input onChange={handleChooseFile} className={classes.input} type="file" ref={fileUploaderEl} />
    </Card>
  );
};

export default withStyles(styles)(OrderProcedureCard);
