import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { useAPI, RESOURCES } from "utils/api";
import Modal from "components/Modal";
import Spinner from "components/Spinner";

const styles = (theme) => ({
  red: { color: theme.palette.error.main },
  bold: { fontWeight: theme.typography.fontWeightMedium },
  blue: { color: theme.palette.info.main }
});

const RequestAppealModal = ({ classes, onClose, itemType, orderItemId }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [appealText, setAppealText] = React.useState("");
  const [textLoading, setTextLoading] = React.useState(true);
  const [CommentAB, setComment] = React.useState("");
  const asda = JSON.stringify({ comm: CommentAB });
  const comment = "Test";

  const api = useAPI();

  React.useEffect(() => {
    const fetchAppealText = async () => {
      const response = await api.callWithAuth({ method: "GET", url: RESOURCES.settings("text_request_appeal") });
      setAppealText(response.value);
      setTextLoading(false);
    };

    if (!!orderItemId) {
      fetchAppealText();
    }
  }, [orderItemId]);

  const handleConfirm = async () => {
    setError(null);
    setLoading(true);

    try {
      await api.callWithAuth({
        method: "POST",
        url: RESOURCES.requestAppeal(orderItemId, CommentAB),
        params: { CommentAB },
        successMessage: `Your ${itemType} was successfully submitted for appeal!`
      });
    } catch (e) {
      setLoading(false);
      return;
    }
    setLoading(false);
    onClose();
  };

  /*const handleComment = (field) => ({ target }) => {
    onChange(field, target.value);
  }; onchange={handleComment={"commentA"}}
  inputRef={comment}*/
  function handleComment() {
    setComment(document.getElementById("AppComment").value);
  }

  return (
    <Modal
      open={true}
      title="Request Appeal"
      actions={
        <>
          <TextField multiline rows={5} variant="outlined" fullWidth defaultValue="" id="AppComment" onChange={handleComment} />
          <Button variant="text" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          {loading ? (
            <Spinner size={20} />
          ) : (
            <Button className={classes.blue} variant="text" disabled={loading} onClick={handleConfirm}>
              Continue
            </Button>
          )}
        </>
      }
    >
      {textLoading ? <Spinner /> : <Typography paragraph dangerouslySetInnerHTML={{ __html: appealText }} />}
      <Typography className={classes.noData}> Appeal Comment:</Typography>
    </Modal>
  );
};

export default withStyles(styles)(RequestAppealModal);
