import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";

import Footer from "components/Footer";

const styles = (theme) => ({
  sm: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: 600
    }
  },
  md: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 960
    }
  }
});

const PageContent = ({ classes, fullWidth, maxWidth = "md", children }) => (
  <Fragment>
    <div style={{ minHeight: "100%" }} className={fullWidth ? "" : classes[maxWidth] ? classes[maxWidth] : classes.md}>
      {children}
    </div>

    <Footer />
  </Fragment>
);

export default withStyles(styles)(PageContent);
