import React from "react";
import { useHistory } from "react-router-dom";
import pick from "lodash/pick";
import { Prompt } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";

import RoundedButton from "components/Buttons/RoundedButton";
import BackButton from "components/Buttons/BackButton";
import TextField from "components/TextField";
import PageHeader from "components/PageHeader";
import { useUserCompany } from "context/userCompany";
import { useGlobalUI } from "context/globalUI";
import { RESOURCES, useAPI } from "utils/api";
import Spinner from "components/Spinner";

const VALIDATION_RULES = {
  first_name: { required: true },
  last_name: { required: true },
  email: {
    required: true
  },
  phone: {
    required: true,
    pattern: {
      value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
      message: "Invalid phone number"
    }
  }
};

const styles = (theme) => ({
  backButton: theme.buttons.back,
  value: {
    fontWeight: theme.typography.fontWeightLight
  },
  right: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    border: "1px solid #8c8c8c",
    borderRadius: 4,
    color: "#8c8c8c",
    fontSize: 18
  },
  phoneInput: {
    margin: theme.spacing(2, 0, 6, 0)
  },
  inputPhone: {
    focus: theme.palette.secondary.main
  }
});

function EditInformation({ classes }) {
  const { register, errors, handleSubmit, watch, formState, reset, control } = useForm({ mode: "onBlur" });
  const { user, updateData } = useUserCompany();
  const { callWithAuth } = useAPI();
  const history = useHistory();
  const data = watch();
  const { snackbar, navDrawer } = useGlobalUI();

  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    reset(pick(user, ["first_name", "last_name", "email", "phone"]));
    setLoading(false);
  }, []);

  async function handleSave(data) {
    setSaving(true);
    const phone = data.phone ? `+${data.phone}` : "";

    if (!phone) {
      setSaving(false);
      return snackbar.open({ message: "Phone number is required", color: "error" });
    }

    try {
      await callWithAuth({
        method: "PUT",
        url: `${RESOURCES.currentUserInfo}`,
        axiosOptions: { data: { ...data, phone } },
        successMessage: "Your account information was updated!"
      });
    } catch (e) {
      setSaving(false);
      return;
    }

    setSaving(false);
    updateData();
    history.goBack();
  }

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <Prompt when={formState.dirty && !formState.isSubmitted} message="You have unsaved changes." />

      <PageHeader
        title="Edit Your Personal Information"
        breadcrumbs={["Account", "Edit Your Personal Information"]}
        hint="Edit your user information below. Contact your company's administrator to edit company information."
      >
        <BackButton url="/account" />
      </PageHeader>

      {/* <Typography variant="h5">Your Details</Typography> */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <form onSubmit={handleSubmit(handleSave)}>
            {loading ? (
              <Spinner />
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="first_name"
                    inputRef={register(VALIDATION_RULES.first_name)}
                    error={!!errors.first_name}
                    label="First Name"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    empty={!data.first_name}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="last_name"
                    inputRef={register(VALIDATION_RULES.last_name)}
                    error={!!errors.last_name}
                    label="Last Name"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    empty={!data.last_name}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="email"
                    inputRef={register(VALIDATION_RULES.email)}
                    error={!!errors.email}
                    label="Email"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    empty={!data.email}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    as={
                      <PhoneInput
                        name="phone"
                        containerClass={classes.phoneInput}
                        inputClass={classes.inputPhone}
                        country={"us"}
                        prefix="+"
                      />
                    }
                    // rules={{ required: true }}
                    name="phone"
                    control={control}
                    defaultValue={data.phone}
                  />
                </Grid>

                {error && (
                  <Grid item xs={12}>
                    <Typography color="error">{error}</Typography>
                  </Grid>
                )}

                <Grid item xs={12} align="center">
                  {saving ? (
                    <Spinner />
                  ) : (
                    <RoundedButton color="primary" type="submit" size="large">
                      SAVE
                    </RoundedButton>
                  )}
                </Grid>
              </Grid>
            )}
          </form>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.right}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                GE Vendor Code
              </Grid>
              <Grid item xs={7} className={classes.value}>
                1234-5678-9012
              </Grid>

              <Grid item xs={5}>
                Company
              </Grid>
              <Grid item xs={7} className={classes.value}>
                Madison Avenue Labs
              </Grid>

              <Grid item xs={5}>
                Address 1
              </Grid>
              <Grid item xs={7} className={classes.value}>
                123 Fake Street
              </Grid>

              <Grid item xs={5}>
                Address 2
              </Grid>
              <Grid item xs={7} className={classes.value}>
                Suite 200
              </Grid>

              <Grid item xs={5}>
                City
              </Grid>
              <Grid item xs={7} className={classes.value}>
                Covington
              </Grid>

              <Grid item xs={5}>
                State / Province
              </Grid>
              <Grid item xs={7} className={classes.value}>
                Alberta
              </Grid>

              <Grid item xs={5}>
                Zip
              </Grid>
              <Grid item xs={7} className={classes.value}>
                12345
              </Grid>

              <Grid item xs={5}>
                Country
              </Grid>
              <Grid item xs={7} className={classes.value}>
                Canada
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(EditInformation);
