import React, { useState, useEffect, useContext, createContext } from "react";
import { useHistory } from "react-router-dom";

import { RESOURCES, useAPI, APIError } from "../utils/api";
import { globalUI } from "context/globalUI";
import cartTypes from "utils/cartTypes";
import { useGlobalUI } from "./globalUI";

const orderContext = createContext();

export function OrderProvider({ children }) {
  const order = useProvideOrder();
  return <orderContext.Provider value={order}>{children}</orderContext.Provider>;
}

export const useOrder = () => {
  return useContext(orderContext);
};

function useProvideOrder() {
  const [order, setOrder] = React.useState(null);
  const [procedures, setProcedures] = React.useState([]);
  const [coatings, setCoatings] = React.useState([]);
  const [billingAddress, setBillingAddress] = React.useState(null);
  const [stripePaymentDetails, setStripePaymentDetails] = React.useState(null);
  const [stripeToken, setStripeToken] = React.useState(null);
  const [shippingMethods, setShippingMethods] = React.useState([]);
  const [chosenShippingMethod, setChosenShippingMethod] = React.useState({});
  const [grandTotal, setGrandTotal] = React.useState(null);
  const [subTotal, setSubTotal] = React.useState(null);
  const [calculatedSalesTax, setCalculatedSalesTax] = React.useState(null);
  const [noCoatings, setNoCoatings] = React.useState(false);
  const [salesTax, setSalesTax] = React.useState(null);
  const [cartType, forceCart] = React.useState(cartTypes.normal);
  const [loading, setLoading] = React.useState(true);
  const [testsLoading, setTestsLoading] = React.useState(false);

  const history = useHistory();
  const { callWithAuth } = useAPI();
  const { snackbar } = useGlobalUI();

  const fetchCurrentOrder = async () => {
    setLoading(true);
    let result;

    try {
      result = await callWithAuth({ method: "GET", url: RESOURCES.currentOrder });
    } catch (e) {
      return;
    }
    determineNoCoatings(result);
    setOrder(result);
    setLoading(false);
  };

  const determineNoCoatings = (order) => {
    if (order && order.coatings.length >= 1) {
      setNoCoatings(false);
      return false;
    } else {
      setNoCoatings(true);
      return true;
    }
  };

  const calcSubTotal = (items, shipping = 0) => {
    const total = items.reduce((acc, curr) => {
      acc += curr.price;
      return acc;
    }, 0);
    setSubTotal((total + shipping).toFixed(2));
    return (total + shipping).toFixed(2);
  };

  const calcSalesTax = (items, shipping = 0) => {
    const sub = items.reduce((acc, curr) => {
      acc += curr.price;
      return acc;
    }, 0);
    const total = sub + shipping;
    setCalculatedSalesTax((total * salesTax).toFixed(2));
    return (total * salesTax).toFixed(2);
  };

  const calcGrandTotal = (items, shipping) => {
    const itemsTotal = items.reduce((acc, curr) => {
      acc += curr.price;
      return acc;
    }, 0);
    const tax = (itemsTotal + shipping) * salesTax;
    setGrandTotal((itemsTotal + shipping + tax).toFixed(2));
    return (itemsTotal + shipping + tax).toFixed(2);
  };

  const fetchProcedures = async () => {
    try {
      const procedures = await callWithAuth({ method: "GET", url: RESOURCES.procedures });
      setProcedures(procedures);
    } catch (e) {}
  };

  const fetchCoatings = async () => {
    try {
      const coatings = await callWithAuth({ method: "GET", url: RESOURCES.coatings });
      setCoatings(coatings);
    } catch (e) {}
  };

  const handleAddressChange = async (newAddress, type) => {
    const typeString = type.charAt(0).toUpperCase() + type.slice(1);

    try {
      await callWithAuth({
        method: "PUT",
        url: `/orders/current/addresses/${type}`,
        successMessage: `${typeString} Address updated for this order.`,
        axiosOptions: { data: { ...newAddress } }
      });
      await fetchCurrentOrder();
    } catch (e) {
      throw e;
    }
  };

  const fetchShippingMethods = async () => {
    setLoading(true);

    try {
      const response = await callWithAuth({ method: "GET", url: "/shippingMethods" });
      setShippingMethods(response);
      setLoading(false);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const addToOrder = async ({ procedure, coating }) => {
    setLoading(true);
    if (order.coatings.concat(order.procedures).length >= 1) {
      snackbar.open({ message: "Only one item allowed in the cart at a time.", color: "error" });
      return;
    }
    try {
      if (procedure) {
        await callWithAuth({
          method: "POST",
          url: RESOURCES.currentOrderProcedures,
          axiosOptions: { data: { id: procedure.id } }
        });
      }

      if (coating) {
        await callWithAuth({
          method: "POST",
          url: RESOURCES.currentOrderCoatings,
          axiosOptions: { data: { id: coating.id } }
        });
      }
    } catch (e) {
      return;
    } finally {
      setLoading(false);
    }

    await fetchCurrentOrder();
  };

  const updateOrderItemComment = async ({ procedure, coating }, comment) => {
    if (procedure) {
      try {
        const result = await callWithAuth({
          method: "PUT",
          url: `${RESOURCES.currentOrderProcedures}/${procedure.id}/comment`,
          axiosOptions: { data: { comment } }
        });
      } catch (e) {}
      console.log("[procedure]: ", procedure);
      const newProcedure = { ...procedure, comment };
      const newOrder = { ...order };
      const idx = order.procedures.findIndex(({ id }) => id === procedure.id);
      newOrder.procedures[idx] = newProcedure;

      setOrder(newOrder);
    }
    if (coating) {
      try {
        const result = await callWithAuth({
          method: "PUT",
          url: `${RESOURCES.currentOrderCoatings}/${coating.id}/comment`,
          axiosOptions: { data: { comment } }
        });
      } catch (e) {}
      const newCoating = { ...coating, comment };
      const newOrder = { ...order };
      const idx = order.coatings.findIndex(({ id }) => id === coating.id);
      newOrder.coatings[idx] = newCoating;

      setOrder(newOrder);
    }
  };

  const addAttachmentToOrderProcedure = async (procedure, guid) => {
    let newProcedure;

    try {
      await callWithAuth({
        method: "PUT",
        url: `${RESOURCES.currentOrderProcedures}/${procedure.id}/attachment`,
        axiosOptions: { data: { guid } }
      });

      newProcedure = await callWithAuth({ method: "GET", url: `${RESOURCES.currentOrderProcedures}/${procedure.id}` });
    } catch (e) {
      return;
    }

    const newOrder = { ...order };
    const idx = order.procedures.findIndex(({ id }) => id === procedure.id);
    newOrder.procedures[idx] = newProcedure;

    setOrder(newOrder);
  };

  const removeAttachmentFromOrderProcedure = async (procedure) => {
    let newProcedure;

    try {
      await callWithAuth({
        method: "DELETE",
        url: `${RESOURCES.currentOrderProcedures}/${procedure.id}/attachment`
      });

      newProcedure = await callWithAuth({ method: "GET", url: `${RESOURCES.currentOrderProcedures}/${procedure.id}` });
    } catch (e) {
      return;
    }

    const newOrder = { ...order };
    const idx = order.procedures.findIndex(({ id }) => id === procedure.id);
    newOrder.procedures[idx] = newProcedure;

    setOrder(newOrder);
  };

  const removeFromOrder = async ({ procedure, coating }) => {
    try {
      if (procedure) {
        await callWithAuth({ method: "DELETE", url: `${RESOURCES.currentOrderProcedures}/${procedure.id}` });
      }

      if (coating) {
        await callWithAuth({ method: "DELETE", url: `${RESOURCES.currentOrderCoatings}/${coating.id}` });
      }
    } catch (e) {
      return;
    } finally {
      setLoading(false);
    }

    await fetchCurrentOrder();
  };

  const addTestsToCoating = async (coating, tests) => {
    setTestsLoading(true);
    let newCoatingTests;

    try {
      for (const test of tests) {
        await callWithAuth({ method: "POST", url: RESOURCES.currentOrderCoatingTest(coating.id, test.id) });
      }

      newCoatingTests = await callWithAuth({ method: "GET", url: `${RESOURCES.currentOrderCoatings}/${coating.id}/tests` });
    } catch (e) {
      return;
    } finally {
      setTestsLoading(false);
    }

    /*const newOrder = { ...order };
    const idx = order.coatings.findIndex(({ id }) => id === coating.id);
    newOrder.coatings[idx] = {
      ...order.coatings[idx],
      tests: newCoatingTests
    };

    setOrder(newOrder);*/

    await fetchCurrentOrder();
  };

  const deleteTestFromCoating = async (coating, test) => {
    setTestsLoading(true);
    let newCoatingTests;

    try {
      await callWithAuth({ method: "DELETE", url: RESOURCES.currentOrderCoatingTest(coating.id, test.id) });

      newCoatingTests = await callWithAuth({ method: "GET", url: `${RESOURCES.currentOrderCoatings}/${coating.id}/tests` });
    } catch (e) {
      return;
    } finally {
      setTestsLoading(false);
    }

    /*const newOrder = { ...order };
    const idx = order.coatings.findIndex(({ id }) => id === coating.id);
    newOrder.coatings[idx] = {
      ...order.coatings[idx],
      tests: newCoatingTests
    };

    setOrder(newOrder);*/

    await fetchCurrentOrder();
  };

  const setCoatingTestResults = async (coating, test, data) => {
    setTestsLoading(true);
    let newCoatingTests;

    try {
      await callWithAuth({
        method: "PUT",
        url: `${RESOURCES.currentOrderCoatingTest(coating.id, test.id)}/results`,
        axiosOptions: { data }
      });

      newCoatingTests = await callWithAuth({ method: "GET", url: `${RESOURCES.currentOrderCoatings}/${coating.id}/tests` });
    } catch (e) {
      throw e;
    } finally {
      setTestsLoading(false);
    }

    const newOrder = { ...order };
    const idx = order.coatings.findIndex(({ id }) => id === coating.id);
    newOrder.coatings[idx] = {
      ...order.coatings[idx],
      tests: newCoatingTests
    };

    setOrder(newOrder);
  };

  /*
   * If property is null, then deviation is set for the entire test
   */
  const setCoatingTestPropertyDeviation = async (coating, test, property, data) => {
    setTestsLoading(true);
    let newCoatingTests;

    try {
      await callWithAuth({
        method: "PUT",
        url: `${RESOURCES.currentOrderCoatingTestDeviation(coating.id, test.id, property ? property.id : null)}`,
        axiosOptions: { data }
      });

      newCoatingTests = await callWithAuth({ method: "GET", url: `${RESOURCES.currentOrderCoatings}/${coating.id}/tests` });
    } catch (e) {
      throw e;
    } finally {
      setTestsLoading(false);
    }

    const newOrder = { ...order };
    const idx = order.coatings.findIndex(({ id }) => id === coating.id);
    newOrder.coatings[idx] = {
      ...order.coatings[idx],
      tests: newCoatingTests
    };

    setOrder(newOrder);
  };

  const updatePackage = async (data) => {
    setLoading(true);

    try {
      await callWithAuth({ method: "PUT", url: `${RESOURCES.currentOrder}/package`, axiosOptions: { data } });
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }

    await fetchCurrentOrder();
  };

  const submit = async (data) => {
    setLoading(true);

    try {
      await callWithAuth({
        method: "POST",
        url: `${RESOURCES.currentOrder}/submit`,
        axiosOptions: { data: { stripe_token: stripeToken.token.id, order_total: parseFloat(grandTotal) } }
      });
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }

    const submittedOrderId = order.id;

    // Clear current order since we have submitted it
    setOrder(null);

    history.push(`/new/order/confirmation?id=${submittedOrderId}`);
  };

  async function fetchSalesTax() {
    try {
      const response = await callWithAuth({ method: "GET", url: RESOURCES.settings("sales_tax") });
      setSalesTax(response.value);
    } catch (e) {
      return;
    }
  }

  function setCartType(qs) {
    if (qs[cartTypes.second_submission] == 1) {
      return forceCart(cartTypes.second_submission);
    }
    if (qs[cartTypes.new_order] == 1) {
      return forceCart(cartTypes.new_order);
    }
    if (qs[cartTypes.new_order_accepted] == 1) {
      return forceCart(cartTypes.new_order_accepted);
    }
    return forceCart(cartTypes.normal);
  }

  useEffect(() => {
    fetchCurrentOrder();
    fetchSalesTax();
  }, []);

  return {
    fetchCurrentOrder,
    fetchProcedures,
    fetchCoatings,
    setLoading,
    handleAddressChange,
    addToOrder,
    updateOrderItemComment,
    addAttachmentToOrderProcedure,
    removeAttachmentFromOrderProcedure,
    addTestsToCoating,
    deleteTestFromCoating,
    setCoatingTestResults,
    setCoatingTestPropertyDeviation,
    removeFromOrder,
    updatePackage,
    submit,
    loading,
    testsLoading,
    order,
    procedures,
    coatings,
    calcSubTotal,
    calcGrandTotal,
    setStripePaymentDetails,
    stripePaymentDetails,
    setOrder,
    fetchShippingMethods,
    shippingMethods,
    chosenShippingMethod,
    setChosenShippingMethod,
    grandTotal,
    subTotal,
    setStripeToken,
    noCoatings,
    determineNoCoatings,
    salesTax,
    calcSalesTax,
    calculatedSalesTax,
    setCartType,
    forceCart,
    cartType
  };
}
