import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { useAPI, RESOURCES } from "utils/api";
import Modal from "components/Modal";
import Spinner from "components/Spinner";

const styles = (theme) => ({
  red: { color: theme.palette.error.main },
  green: { color: theme.palette.secondary.main },
  bold: { fontWeight: theme.typography.fontWeightMedium },
  supplierName: { marginBottom: theme.spacing(3) }
});

const DenySupplierAcctModal = ({ classes, onClose, supplier }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const api = useAPI();

  const handleConfirm = async () => {
    setError(null);
    setLoading(true);
    console.log("Deny Account Clicked");
  };

  return (
    <Modal
      open={true}
      title="Deny Supplier account"
      actions={
        <>
          {loading && <Spinner size={20} />}

          <Button variant="text" onClick={onClose} disabled={loading}>
            Cancel
          </Button>

          <Button className={classes.red} variant="text" disabled={loading} onClick={handleConfirm}>
            Deny
          </Button>
        </>
      }
    >
      <Typography className={classes.supplierName} variant="h6">
        {supplier.name} has requested an account.
      </Typography>
      <Typography className={classes.red} paragraph>
        Are you sure you want to deny this supplier an account?
      </Typography>
    </Modal>
  );
};

export default withStyles(styles)(DenySupplierAcctModal);
