import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";

import { useAuth } from "context/auth";

export const HEADER_HEIGHT = 64;

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    color: theme.palette.background.text,
    height: HEADER_HEIGHT,
    position: "fixed",
    zIndex: 2
  },
  toolbar: {
    padding: theme.spacing(0, 4),
    position: "fixed",
    zIndex: 2,
    width: "100%"
  },
  grow: {
    flexGrow: 1
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "inherit"
  },
  title: {
    fontSize: 32,
    color: "inherit",
    [theme.breakpoints.down("xs")]: {
      fontSize: 26
    }
  },
  logo: {
    height: 48,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      height: 32
    }
  },
  text: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      lineHeight: 0.7,
      marginLeft: theme.spacing(2.5)
    }
  },
  aTag: {
    fontSize: 12,
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    },
    [theme.breakpoints.down("xs")]: {
      lineHeight: 1,
      fontSize: 10
    }
  },
  button: {
    color: "#FFF",
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 12,
    marginLeft: theme.spacing(3),
    borderRadius: 0,
    lineHeight: 1,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12
    }
  },
  startIcon: {
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      margin: 0
    }
  }
});

const Header = ({ classes }) => {
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    auth.logout();
  };

  return (
    <AppBar elevation={0} position="fixed" className={classes.root}>
      <Toolbar disableGutters className={classes.toolbar}>
        <Link to="/" className={classes.link}>
          <img src="/assets/logo.png" alt="logo" className={classes.logo} />
          <Typography className={classes.title}>Metcut</Typography>
        </Link>

        <div className={classes.grow} />

        <Typography className={classes.text}>
          <a className={classes.aTag} href="https://metcut.com/" target="_blank">
            Metcut Research Inc.
          </a>
        </Typography>
        <Typography className={classes.text}>
          <a className={classes.aTag} href="https://metcutctl.com/" target="_blank">
            Cincinnati Testing Laboratories
          </a>
        </Typography>
        <Typography className={classes.text}>
          <a className={classes.aTag} href="https://metcutfrance.com/" target="_blank">
            Metcut Recherches S.A.S.
          </a>
        </Typography>

        {auth.user ? (
          <>
            <Button
              aria-label="actions"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              classes={{ startIcon: classes.startIcon }}
              startIcon={<AccountCircleOutlinedIcon />}
              className={classes.button}
            >
              <span className={classes.accountButtonLabel}>{`${auth.user.first_name} ${auth.user.last_name}`}</span>
              <ExpandMoreOutlinedIcon />
            </Button>

            <Menu
              className={classes.menuRoot}
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to="/account" onClick={handleClose}>
                My Account
              </MenuItem>

              <Divider />

              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Menu>
          </>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);
