import * as Sentry from "@sentry/browser";
import getEvnvironment from "./environment";

//-------------------------------
export const sentryInit = () => {
  let environment = getEvnvironment;

  if (environment !== "dev") {
    // Do not init Sentry for development environments
    Sentry.init({
      dsn: "https://62f29d1a9dda4a14862b92d59d6afebd@o237647.ingest.sentry.io/5280042",
      release: process.env.REACT_APP_VERSION,
      environment
    });
  }
};

//-------------------------------------------
export const sentryConfigScope = (username) => {
  Sentry.configureScope((scope) => {
    scope.setUser({
      username: username
    });
  });
};

//----------------------------
const ERROR_LEVEL_MAPPING = {
  NotAuthorizedException: true,
  UserNotFoundException: true
};

export const sentryError = ({ error, tag, level }) => {
  //function should take in the level, the tag name and value, and the error message
  //*customize level based on response
  if (error.name) {
    if (ERROR_LEVEL_MAPPING[error.name]) {
      level = "info";
    }
  }
  const errorFormatted = error.message || error;
  Sentry.withScope((scope) => {
    scope.setTag(tag.name, tag.value);
    scope.setLevel(level);
    Sentry.captureException(errorFormatted);
  });
};
