import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { useAPI, RESOURCES } from "utils/api";
import { capitalizeFirstLetter } from "utils/format";
import { useGlobalUI } from "context/globalUI";
import Modal from "components/Modal";
import Spinner from "components/Spinner";

const styles = (theme) => ({
  red: { color: theme.palette.error.main },
  bold: { fontWeight: theme.typography.fontWeightMedium }
});

const ApprovePriority = ({ classes, onClose, itemType, orderItemId, tableType }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const api = useAPI();
  const { approveDenyRequest, setApproveDenyRequest } = useGlobalUI();

  const handleConfirm = async () => {
    setLoading(true);
    setError(null);

    try {
      await api.callWithAuth({
        method: "POST",
        url: RESOURCES.approvePriority(orderItemId),
        successMessage: `${capitalizeFirstLetter(itemType)} was successfully approved for priority scheduling!`
      });
    } catch (e) {
      setLoading(false);
      return;
    } finally {
      setApproveDenyRequest(!approveDenyRequest);
      onClose();
    }
  };

  return (
    <Modal
      open={true}
      title={`Approve Priority Scheduling For This ${capitalizeFirstLetter(itemType)}`}
      actions={
        <>
          <Button variant="text" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          {loading ? (
            <Spinner />
          ) : (
            <Button color="secondary" variant="text" disabled={loading} onClick={handleConfirm}>
              Approve
            </Button>
          )}
        </>
      }
    >
      <Typography paragraph>
        Are you sure you want to approve the priority scheduling request for this item? Upon approving, the administrator of the
        supplier account will receive an email notifying them of the approval and completion of the priority fee transaction.
      </Typography>
    </Modal>
  );
};

export default withStyles(styles)(ApprovePriority);
