import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FilterListIcon from "@material-ui/icons/FilterList";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  select: {
    fontSize: 18,
    color: theme.palette.info.main,
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: "none",
    paddingRight: theme.spacing(1.5)
  },
  formControl: {
    margin: theme.spacing(0, 2)
  },
  text: {
    fontSize: 18,
    margin: theme.spacing(0, 1, 0, 0.5)
  },
  iconButton: {
    color: theme.palette.info.main
  },
  filterButton: {
    marginLeft: theme.spacing(2)
  }
});

function Pagination({ classes, disabled, limit, start, onChangeLimit, onChangeStart, totalRows }) {
  function handleChange({ target }) {
    onChangeLimit(target.value);
  }

  const handleChangeStart = (num) => () => {
    onChangeStart(Math.max(start + num, 0));
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>Rows per page:</Typography>

      <FormControl className={classes.formControl}>
        <Select value={limit} onChange={handleChange} disabled={disabled} disableUnderline classes={{ root: classes.select }}>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </FormControl>

      <Typography className={classes.text}>{`${start + 1}-${Math.min(start + limit, totalRows)} of ${totalRows}`}</Typography>
      <IconButton
        className={classes.iconButton}
        size="small"
        disabled={disabled || start < 1}
        onClick={handleChangeStart(-limit)}
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        className={classes.iconButton}
        size="small"
        disabled={disabled || start + limit >= totalRows}
        onClick={handleChangeStart(limit)}
      >
        <ChevronRightIcon />
      </IconButton>

      {/* <IconButton className={[classes.iconButton, classes.filterButton].join(" ")}>
        <FilterListIcon />
      </IconButton> */}
    </div>
  );
}

export default withStyles(styles)(Pagination);
