import React from "react";

import PageContent from "components/PageContent";
import PageHeader from "components/PageHeader";
import { useGlobalUI } from "context/globalUI";

function Error() {
  const { navDrawer } = useGlobalUI();
  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader title="Error" />

      <PageContent>
        <div>Page Not Found</div>
      </PageContent>
    </div>
  );
}

export default Error;
