import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

import Modal from "components/Modal";
import { useAPI, RESOURCES } from "utils/api";

const styles = (theme) => ({
  approve: {
    color: theme.palette.secondary.main
  },
  deny: {
    color: theme.palette.error.main
  },
  warning: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(4)
  }
});

function HandleRequestModal({ onClose, classes, action, id }) {
  const [text, setText] = React.useState("");

  const title = action === "approve" ? "Approve Account Request" : "Deny Account Request";
  const buttonText = action.charAt(0).toUpperCase() + action.slice(1);
  const api = useAPI();
  const history = useHistory();
  const callback = () => history.goBack();

  const handle = {
    approve: async () => {
      try {
        await api.callWithAuth({
          method: "POST",
          url: RESOURCES.approveRequest(id),
          successMessage: "Account was successfully approved! The supplier will receive an email shortly."
        });
      } catch (e) {
        return;
      }

      callback();
    },
    deny: async () => {
      try {
        await api.callWithAuth({
          method: "POST",
          url: RESOURCES.denyRequest(id),
          successMessage: "Account was successfully denied. They will receive an email shortly"
        });
      } catch (e) {
        return;
      }

      callback();
    }
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <Modal
      open={true}
      title={<span className={classes[action]}>{title}</span>}
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button className={classes[action]} variant="text" onClick={handle[action]}>
            {buttonText}
          </Button>
        </>
      }
    >
      {/* <Typography className={classes.warning}>
        Are you sure you want to {action} this account request? This action cannot be undone.
      </Typography> */}
      <Typography>
        The supplier will receive an email shortly notifying them of their {action === "approve" ? "approval" : "denial"}.
      </Typography>
      {/* <Typography variant="p">
        Provide any extra information or explanation below. They will receive an email shortly notifying them.
      </Typography> */}
      {/* <TextField
        autoFocus
        label="Additional Info"
        multiline
        rows={7}
        variant="outlined"
        fullWidth
        value={text}
        onChange={handleChange}
        className={classes.textField}
      /> */}
    </Modal>
  );
}

export default withStyles(styles)(HandleRequestModal);
