import React from "react";
import { Link } from "react-router-dom";

import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import RoundedButton from "components/Buttons/RoundedButton";

export default function SupplierInfoGrid({ classes, companyInfo }) {
  const labelWidthXs = 4;
  const valueWidthXs = 8;

  return (
    <>
      <div className={classes.subheader}>
        <Typography variant="h5">Supplier Information</Typography>
        <RoundedButton
          color="secondary"
          size="small"
          startIcon={<EditIcon />}
          component={Link}
          to={`/admin/suppliers/${companyInfo.id}/edit`}
        >
          Edit
        </RoundedButton>
      </div>
      <Grid container>
        <Grid container spacing={2} xs={12} sm={5}>
          <Grid className={classes.label} item xs={labelWidthXs}>
            Company
          </Grid>
          <Grid item xs={valueWidthXs} className={classes.value}>
            {companyInfo.name}
          </Grid>

          <Grid className={classes.label} item xs={labelWidthXs}>
            Address 1
          </Grid>
          <Grid item xs={valueWidthXs} className={classes.value}>
            {companyInfo.address1}
          </Grid>

          <Grid className={classes.label} item xs={labelWidthXs}>
            Address 2
          </Grid>
          <Grid item xs={valueWidthXs} className={classes.value}>
            {companyInfo.address2}
          </Grid>

          <Grid className={classes.label} item xs={labelWidthXs}>
            City
          </Grid>
          <Grid item xs={valueWidthXs} className={classes.value}>
            {companyInfo.city}
          </Grid>

          <Grid className={classes.label} item xs={labelWidthXs}>
            State/Province
          </Grid>
          <Grid item xs={valueWidthXs} className={classes.value}>
            {companyInfo.region}
          </Grid>

          <Grid className={classes.label} item xs={labelWidthXs}>
            Zip/Postal Code
          </Grid>
          <Grid item xs={valueWidthXs} className={classes.value}>
            {companyInfo.postal_code}
          </Grid>

          <Grid className={classes.label} item xs={labelWidthXs}>
            Country
          </Grid>
          <Grid item xs={valueWidthXs} className={classes.value}>
            {companyInfo.country}
          </Grid>
        </Grid>
        <Grid alignContent="flex-start" container spacing={2} xs={12} sm={7}>
          <Grid className={classes.label} item xs={labelWidthXs}>
            Account Admin
          </Grid>
          <Grid item xs={valueWidthXs} className={classes.value}>
            {companyInfo.admin_full_name}
          </Grid>
          <Grid className={classes.label} item xs={labelWidthXs}>
            Email
          </Grid>
          <Grid item xs={valueWidthXs} className={classes.value}>
            {companyInfo.admin_email}
          </Grid>
          <Grid className={classes.label} item xs={labelWidthXs}>
            Phone
          </Grid>
          <Grid item xs={valueWidthXs} className={classes.value}>
            {companyInfo.admin_phone}
          </Grid>

          <Grid className={classes.label} item xs={labelWidthXs}>
            GE Vendor Code
          </Grid>
          <Grid item xs={valueWidthXs} className={classes.value}>
            {companyInfo.ge_vendor_code}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
