import React from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import pick from "lodash/pick";
import { useForm, Controller } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import RoundedButton from "components/Buttons/RoundedButton";
import BackButton from "components/Buttons/BackButton";
import TextField from "components/TextField";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import Spinner from "components/Spinner";
import { useAPI, RESOURCES } from "utils/api";
import { parseQueryString } from "utils/qs";
import { useOrder } from "context/order";
import { useGlobalUI } from "context/globalUI";
import cartTypes from "utils/cartTypes";

const VALIDATION_RULES = {
  title: { required: true },
  procedure_number: { required: true },
  revision: { required: true },
  type: { required: true }
};

const styles = (theme) => ({
  backButton: theme.buttons.back,
  form: {
    margin: theme.spacing(2, 0, 4)
  },
  subHead1: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.secondary.main
  },
  subHead2: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.secondary.main
  },
  majMinLabel: {
    margin: theme.spacing(1.5, 0),
    fontSize: 16
  },
  menuItem: {
    backgroundColor: "#fff",
    zIndex: 10
  },
  buttonContainer: {
    marginBottom: theme.spacing(2),
    "& > *": {
      marginBottom: theme.spacing(2),
      display: "block"
    }
  }
});

function EditProcedure({ classes }) {
  const { register, errors, handleSubmit, watch, control, reset, formState } = useForm({ mode: "onBlur" });
  const data = watch();
  const params = useParams();

  const order = useOrder();
  const api = useAPI();
  const history = useHistory();
  const location = useLocation();
  const { navDrawer } = useGlobalUI();

  const [id, setId] = React.useState(params.id ? +params.id : null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [saving, setSaving] = React.useState(false);
  const [secondSubmission, setSecondSubmission] = React.useState(false);
  const [newOrderAccepted, setNewOrderAccepted] = React.useState(false);
  const [addToOrder, setAddToOrder] = React.useState(false);

  React.useEffect(() => {
    const qs = parseQueryString(location.search);
    order.setCartType(qs);
    if (qs.add) {
      setAddToOrder(true);
    }
    if (!!id) {
      fetchProcedure(id);
    } else {
      setLoading(false);
    }
  }, []);

  async function fetchProcedure(id) {
    const result = await api.callWithAuth({ method: "GET", url: `${RESOURCES.procedures}/${id}` });
    // setUser(result);
    reset(pick(result, ["title", "procedure_number", "revision", "type"]));
    setLoading(false);
  }

  async function handleSave(add) {
    setSaving(true);
    let procedure;
    const method = id ? "PUT" : "POST";
    const url = id ? `${RESOURCES.procedures}/${id}` : RESOURCES.procedures;
    const payload = { ...data, procedure_id: id };
    const successMessage = id
      ? "The Procedure was successfully updated!"
      : "The Procedure was successfully added to the database!";

    try {
      procedure = await api.callWithAuth({
        method,
        url,
        axiosOptions: { data: payload },
        successMessage
      });
    } catch (e) {
      setSaving(false);
      return;
    }

    if (add && addToOrder) {
      const payload = secondSubmission || id ? { procedure: { id } } : { procedure };
      order.addToOrder(payload);

      const urlToPush = pageContentMap("urlToPush");
      history.push(urlToPush);
      window.location = "/home";
      return;
    }

    // Just save the procedure and redirect to the Home page
    //history.push("/");
    window.location = "/home";
  }

  function pageContentMap(textType) {
    return {
      [cartTypes.second_submission]: {
        pageTitle: "Second Submission",
        pageHeader: "Make Changes For Second Submission",
        subHead1:
          "For resubmission of an accepted procedure: the only edits that can be made are the 'revision' and 'type' (Major or Minor).",
        subHead2: "",
        saveButton: "ADD TO ORDER",
        backUrl: `/new/order?${cartTypes.new_order_accepted}=1`,
        urlToPush: `/new/order?${cartTypes.second_submission}=1`
      },
      [cartTypes.new_order]: {
        pageTitle: "New Order",
        pageHeader: "Make Changes For New Order",
        subHead1:
          "For resubmission: the only edits that can be made are to the 'Revision' field and 'TypeField'. Review the information on this page and then select 'Add To Order' when you're ready to continue. (Major or Minor).",
        subHead2: "",
        backUrl: addToOrder ? `/new/order?${cartTypes.new_order_accepted}=1` : "/home",
        urlToPush: `/new/order?${cartTypes.new_order}=1`,
        saveButton: "ADD TO ORDER"
      },
      [cartTypes.new_order_accepted]: {
        pageTitle: "New Order",
        pageHeader: "Make Limited Changes For New Order",
        subHead1:
          "For resubmission of an accepted procedure: the only edits that can be made are to the 'Revision' field and 'TypeField'. Review the information on this page and then select 'Add To Order' when you're ready to continue. (Major or Minor).",
        subHead2: "",
        backUrl: addToOrder ? `/new/order?${cartTypes.new_order_accepted}=1` : "/home",
        urlToPush: `/new/order?${cartTypes.new_order_accepted}=1`,
        saveButton: "ADD TO ORDER"
      },
      [cartTypes.normal]: {
        pageTitle: id ? "Edit Procedure" : "New Procedure",
        breadcrumbs: id
          ? addToOrder
            ? ["Your Order", "Edit Procedure"]
            : ["Home", "Edit Procedure"]
          : ["Create", "New Procedure"],
        pageHeader: id ? (addToOrder ? "Make Your Changes" : "Make Your Changes") : "Create A New Procedure",
        backUrl: id ? (addToOrder ? "/new/order" : "/home") : "/new",
        urlToPush: id ? (addToOrder ? "/new/order" : "/home") : "/new",
        saveButton: addToOrder ? "ADD TO ORDER" : "CREATE"
      }
    }[order.cartType || "normal"][textType];
  }

  function handleCancel() {
    history.goBack();
  }

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader
        // title={newOrderAccepted ? "New Order" : id ? "Edit Procedure" : "New Procedure"}
        title={pageContentMap("pageTitle")}
        breadcrumbs={pageContentMap("breadcrumbs")}
        hint="Check out the videos for a little guidance through the coating or procedure submission process."
      >
        {/* <BackButton url={secondSubmission ? "/home" : !addToOrder && !id ? "/new" : id ? "/home" : "/new/order"} /> */}
        <BackButton url={"/home"} />
      </PageHeader>

      <PageContent maxWidth="sm">
        <Typography variant="h5">{id ? "Edit Procedure" : "Build New Procedure"}</Typography>
        <Typography className={classes.subHead1}>{pageContentMap("subHead1")}</Typography>

        {loading ? (
          <Spinner />
        ) : (
          <Grid container spacing={2} className={classes.form}>
            <Grid item xs={12}>
              <TextField
                name="title"
                inputRef={register(VALIDATION_RULES.title)}
                error={!!errors.title}
                label="Title"
                margin="normal"
                variant="outlined"
                color="secondary"
                empty={!data.title}
                disabled={order.cartType !== cartTypes.normal}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="procedure_number"
                inputRef={register(VALIDATION_RULES.procedure_number)}
                error={!!errors.procedure}
                label="Reference Number"
                margin="normal"
                variant="outlined"
                color="secondary"
                empty={!data.procedure_number}
                disabled={order.cartType !== cartTypes.normal}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="revision"
                inputRef={register(VALIDATION_RULES.revision)}
                error={!!errors.revision}
                label="Revision"
                margin="normal"
                variant="outlined"
                color="secondary"
                empty={!data.revision}
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h5" className={classes.majMinLabel}>
                Major or Minor Review?
              </Typography>
              <FormControl error={!!errors.type} variant="outlined" fullWidth>
                <InputLabel id="add-class-label-1.5">Major/Minor</InputLabel>
                <Controller
                  as={
                    <Select fullWidth name="type" labelId="add-class-label-1.5" label="Major/Minor">
                      <MenuItem className={classes.menuItem} value=""></MenuItem>
                      <MenuItem className={classes.menuItem} value="Major">
                        Major
                      </MenuItem>
                      <MenuItem className={classes.menuItem} value="Minor">
                        Minor
                      </MenuItem>
                    </Select>
                  }
                  name="type"
                  rules={VALIDATION_RULES.type}
                  control={control}
                  defaultValue={data.type}
                />
              </FormControl>
            </Grid>

            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
          </Grid>
        )}

        {saving ? (
          <Spinner />
        ) : (
          <div className={classes.buttonContainer}>
            {loading ? (
              <Spinner style={{ marginRight: "100%" }} />
            ) : (
              <>
                {addToOrder && (
                  <RoundedButton
                    color="primary"
                    onClick={handleSubmit(() => handleSave(true))}
                    size="large"
                    disabled={!formState.isValid}
                  >
                    ADD TO ORDER
                  </RoundedButton>
                )}

                <RoundedButton
                  color="primary"
                  onClick={handleSubmit(() => handleSave(false))}
                  size="large"
                  disabled={!formState.isValid}
                >
                  SAVE
                </RoundedButton>

                <RoundedButton color="primary" onClick={handleCancel} size="large">
                  CANCEL
                </RoundedButton>
              </>
            )}
          </div>
        )}
      </PageContent>
    </div>
  );
}

export default withStyles(styles)(EditProcedure);
