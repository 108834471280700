import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import Modal from "components/Modal";
import Spinner from "components/Spinner";

function EditPropertyValueModal({ property, requiredValue, onCancel, onEdit }) {
  const [value, setValue] = React.useState(requiredValue ? property.required_value || property.required_default : property.value);
  const [saving, setSaving] = React.useState(false);

  function handleChange({ target }) {
    setValue(target.value);
  }

  function handleEdit() {
    setSaving(true);
    onEdit(property, value, requiredValue);
  }

  function onKeyDown(event) {
    if (event.keyCode === 13 || event.code === "NumpadEnter") {
      event.preventDefault();
      event.stopPropagation();
      handleEdit();
    }
  }

  return (
    <Modal
      open={true}
      title={
        <span>
          {requiredValue ? "Edit Required Value" : "Edit Your Value"}
          <br />
          {property.formatted_name}
        </span>
      }
      actions={
        <>
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>

          {saving ? (
            <Spinner />
          ) : (
            <Button color="primary" variant="text" disabled={!value} onClick={handleEdit}>
              Accept
            </Button>
          )}
        </>
      }
    >
      <form>
        {requiredValue ? (
          <Typography color="error">
            Editing a required value of a required property will result in a deviation, requiring more information and
            documentation.
          </Typography>
        ) : (
          <Typography>
            If this value does not match the required value then a deviation will be triggered and further information is
            required.
          </Typography>
        )}

        <Box mt={3}>
          <TextField
            label="Property Requirement"
            variant="outlined"
            color="secondary"
            value={value || ""}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            fullWidth
            autoFocus
          />
        </Box>
      </form>
    </Modal>
  );
}

export default EditPropertyValueModal;
