import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";

import RoundedButton from "components/Buttons/RoundedButton";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import Spinner from "components/Spinner";
import DeviationSourceCard from "./children/DeviationSourceCard";
import { useGlobalUI } from "context/globalUI";
import { useOrder } from "context/order";
import { useAPI, RESOURCES } from "utils/api";

const styles = (theme) => ({
  backButton: theme.buttons.back,
  subheader: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(4),

    "& > :first-child": {
      marginRight: theme.spacing(6)
    }
  },
  propertyTitle: {
    fontSize: 28,
    fontWeight: theme.typography.fontWeightRegular
  }
});

const SOURCE = {
  id: null,
  type: "",
  document_id: "",
  deviation_number: "",
  comment: ""
};

const EFFECT = {
  document_number: "",
  document_section: "",
  type: "",
  comment: ""
};

function TestResultsDeviation({ classes }) {
  const [sources, setSources] = React.useState([{ ...SOURCE }]);
  const [effect, setEffect] = React.useState(EFFECT);

  const [coating, setCoating] = React.useState(null);
  const [test, setTest] = React.useState(null);
  const [property, setProperty] = React.useState(null);
  const [deviationTypes, setDeviationTypes] = React.useState([]);

  const { callWithAuth } = useAPI();
  const params = useParams();
  const history = useHistory();
  const { navDrawer } = useGlobalUI();
  const { order, loading, testsLoading, setCoatingTestPropertyDeviation } = useOrder();

  const backURL = property && !test.deleted ? `/new/order/${coating.id}/tests/${test.id}` : "/new/order";

  React.useEffect(() => {
    if (!order) {
      // Order is loading
      return;
    }

    const { coatingId, testId, id: propertyId } = params;
    const coating = order.coatings.find(({ id }) => id === +coatingId);
    const test = coating.tests.find(({ id }) => id === +testId);
    const property = propertyId ? test.properties.find(({ id }) => id === +propertyId) : null;

    setCoating(coating);
    setTest(test);
    setProperty(property);

    if (property && property.deviation) {
      setEffect({
        document_number: property.deviation.document_number,
        document_section: property.deviation.document_section,
        type: property.deviation.type,
        comment: property.deviation.comment
      });

      if (property.deviation.sources) {
        setSources(property.deviation.sources);
      }
    } else if (test && test.deviation) {
      setEffect({
        document_number: test.deviation.document_number,
        document_section: test.deviation.document_section,
        type: test.deviation.type,
        comment: test.deviation.comment
      });

      if (test.deviation.sources) {
        setSources(test.deviation.sources);
      }
    }

    fetchDeviationTypes();
  }, [order]);

  async function fetchDeviationTypes() {
    const response = await callWithAuth({ method: "GET", url: RESOURCES.deviationTypes });
    setDeviationTypes(response);
  }

  function handleAdd() {
    const sourcesClone = sources.slice();
    sourcesClone.push({});
    setSources(sourcesClone);
  }

  async function handleSave() {
    try {
      await setCoatingTestPropertyDeviation(coating, test, property, {
        ...effect,
        sources
      });
    } catch (e) {
      return;
    }

    history.push(backURL);
  }

  const handleEffectFieldChange = (field, value) => {
    const effectClone = { ...effect, [field]: value };
    setEffect(effectClone);
  };

  const handleSourceFieldChange = (index) => (field, value) => {
    const sourcesClone = sources.slice();
    sourcesClone[index][field] = value;
    setSources(sourcesClone);
  };

  const handleSourceDelete = (index) => () => {
    const sourcesClone = sources.slice();
    sourcesClone.splice(index, 1);
    setSources(sourcesClone);
  };

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader
        title="Deviation Log"
        breadcrumbs={["New Order", "Results Input", "Deviation Log"]}
        hint={
          <span>
            Start by adding a new document and providing the listed information for each doc.
            <br />
            <br />
            When you're finished, be sure to save you're information at the bottom of the page.
          </span>
        }
      >
        <Button component={Link} to={backURL} startIcon={<ChevronLeftIcon />} className={classes.backButton} color="primary">
          Back
        </Button>
      </PageHeader>

      <PageContent>
        {!test ? (
          <Spinner />
        ) : (
          <>
            <Box pb={6}>
              <Typography variant="h5" color="primary">
                Current Deviation
              </Typography>
              <Typography className={classes.propertyTitle}>
                {property
                  ? `${test.formatted_name} - ${property.name}${property.layer ? ` (${property.layer})` : ""}`
                  : test.formatted_name}
              </Typography>
            </Box>

            <div className={classes.subheader}>
              <Typography variant="h5">Deviations</Typography>
              <RoundedButton color="primary" startIcon={<AddIcon color="secondary" />} onClick={handleAdd}>
                Add A Deviation
              </RoundedButton>
            </div>

            <Box pt={1} pb={6}>
              {sources.map((source, index) => (
                <DeviationSourceCard
                  key={index}
                  deviationTypes={deviationTypes}
                  source={source}
                  title={`Deviation ${index + 1}`}
                  onDelete={handleSourceDelete(index)}
                  onChange={handleSourceFieldChange(index)}
                />
              ))}
            </Box>

            <RoundedButton color="primary" onClick={handleSave} size="large" disabled={testsLoading}>
              Save and Go Back
            </RoundedButton>
          </>
        )}
      </PageContent>
    </div>
  );
}

export default withStyles(styles)(TestResultsDeviation);
