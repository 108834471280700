import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  paper: {
    minWidth: 600,
    [theme.breakpoints.down("xs")]: {
      minWidth: "85%"
    },
    background: theme.palette.common.white
  },
  buttonContainer: {
    marginTop: 30
  }
});

const Modal = ({ classes, title, children, actions, ...props }) => (
  <Dialog classes={{ paper: classes.paper }} {...props}>
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>{children}</DialogContent>
    <DialogActions className={classes.buttonContainer}>{actions}</DialogActions>
  </Dialog>
);

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node
};

export default withStyles(styles)(Modal);
