import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";

import Modal from "components/Modal";

const PROPERTIES = [
  { id: 1, title: "Property 1" },
  { id: 2, title: "Property 2" },
  { id: 3, title: "Property 3" }
];

function AddPropertyModal({ onCancel, onAdd }) {
  const [selectedPropertiesHash, setSelectedPropertiesHash] = React.useState({});

  const toggleChange = (id) => ({ target }) => {
    const selectedPropertiesHashClone = { ...selectedPropertiesHash };
    if (target.checked) {
      selectedPropertiesHashClone[id] = 1;
    } else {
      delete selectedPropertiesHashClone[id];
    }

    setSelectedPropertiesHash(selectedPropertiesHashClone);
  };

  function handleAdd() {
    const selectedProperties = PROPERTIES.filter(({ id }) => !!selectedPropertiesHash[id]);
    onAdd(selectedProperties);
  }

  const numberOfSelectedProperties = Object.keys(selectedPropertiesHash).length;

  return (
    <Modal
      open={true}
      title="Add Properties To This Test"
      actions={
        <>
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>

          <Button color="primary" variant="text" disabled={!numberOfSelectedProperties} onClick={handleAdd}>
            Add
          </Button>
        </>
      }
    >
      <Grid container spacing={0}>
        {PROPERTIES.map(({ id, title }) => (
          <Grid item xs={6} key={id}>
            <FormControlLabel
              control={<Checkbox checked={!!selectedPropertiesHash[id]} onChange={toggleChange(id)} />}
              label={title}
            />
          </Grid>
        ))}
      </Grid>
    </Modal>
  );
}

export default AddPropertyModal;
