import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export default withStyles((theme) => ({
  root: {
    color: "#FFF"
  },
  label: {
    textTransform: "none",
    fontSize: 20,
    fontWeight: theme.typography.fontWeightLight
  }
}))(Button);
