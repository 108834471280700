import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Spinner from "components/Spinner";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  statContainer: {
    border: "1px solid lightGray",
    borderRadius: 4,
    padding: theme.spacing(4, 0),
    marginTop: theme.spacing(2),
    width: "80%"
  },
  stat: {
    fontSize: 50
  },
  descriptorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  statDescriptor: {
    color: theme.palette.info.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 20
  }
});

function SystemStats({ classes, systemStats }) {
  return (
    <Grid className={classes.root} container spacing={8}>
      <Grid className={classes.statGroup} item xs={12} sm={6}>
        <Typography variant="h5">Total Orders Submitted</Typography>
        <Typography>All completed orders that have been submitted and paid for</Typography>
        {!systemStats ? (
          <Spinner />
        ) : (
          <Grid className={classes.statContainer} container spacing={1}>
            <Grid item xs={6}>
              <Typography align="right" variant="h5" className={classes.stat}>
                {systemStats.total_orders_submitted}
              </Typography>
            </Grid>
            <Grid className={classes.descriptorContainer} item xs={6}>
              <div className={classes.statDescriptor}>Orders</div>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid className={classes.statGroup} item xs={12} sm={6}>
        <Typography variant="h5">Total Items in Development</Typography>
        <Typography>Coatings or procedured currently being developed by Suppliers</Typography>
        {!systemStats ? (
          <Spinner />
        ) : (
          <Grid className={classes.statContainer} container spacing={1}>
            <Grid item xs={6}>
              <Typography align="right" variant="h5" className={classes.stat}>
                {systemStats.total_orders_in_development}
              </Typography>
            </Grid>
            <Grid className={classes.descriptorContainer} item xs={6}>
              <div className={classes.statDescriptor}>Coatings</div>
              <div className={classes.statDescriptor}>or Procedures</div>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(SystemStats);
