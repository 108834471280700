import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ChevronRight from "@material-ui/icons/ChevronRight";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import AddIcon from "@material-ui/icons/Add";
import SendIcon from "@material-ui/icons/SendOutlined";

import RoundedButton from "components/Buttons/RoundedButton";
import TableCell from "./TableCell";
import Pagination from "./Pagination";
import Tooltip from "../Tooltip";
import ContextMenu from "../ContextMenu";
import { useTables } from "context/tables";
import { Typography } from "@material-ui/core";
import { formatISODate, formatNumberSuffix } from "utils/format";
import { useAuth } from "context/auth";
import { useUserCompany } from "context/userCompany";
import CreateNewOrderModal from "./TableModals/Modals/CreateNewOrderModal";
import SavedCoatingModal from "pages/Order/children/SavedCoatingModal";

const styles = (theme) => ({
  table: {
    minWidth: 650
  },
  headerCell: { alignItems: "center" },
  accepted: { color: theme.palette.secondary.main, fontWeight: theme.typography.fontWeightMedium },
  approved: { color: theme.palette.secondary.main, fontWeight: theme.typography.fontWeightMedium },
  rejected: { color: theme.palette.error.main, fontWeight: theme.typography.fontWeightMedium },
  awaiting: { fontWeight: theme.typography.fontWeightMedium },
  inreview: { fontWeight: theme.typography.fontWeightMedium },
  exhibit7: { color: theme.palette.error.main, fontWeight: theme.typography.fontWeightMedium },
  active: { color: theme.palette.info.main, fontWeight: theme.typography.fontWeightMedium },
  hold: { color: theme.palette.primary.main, fontWeight: theme.typography.fontWeightMedium },
  requested: {
    color: theme.palette.info.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  blue: { color: theme.palette.info.main },
  red: { color: theme.palette.error.main },
  footer: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& > *": {
      marginRight: theme.spacing(4)
    }
  },
  footerText: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium
  }
});

const icons = {
  INFO_ICON: InfoIcon,
  CLOSE_ICON: CloseIcon,
  CHEVRON_RIGHT_ICON: ChevronRight,
  ADD_ICON: AddIcon,
  SEND_ICON: SendIcon
};

function MetcutTable({ classes, table, usePagination = false, showFooter = false, tableItems = [], onRefreshRequest, ...rest }) {
  const [limit, setLimit] = useState(10);
  const [start, setStart] = useState(0);

  const {
    modals: { open, names },
    redirectAction,
    tableNames,
    tables,
    tableHeaders
  } = useTables();
  const auth = useAuth();
  const { user } = useUserCompany();

  const STATUSES = [
    { name: "Accepted", class: "accepted" },
    { name: "Exhibit 7", class: "exhibit7" },
    { name: "Rejected", class: "rejected" },
    { name: "Active", class: "active" },
    { name: "Hold", class: "hold" },
    { name: "In Review", class: "inreview" },
    { name: null, class: "awaiting" }
  ];

  const OPTIONS = [
    {
      name: "Edit Coating",
      itemHandler: ({ itemId, itemType }) => redirectAction(`/edit/${itemType}s/${itemId}`),
      icon: <icons.CHEVRON_RIGHT_ICON className={classes.blue} />,
      reqs: { table: tables.ITEMS_IN_DEV }
    },
    {
      name: "Order",
      itemHandler: ({ ...rest }) =>
        open({
          ...rest,
          modalName: names.NEW_ORDER,
          callback: () => onRefreshRequest && onRefreshRequest()
        }),
      icon: <icons.CHEVRON_RIGHT_ICON className={classes.blue} />,
      reqs: { table: tables.ITEMS_IN_DEV }
    },
    {
      name: "Delete",
      itemHandler: (itemInfo) =>
        open({
          ...itemInfo,
          modalName: names.DELETE_ITEM,
          callback: () => onRefreshRequest && onRefreshRequest()
        }),
      icon: <icons.CLOSE_ICON className={classes.red} />,
      reqs: { table: tables.ITEMS_IN_DEV }
    },
    {
      name: "View Full Request",
      icon: <icons.CHEVRON_RIGHT_ICON className={classes.blue} />,
      itemHandler: ({ id }) => redirectAction(`/metcut-admin/request/${id}`),
      reqs: { table: tables.PENDING_ACC_REQUESTS }
    },
    {
      name: "Request Priority For This Item",
      itemHandler: ({ id, itemType }) => redirectAction(`/misc/payment?order_item_id=${id}&item_type=${itemType}`),
      icon: <icons.SEND_ICON className={classes.blue} />,
      reqs: {
        table: tables.ITEMS_SUBMITTED || tables.ITEMS_HISTORY || tables.ACCEPTED_COATINGS || tables.ACCEPTED_PROCEDURES,
        priority_requested: false
      }
    },
    {
      name: "View Order & Receipt",
      itemHandler: ({ orderId, supplierID }) =>
        redirectAction(user.company_id === supplierID ? `/orders/${orderId}` : `/orders/${orderId}?supplierID=${supplierID}`),
      icon: <icons.CHEVRON_RIGHT_ICON className={classes.blue} />,
      reqs: {
        table: tables.ITEMS_SUBMITTED || tables.ITEMS_HISTORY || tables.ACCEPTED_COATINGS || tables.ACCEPTED_PROCEDURES
      }
    },
    {
      name: "View Order & Receipt",
      itemHandler: ({ orderId, supplierID }) =>
        redirectAction(user.company_id === supplierID ? `/orders/${orderId}` : `/orders/${orderId}?supplierID=${supplierID}`),
      icon: <icons.CHEVRON_RIGHT_ICON className={classes.blue} />,
      reqs: {
        table: tables.ACCEPTED_COATINGS || tables.ACCEPTED_PROCEDURES
      }
    },
    {
      name: "View Status Explanation",
      itemHandler: ({ ...rest }) => open({ ...rest, modalName: names.STATUS_EXPLAIN }),
      icon: <icons.CHEVRON_RIGHT_ICON className={classes.blue} />,
      reqs: {
        table: tables.ITEMS_SUBMITTED || tables.ITEMS_HISTORY || tables.ACCEPTED_COATINGS || tables.ACCEPTED_PROCEDURES
        // status_title: true || null
      }
    },
    {
      name: "Create Order For Second Submission",
      itemHandler: ({ ...rest }) =>
        open({
          ...rest,
          modalName: names.SECOND_ORDER,
          callback: () => onRefreshRequest && onRefreshRequest()
        }),
      icon: <icons.ADD_ICON color="secondary" />,
      reqs: { table: tables.ITEMS_SUBMITTED, status_title: "Exhibit 7" }
    },
    /*{
      name: "Create New Order",
      //open({ ...rest, modalName: names.NEW_ORDER }) new/coating?add=1
      itemHandler: ({ itemId, itemType }) => redirectAction(`/edit/${itemType}s/${itemId}`),
      icon: <icons.ADD_ICON color="secondary" />,
      reqs: { table: tables.ACCEPTED_COATINGS || tables.ITEMS_HISTORY || tables.ACCEPTED_PROCEDURES, status_title: "Accepted" }
    }, */
    {
      name: "Request Appeal",
      itemHandler: ({ ...rest }) => open({ ...rest, modalName: names.REQUEST_APPEAL }),
      icon: <icons.SEND_ICON className={classes.blue} />,
      reqs: { table: tables.ITEMS_HISTORY, status_title: "Rejected" }
    },
    {
      name: "Approve Priority Request",
      itemHandler: ({ ...rest }) =>
        open({
          ...rest,
          modalName: names.APPROVE_REQUEST,
          callback: () => onRefreshRequest && onRefreshRequest()
        }),
      icon: <icons.ADD_ICON className={classes.blue} />,
      reqs: { priority_status: "requested", permissions: auth.hasPermission("admin.orders.edit") }
    },
    {
      name: "Deny Priority Request",
      itemHandler: ({ ...rest }) =>
        open({
          ...rest,
          modalName: names.DENY_REQUEST,
          callback: () => onRefreshRequest && onRefreshRequest()
        }),

      icon: <icons.CLOSE_ICON className={classes.red} />,
      reqs: { priority_status: "requested", permissions: auth.hasPermission("admin.orders.edit") }
    }
  ];

  // register a new key here to compare against the option's reqs
  const characteristics = ["table", "status_title", "submission_count", "priority_requested", "priority_status", "permissions"];

  const setOptions = (row) => {
    // determine characteristics of the row
    let rowChars = characteristics.reduce((acc, val) => {
      if (val === "table") acc.table = table;
      if (val === "permissions") acc.permissions = 1;
      Object.keys(row).forEach((rowKey) => (rowKey === val ? (acc[rowKey] = row[rowKey]) : (acc = acc)));
      return acc;
    }, {});

    let options = OPTIONS.reduce((acc, o) => {
      let match = false;
      for (const reqKey of Object.keys(o.reqs)) {
        if (o.reqs[reqKey] === rowChars[reqKey]) {
          match = true;
        } else {
          match = false;
          break;
        }
      }
      if (match) acc.push(o);
      return acc;
    }, []);

    return options;
  };

  const HEADER_CELLS = tableHeaders[table].map((header) => createHeaderCells(header));

  const ROW_DATA = tableItems;

  function createHeaderCells({ name, icon = false, handleClick, tooltipText }) {
    const IconComponent = icons[icon];
    return (
      <Tooltip placement="top" arrow title={tooltipText || ""}>
        <TableCell className={classes.headerCell}>
          {name} {!!icon && <IconComponent onClick={handleClick} />}
        </TableCell>
      </Tooltip>
    );
  }

  const determineCellStyle = (key, row) => {
    if (key !== "status_title") return;
    const curStatus = STATUSES.find((x) => row.status_title === x.name);
    return classes[curStatus.class];
  };

  const formatCellValue = (key, name, row) => {
    let value = row[key];
    if (name.includes("Coating / Procedure")) value = row[key[0]] ? row[key[0]] : row[key[1]];
    if (name === "Type") value = row[key] === null ? row.procedure_type : row[key] ? "Full" : "Partial";
    if (key === "submission_count") value = !!row[key] ? `${formatNumberSuffix(row[key])} submission` : "Not Submitted";
    if (name.includes("Name") && Array.isArray(key)) value = key.map((i) => row[i] + " ");
    if (name === "User Requested" && Array.isArray(key)) value = key.map((i) => row[i] + " ");
    if (name.includes("Date") || name.includes("Updated")) value = formatISODate(row[key]);
    if (name === "Status") value = row[key] ? row[key] : "Awaiting Package";
    if (name === "Priority") value = formatPriorityStatus(row[key]);
    if (name === "Priority Fee") value = `$${(row[key] / 100).toFixed(2)}`;
    return value;
  };

  const formatPriorityStatus = (status) => {
    if (!status) return " - ";
    if (status === "rejected") {
      return <span className={classes.rejected}>Rejected</span>;
    }
    if (status === "approved") {
      return <span className={classes.approved}>Approved</span>;
    }
    if (status === "requested") {
      return <span className={classes.requested}>Requested</span>;
    }
  };

  return (
    <>
      {/* <Typography variant="h5">{tableTitles[table]}</Typography> */}
      <TableContainer>
        {usePagination && (
          <Pagination
            disabled={usePagination || false}
            limit={limit}
            start={start}
            onChangeLimit={setLimit}
            onChangeStart={setStart}
            totalRows={tableItems && !!tableItems.length ? tableItems[0].count : 0}
          />
        )}
        <Table size="small" className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {HEADER_CELLS.map((cell) => cell)}
              <TableCell>Actions</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {ROW_DATA.map((row) => {
              let itemType = row.coating ? "coating" : "procedure";
              let itemId = row.coating ? row.coating_id : row.procedure_id;
              return (
                <TableRow key={row.name} className={classes.tableRow}>
                  {tableHeaders[table].map(({ key, name }) => {
                    return (
                      <TableCell className={determineCellStyle(key, row)} align="left">
                        {formatCellValue(key, name, row)}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ContextMenu
                      options={setOptions(row)}
                      itemId={itemId}
                      id={row.id}
                      supplierID={rest.supplierID}
                      itemType={itemType}
                      orderId={row.order_id}
                      orderItemId={row.order_item_id || row.id}
                      statusExplain={row.status_explain}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {!!showFooter && (
          <div className={classes.footer}>
            <Typography color="primary" className={classes.footerText}>
              Showing {tableItems.length} of {tableItems[0].count}
            </Typography>
            <RoundedButton
              disabled={tableItems[0].count <= 5}
              color="primary"
              size="small"
              component={Link}
              to={`/home/all/${tableNames[table]}`}
            >
              View All
            </RoundedButton>
          </div>
        )}
      </TableContainer>
    </>
  );
}

export default withStyles(styles)(MetcutTable);
