import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { useAPI, RESOURCES } from "utils/api";
import Modal from "components/Modal";
import Spinner from "components/Spinner";
import cartTypes from "utils/cartTypes";

const styles = (theme) => ({
  warning: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(2)
  },
  bold: { fontWeight: theme.typography.fontWeightMedium },
  blue: { color: theme.palette.info.main }
});

const CreateNewOrderModal = ({ classes, onClose, itemType, orderItemId, orderInDev, itemId }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const api = useAPI();
  const history = useHistory();

  const handleConfirm = async () => {
    setError(null);
    setLoading(true);

    try {
      await api.callWithAuth({ method: "POST", url: "/orders/new", axiosOptions: { data: { order_item_id: orderItemId } } });
    } catch (e) {
      setLoading(false);
      return;
    }
    window.location.reload();
    setLoading(false);
    onClose();
    history.push(`/new/order`);
    window.location = "/new/order";
  };

  return (
    <Modal
      open={true}
      title="Develop Order With This Item"
      actions={
        <>
          {loading && <Spinner size={20} />}

          <Button variant="text" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button className={classes.blue} variant="text" disabled={loading} onClick={handleConfirm}>
            Continue
          </Button>
        </>
      }
    >
      {orderInDev && (
        <Typography className={classes.warning}>
          {`There is currently an order in development. If you continue, that order will be saved and replaced with this one.
          Otherwise, complete your order and return back here to start a new order with this ${itemType}`}
        </Typography>
      )}
      <Typography paragraph>Clicking 'Continue' will redirect you to the cart with this item in the cart.</Typography>
    </Modal>
  );
};

export default withStyles(styles)(CreateNewOrderModal);
