import React from "react";
import { Link } from "react-router-dom";

import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import RoundedButton from "components/Buttons/RoundedButton";

export default function ShippingAddressGrid({ labelWidthXs, valueWidthXs, classes, shippingAddress }) {
  //TODO: "same as company address", "same as billing address" radio buttons
  return (
    <>
      <div className={classes.subheader}>
        <Typography variant="h6">Shipping Address</Typography>
        <RoundedButton
          color="secondary"
          size="small"
          startIcon={<EditIcon />}
          component={Link}
          to="/account/shippingAddress/edit"
        >
          Edit
        </RoundedButton>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={labelWidthXs}>
          Address 1
        </Grid>
        <Grid item xs={valueWidthXs} className={classes.value}>
          {shippingAddress ? shippingAddress.address1 : "-"}
        </Grid>

        <Grid item xs={labelWidthXs}>
          Address 2
        </Grid>
        <Grid item xs={valueWidthXs} className={classes.value}>
          {shippingAddress ? shippingAddress.address2 : "-"}
        </Grid>

        <Grid item xs={labelWidthXs}>
          City
        </Grid>
        <Grid item xs={valueWidthXs} className={classes.value}>
          {shippingAddress ? shippingAddress.city : "-"}
        </Grid>

        <Grid item xs={labelWidthXs}>
          State / Province
        </Grid>
        <Grid item xs={valueWidthXs} className={classes.value}>
          {shippingAddress ? shippingAddress.region : "-"}
        </Grid>

        <Grid item xs={labelWidthXs}>
          Zip
        </Grid>
        <Grid item xs={valueWidthXs} className={classes.value}>
          {shippingAddress ? shippingAddress.postal_code : "-"}
        </Grid>

        <Grid item xs={labelWidthXs}>
          Country
        </Grid>
        <Grid item xs={valueWidthXs} className={classes.value}>
          {shippingAddress ? shippingAddress.country : "-"}
        </Grid>
      </Grid>
    </>
  );
}
