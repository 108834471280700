import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import EditIcon from "@material-ui/icons/Edit";

import RoundedButton from "components/Buttons/RoundedButton";
import Spinner from "components/Spinner";
import { useOrder } from "context/order";
import { useUserCompany } from "context/userCompany";

const styles = (theme) => ({
  address: {
    fontSize: 18
  },
  orderInfoGroup: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    // marginBottom: theme.spacing(1),

    "& > :first-child": {
      marginRight: theme.spacing(3)
    }
  },
  infoGroupHeader: {
    fontSize: 22
  },
  right: {
    border: "1px solid lightGray",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(5)
    }
  },
  rightHeader: {
    margin: theme.spacing(0, 0, 2, 4)
  },
  itemPriceGroup: {
    marginBottom: theme.spacing(1)
  },
  price: {
    textAlign: "right"
  },
  summary: {},
  shippingPriceGroup: {
    marginBottom: theme.spacing(2)
  },
  summaryPricesGroup: {
    marginBottom: theme.spacing(2)
  },
  summaryLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 18
  },
  summaryPrice: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 18,
    textAlign: "right"
  },
  purchaseButton: {
    margin: `${theme.spacing(3)}px auto`,
    display: "block"
  }
});

function Review({ classes, onSave, onSubmit, loading, setActiveStep }) {
  const {
    order,
    calcGrandTotal,
    calcSubTotal,
    calcSalesTax,
    stripePaymentDetails,
    chosenShippingMethod,
    noCoatings
  } = useOrder();
  const { companyInfo } = useUserCompany();

  const [saving, setSaving] = React.useState(false);

  function handlePurchase() {
    setSaving(true);
    // onSave(order);
    onSubmit(order);
  }

  function handleEditClick(step) {
    setActiveStep(step);
    window.scrollTo(0, 0);
  }

  function determineShippingCost() {
    if (!chosenShippingMethod.price) {
      return 0;
    }
    if (companyInfo.preferred_carrier_account) {
      return 0;
    } else {
      return parseInt(chosenShippingMethod.price);
    }
  }

  return (
    <Grid container spacing={4}>
      <>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5">Order Information</Typography>

          <Box mb={2} mt={2}>
            <div className={classes.orderInfoGroup}>
              <Typography className={classes.infoGroupHeader} variant="h6" color="primary">
                Payment Details
              </Typography>
              <RoundedButton
                onClick={() => handleEditClick(noCoatings ? 0 : 1)}
                color="secondary"
                size="small"
                startIcon={<EditIcon />}
              >
                Edit
              </RoundedButton>
            </div>

            <Typography className={classes.address} paragraph>
              {stripePaymentDetails.card.brand.charAt(0).toUpperCase() + stripePaymentDetails.card.brand.slice(1)}
              <br />
              ******** {stripePaymentDetails.card.last4}
              <br />
              {`${stripePaymentDetails.card.exp_month}/${stripePaymentDetails.card.exp_year}`}
            </Typography>
          </Box>

          <Box mb={2} mt={2}>
            <div className={classes.orderInfoGroup}>
              <Typography className={classes.infoGroupHeader} variant="h6" color="primary">
                Billing Address
              </Typography>
              <RoundedButton
                onClick={() => handleEditClick(noCoatings ? 0 : 1)}
                color="secondary"
                size="small"
                startIcon={<EditIcon />}
              >
                Edit
              </RoundedButton>
            </div>

            <Typography className={classes.address} paragraph>
              {order.addresses.billing.company_name}
              <br />
              {order.addresses.billing.address1}
              {order.addresses.billing.address2 ? `, ${order.addresses.billing.address2}` : ""}
              <br />
              {`${order.addresses.billing.city} ${order.addresses.billing.region}, ${order.addresses.billing.country}, ${order.addresses.billing.postal_code}`}
            </Typography>
          </Box>

          {!noCoatings && (
            <Box mb={4} mt={2}>
              <div className={classes.orderInfoGroup}>
                <Typography className={classes.infoGroupHeader} variant="h6" color="primary">
                  Return Shipping Address
                </Typography>
                <RoundedButton onClick={() => handleEditClick(0)} color="secondary" size="small" startIcon={<EditIcon />}>
                  Edit
                </RoundedButton>
              </div>

              <Typography className={classes.address} paragraph>
                {order.addresses.shipping.company_name}
                <br />
                {order.addresses.shipping.address1}
                {order.addresses.shipping.address2 ? `, ${order.addresses.shipping.address2}` : ""}
                <br />
                {`${order.addresses.shipping.city} ${order.addresses.shipping.region}, ${order.addresses.shipping.country}, ${order.addresses.shipping.postal_code}`}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid className={classes.summary} item xs={12} sm={6}>
          <Typography variant="h5" className={classes.rightHeader}>
            Order Items Summary
          </Typography>
          <Box p={2} borderRadius="borderRadius" className={classes.right}>
            <Grid container spacing={1}>
              {!!order.coatings.length &&
                order.coatings.map((item) => {
                  return (
                    <Grid key={item.id} container spacing={3} className={classes.itemPriceGroup}>
                      <Grid item xs={9}>
                        <Typography>Coating</Typography>
                        <Typography variant="caption">{item.coating_string}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.price}>${item.price.toFixed(2)}</Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              {!!order.procedures.length &&
                order.procedures.map((item) => {
                  return (
                    <Grid key={item.id} container spacing={3} className={classes.itemPriceGroup}>
                      <Grid item xs={9}>
                        <Typography>{item.title}</Typography>
                        <Typography variant="caption">procedure num: {item.procedure_number}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.price}>${item.price.toFixed(2)}</Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              {!noCoatings && (
                <Grid container className={classes.shippingPriceGroup}>
                  <Grid item xs={9}>
                    <Typography>Shipping - {chosenShippingMethod.name} Delivery</Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography className={classes.price}>
                      {companyInfo.preferred_carrier_account
                        ? "Determined by carrier"
                        : `$${chosenShippingMethod.price.toFixed(2)}`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid className={classes.summaryPricesGroup} container>
                <Grid item xs={9}>
                  <Typography className={classes.summaryLabel}>Sub Total:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.summaryPrice}>
                    $
                    {noCoatings
                      ? calcSubTotal(order.procedures)
                      : calcSubTotal(order.coatings.concat(order.procedures), determineShippingCost())}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className={classes.summaryLabel}>Sales Tax:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.summaryPrice}>
                    $
                    {noCoatings
                      ? calcSalesTax(order.procedures)
                      : calcSalesTax(order.coatings.concat(order.procedures), determineShippingCost())}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className={classes.summaryLabel}>Grand Total:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.summaryPrice}>
                    $
                    {noCoatings
                      ? calcGrandTotal(order.coatings.concat(order.procedures), 0)
                      : calcGrandTotal(order.coatings.concat(order.procedures), determineShippingCost())}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {loading ? (
              <Spinner />
            ) : (
              <RoundedButton
                color="primary"
                size="large"
                onClick={handlePurchase}
                className={classes.purchaseButton}
                disabled={loading}
              >
                Purchase
              </RoundedButton>
            )}
          </Box>
        </Grid>{" "}
      </>

      <RoundedButton component={Link} to="/new/order" color="primary" size="large">
        Back to Edit Order
      </RoundedButton>
    </Grid>
  );
}

export default withStyles(styles)(Review);
