import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import ChevronRight from "@material-ui/icons/ChevronRight";

import { useUserCompany } from "context/userCompany";
import { useAPI, RESOURCES } from "utils/api";
import { useGlobalUI } from "context/globalUI";
import { useTables } from "context/tables";
import PageContent from "components/PageContent";
import PageHeader from "components/PageHeader";
import Spinner from "components/Spinner";
import SystemStats from "./children/SystemStats";

import MetcutTable from "components/Tables/MetcutTable";

const REFRESH_TIMEOUT = 60000;

const styles = (theme) => ({
  pageContent: {
    marginBottom: theme.spacing(11)
  },
  autocomplete: {
    marginTop: theme.spacing(1)
  },
  autocompletePaper: {
    background: theme.palette.common.white
  },
  noSupplier: {
    textAlign: "center"
  },
  noSupplierText: {
    color: "#999",
    fontSize: 22,
    fontWeight: theme.typography.fontWeightMedium,
    margin: theme.spacing(3, 0, 0, 3)
  },
  welcome: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightMedium
  },
  subheader: {
    marginBottom: theme.spacing(4)
  },
  subheader2: {
    marginBottom: theme.spacing(1)
  },
  awaitSyncHeader: {
    marginTop: theme.spacing(8)
  },
  blue: {
    color: theme.palette.info.main
  },
  red: {
    color: theme.palette.error.main
  },
  noRequests: {
    fontSize: 20,
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    border: "1px solid lightGray",
    borderRadius: 4,
    padding: theme.spacing(2, 4),
    width: "max-content"
  },
  tableTitle: {
    marginBottom: theme.spacing(3)
  },
  bottomOfPage: {
    height: 100
  }
});

function MetcutHome({ classes }) {
  const [requests, setRequests] = useState(null);
  const [requestsLoading, setRequestsLoading] = useState(true);
  const [awaitingSync, setAwaitingSync] = useState(null);
  const [awaitingReview, setAwaitingReview] = useState(null);
  const [awaitingReviewLoading, setAwaitingReviewLoading] = useState(null);
  const [awaitingSyncLoading, setAwaitingSyncLoading] = useState(true);
  const [systemStats, setSystemStats] = useState(null);
  const [systemStatsLoading, setSystemStatsloading] = useState(true);
  const [priorityRequests, setPriorityRequests] = useState(null);
  const [priorityRequestsLoading, setPriorityRequestsLoading] = useState(true);
  const timeoutRefs = React.useRef({});

  const api = useAPI();
  const { user } = useUserCompany();
  const { navDrawer, approveDenyRequest } = useGlobalUI();
  const { tables } = useTables();

  React.useEffect(() => {
    getAccountRequests();
    // getItemsAwaitingSync();
    // getItemsAwaitingReview();
    getSystemStats();
    getPriorityRequests();
  }, [approveDenyRequest]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRefs.current.requests);
      clearTimeout(timeoutRefs.current.systemStats);
      clearTimeout(timeoutRefs.current.priorityRequests);
    };
  }, []);

  async function getAccountRequests(background = false) {
    clearTimeout(timeoutRefs.current.requests);

    if (!background) {
      setRequestsLoading(true);
    }

    const response = await api.callWithAuth({ method: "GET", url: RESOURCES.accountRequests });
    setRequests(response);
    setRequestsLoading(false);

    timeoutRefs.current.requests = setTimeout(() => getAccountRequests(true), REFRESH_TIMEOUT);
  }

  // async function getItemsAwaitingSync() {
  //   const response = await api.callWithAuth({ method: "GET", url: "/orders/all/awaiting_sync?offset=5&limit=5" });
  //   setAwaitingSync(response);
  //   setAwaitingSyncLoading(false);
  // }
  // async function getItemsAwaitingReview() {
  //   const response = await api.callWithAuth({ method: "GET", url: "/orders/all/awaiting_review" });
  //   setAwaitingReview(response);
  //   setAwaitingReviewLoading(false);
  // }

  async function getSystemStats(background = false) {
    clearTimeout(timeoutRefs.current.systemStats);

    if (!background) {
      setSystemStatsloading(true);
    }

    const response = await api.callWithAuth({ method: "GET", url: "/tables/system" });
    setSystemStats(response);
    setSystemStatsloading(false);

    timeoutRefs.current.systemStats = setTimeout(() => getSystemStats(true), REFRESH_TIMEOUT);
  }

  async function getPriorityRequests(background = false) {
    clearTimeout(timeoutRefs.current.priorityRequests);

    if (!background) {
      setSystemStatsloading(true);
    }

    const response = await api.callWithAuth({ method: "GET", url: RESOURCES.priorityRequestItems("requested") });
    setPriorityRequests(response);
    setPriorityRequestsLoading(false);

    timeoutRefs.current.priorityRequests = setTimeout(() => getPriorityRequests(true), REFRESH_TIMEOUT);
  }

  const handleRefreshRequest = (table) => async () => {
    if (table === tables.PRIORITY_REQUESTS) {
      await getPriorityRequests();
    }
  };

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader title="Admin Dashboard" hintSetting="infobox_home_admin" hintIcon={<ThumbUpOutlinedIcon color="primary" />}>
        <div className={classes.subheader}>
          <Typography color="secondary" className={classes.welcome}>
            Welcome {user.first_name} {user.last_name}!
          </Typography>
          <Typography>This is your Dashboard. The most pressing tasks and items needing attention live here.</Typography>
        </div>
      </PageHeader>
      <PageContent>
        <Typography className={classes.tableTitle} variant="h5">
          Pending Account Requests
        </Typography>
        {requestsLoading ? (
          <Spinner />
        ) : requests.length <= 0 ? (
          <Typography className={classes.noRequests}>
            <ChevronRight className={classes.chevronNoRequests} />
            All caught up, no pending account requests as of right now!
          </Typography>
        ) : (
          <>
            <MetcutTable tableItems={requests} table={tables.PENDING_ACC_REQUESTS} usePagination={false} />
          </>
        )}

        <SystemStats systemStats={systemStats} />

        <Typography className={classes.tableTitle} variant="h5">
          Priority Scheduling Requests
        </Typography>
        {priorityRequestsLoading ? (
          <Spinner />
        ) : priorityRequests.length <= 0 ? (
          <Typography className={classes.noRequests}>
            <ChevronRight className={classes.chevronNoRequests} />
            All caught up, no priority scheduling requests as of right now!
          </Typography>
        ) : (
          <MetcutTable
            tableItems={priorityRequests}
            usePagination={false}
            table={tables.PRIORITY_REQUESTS}
            onRefreshRequest={handleRefreshRequest(tables.PRIORITY_REQUESTS)}
          />
        )}

        <div className={classes.bottomOfPage} />
      </PageContent>
    </div>
  );
}

export default withStyles(styles)(MetcutHome);
