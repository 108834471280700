import React from "react";

import PageContent from "components/PageContent";
import PageHeader from "components/PageHeader";
import { useGlobalUI } from "context/globalUI";

function ErrorForbidden() {
  const { navDrawer } = useGlobalUI();
  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader title="Access Denied" />

      <PageContent>
        <div>
          It looks like you don't have permission to view this page or resource. Use the support page or contact your account
          administrator for help.
        </div>
      </PageContent>
    </div>
  );
}

export default ErrorForbidden;
