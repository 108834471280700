import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import { useAPI, RESOURCES } from "utils/api";
import Modal from "components/Modal";
import Spinner from "components/Spinner";

const styles = (theme) => ({
  red: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  redButton: theme.buttons.red,
  textFieldInput: {
    textAlign: "center",
    color: theme.palette.error.main
  }
});

const ResetPasswordModal = ({ classes, user, onCancel, onSuccess }) => {
  const [loading, setLoading] = React.useState(false);

  const api = useAPI();

  const handleConfirm = async () => {
    setLoading(true);

    try {
      await api.callWithAuth({
        method: "PUT",
        url: RESOURCES.resetPassword(user.id),
        successMessage: `${user.first_name} ${user.last_name}'s password was successfully reset!`
      });
    } catch (e) {
      return;
    } finally {
      setLoading(false);
    }

    onSuccess();
  };

  return (
    <Modal
      open={true}
      title={`Reset Password for ${user.first_name} ${user.last_name}`}
      actions={
        <>
          <Button variant="text" onClick={onCancel} disabled={loading}>
            Cancel
          </Button>

          {loading ? (
            <Spinner size={20} />
          ) : (
            <Button className={classes.redButton} variant="text" disabled={loading} onClick={handleConfirm}>
              Reset
            </Button>
          )}
        </>
      }
    >
      <Typography paragraph className={classes.red}>
        This action cannot be undone
      </Typography>
      <Typography paragraph>
        Reseting the password will send an email to the address associated with {user.first_name} {user.last_name}'s account
        containing a temporary password. {user.first_name} will then be prompted to log back in using the temporary password and
        then asked to create a new password of their own.
      </Typography>
    </Modal>
  );
};

export default withStyles(styles)(ResetPasswordModal);
