import React from "react";
import { Link, Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import Splash from "components/Splash";
import SplashScreenButton from "components/Buttons/SplashScreenButton";
import RoundedButton from "components/Buttons/RoundedButton";
import { HEADER_HEIGHT } from "components/Header";
import { useAuth } from "context/auth";
import ROUTES from "utils/routes";

const styles = (theme) => ({
  container: {
    position: "absolute",
    zIndex: 1,
    left: 0,
    right: 0,
    top: HEADER_HEIGHT,
    bottom: 0
  },
  back: {
    position: "absolute",
    left: theme.spacing(8),
    top: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      left: theme.spacing(1),
      top: theme.spacing(0.5),
      fontSize: 10
    }
  },
  left: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(0, 8, 0, 28),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(5, 2, 0, 6)
    }
  },
  leftSectionH1: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 96,
    lineHeight: 1,
    color: "#FFF",
    [theme.breakpoints.down("xs")]: {
      fontSize: 60
    }
  },
  leftSectionH2: {
    fontSize: 34,
    color: "#FFF",
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
      lineHeight: 1.3,
      paddingTop: theme.spacing(1)
    }
  },
  right: {
    backgroundColor: "#EBEBEB",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(0, 4),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2)
    }
  },
  rightSection: {
    textAlign: "center",
    padding: theme.spacing(4, 0, 8, 0),
    [theme.breakpoints.down("xs")]: {
      padding: 0
    }
  },
  rightSectionH1: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 44,
    [theme.breakpoints.down("xs")]: {
      fontSize: 32,
      lineHeight: 1
    }
  },
  rightSectionH2: {
    fontSize: 24,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      margin: theme.spacing(1, 0)
    }
  },
  getStartedButton: {
    marginTop: theme.spacing(2),
    fontSize: 38,
    borderRadius: 38,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
      borderRadius: 26,
      marginTop: theme.spacing(1)
    }
  }
});

function Index({ classes, theme }) {
  const auth = useAuth();

  if (!!auth.getToken() && !!auth.getUserRole()) {
    const role = auth.getUserRole();
    const url = role === "CompanyAdmin" || role === "User" ? ROUTES.supplierHome : ROUTES.metcutHome;
    return <Redirect to={url} />;
  }

  return (
    <>
      <Splash />

      <Grid container className={classes.container}>
        <div className={classes.back}>
          <SplashScreenButton startIcon={<ChevronLeftIcon />}>Return Home</SplashScreenButton>
        </div>

        <Grid item xs={12} sm={7} className={classes.left}>
          <div className={classes.leftSection}>
            <Typography className={classes.leftSectionH1}>CCL Round Robin Portal</Typography>
            <Typography className={classes.leftSectionH2}>Materials Analysis Laboratory</Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={5} className={classes.right}>
          <div className={classes.rightSection}>
            <Typography className={classes.rightSectionH1} gutterBottom>
              Don't have an account?
            </Typography>
            <Typography className={classes.rightSectionH2} gutterBottom>
              Fill out a simple request form to get started!
            </Typography>
            <RoundedButton color="secondary" component={Link} to="/sign-up" className={classes.getStartedButton}>
              Get Started
            </RoundedButton>
          </div>
          <div className={classes.rightSection}>
            <Typography className={classes.rightSectionH2} style={{ color: "rgba(0, 0, 0, 0.6)" }} gutterBottom>
              Already have an account?
            </Typography>
            <RoundedButton color="primary" component={Link} to="/login">
              Log In
            </RoundedButton>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default withStyles(styles)(Index);
