import React from "react";
import { Link, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CheckmarkIcon from "@material-ui/icons/CheckSharp";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { withStyles } from "@material-ui/core/styles";

import ContextMenu from "components/ContextMenu";
import Spinner from "components/Spinner";
import TableCell from "components/Tables/TableCell";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import Pagination from "components/Tables/Pagination";
import { useAPI, RESOURCES } from "utils/api";
import { useGlobalUI } from "context/globalUI";

const styles = (theme) => ({
  autocomplete: {
    marginTop: theme.spacing(7)
  },
  autocompletePaper: {
    background: theme.palette.common.white
  },
  tableName: {
    marginBottom: theme.spacing(3)
  },
  table: {
    marginTop: theme.spacing(1.25)
  },
  blue: {
    color: theme.palette.info.main
  },
  checkmark: {
    marginLeft: theme.spacing(4),
    color: theme.palette.info.main
  },

  footer: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& > *": {
      marginRight: theme.spacing(4)
    }
  },
  footerText: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium
  }
});

function Suppliers({ classes }) {
  const [suppliers, setSuppliers] = React.useState([]);
  const [selectedSupplier, setSelectedSupplier] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [limit, setLimit] = React.useState(20);
  const [start, setStart] = React.useState();

  const api = useAPI();
  const history = useHistory();
  const { navDrawer } = useGlobalUI();

  async function fetchSuppliers() {
    setLoading(true);
    const result = await api.callWithAuth({ method: "GET", url: RESOURCES.companies });
    setSuppliers(result);
    setLoading(false);
  }
  React.useEffect(() => {
    fetchSuppliers();
  }, []);

  function handleSupplierSelect(evt, value) {
    return history.push(`/suppliers/${value.id}`);
  }

  function setContextOptions(supplier) {
    return [
      {
        name: "View Supplier",
        icon: <ChevronRight className={classes.blue} />,
        itemHandler: () => history.push(`/suppliers/${supplier.id}`)
      }
    ];
  }

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader
        title="Orders"
        hint="Use the text field to search or enter the vendor code of the supplier you wish to view. Interact with supplier's orders, coatings, and procedures."
      >
        {suppliers.length <= 0 && loading ? (
          ""
        ) : (
          <Autocomplete
            options={suppliers}
            getOptionLabel={(supplier) => `${supplier.ge_vendor_code} - ${supplier.name}`}
            renderInput={(params) => <TextField {...params} label="GE Vendor Code" variant="outlined" fullWidth />}
            value={selectedSupplier}
            onChange={handleSupplierSelect}
            classes={{ root: classes.autocomplete, paper: classes.autocompletePaper }}
          />
        )}
      </PageHeader>
      <PageContent>
        <Typography className={classes.tableName} variant="h5">
          All Suppliers
        </Typography>
        {/* <Pagination
              disabled={false}
              limit={limit}
              start={start}
              onChangeLimit={setLimit}
              onChangeStart={setStart}
              totalRows={!!suppliers.length ? suppliers[0].count : 0}
            /> */}

        {suppliers.length <= 0 && loading ? (
          <Spinner />
        ) : (
          <>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Supplier</TableCell>
                  <TableCell>GE Vendor Code</TableCell>
                  <TableCell>Order In Review</TableCell>
                  <TableCell>Order Awaiting Sync</TableCell>
                  <TableCell>Priority Schedule Request</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {suppliers.map((supplier) => {
                  if (!supplier.active) return;

                  return (
                    <TableRow key={supplier.id}>
                      <TableCell>{supplier.name}</TableCell>
                      <TableCell>{supplier.ge_vendor_code}</TableCell>
                      <TableCell>{supplier.awaiting_review ? <CheckmarkIcon className={classes.checkmark} /> : ""}</TableCell>
                      <TableCell>{supplier.awaiting_sync ? <CheckmarkIcon className={classes.checkmark} /> : ""}</TableCell>
                      <TableCell>
                        {!!supplier.priority_schedule_request ? <CheckmarkIcon className={classes.checkmark} /> : ""}
                      </TableCell>
                      <TableCell>
                        <ContextMenu options={setContextOptions(supplier)} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {/* <div className={classes.footer}>
              <Typography color="primary" className={classes.footerText}>
                Showing 5 of 25
              </Typography>
              <RoundedButton color="primary" size="small" component={Link} to="/home/all/procedures/accepted">
                View All
              </RoundedButton>
            </div> */}
          </>
        )}
      </PageContent>
    </div>
  );
}

export default withStyles(styles)(Suppliers);
