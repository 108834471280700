import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AddIcon from "@material-ui/icons/Add";
import Collapse from "@material-ui/core/Collapse";

import { useOrder } from "context/order";
import { useUserCompany } from "context/userCompany";
import RoundedButton from "components/Buttons/RoundedButton";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import SavedCoatingModal from "./children/SavedCoatingModal";
import SavedProcedureModal from "./children/SavedProcedureModal";
import OrderCoatingCard from "./children/OrderCoatingCard";
import OrderProcedureCard from "./children/OrderProcedureCard";
import Spinner from "components/Spinner";
import { useGlobalUI } from "context/globalUI";
import { parseQueryString } from "utils/qs";
//import { useAPI } from "utils/api";
import cartTypes from "utils/cartTypes";

import { API_URL, RESOURCES, useAPI } from "utils/api";
import { useAuth } from "context/auth";

function NewOrder({ classes }) {
  const { order, loading, addToOrder, removeFromOrder, setCartType, cartType, forceCart } = useOrder();
  const history = useHistory();
  const { navDrawer, snackbar } = useGlobalUI();
  const location = useLocation();
  const api = useAPI();

  const [showSavedCoatingModal, setShowSavedCoatingModal] = React.useState(false);
  const [showSavedProcedureModal, setShowSavedProcedureModal] = React.useState(false);

  const MAXIMUM_ALLOWED_ORDER_ITEMS = 1;

  React.useEffect(() => {
    const qs = parseQueryString(location.search);
    // if (cartType) history.push(`/new/order?${cartTypes[cartType]}=1`);
    setCartType(qs);
  }, []);

  function toggleShowSavedCoatingModal() {
    setShowSavedCoatingModal(!showSavedCoatingModal);
  }

  function toggleShowSavedProcedureModal() {
    setShowSavedProcedureModal(!showSavedProcedureModal);
  }
  /*   */
  function handleReviewClick() {
    const noAttachment = order.procedures.reduce((acc, cur) => {
      if (!cur.attachment_filename || !cur.attachment_url || !cur.attachment_guid) {
        acc = true;
      }
      return acc;
    }, false);

    if (!!noAttachment) {
      snackbar.open({ message: "Procedure Document is required for all procedures", color: "error" });
    } else {
      history.push("/new/order/review");
    }
  }

  function handleAddProcedureToOrder(procedure) {
    toggleShowSavedProcedureModal();
    addToOrder({ procedure });
  }

  function handleAddCoatingToOrder(coating) {
    toggleShowSavedCoatingModal();
    addToOrder({ coating });
  }

  function handleDelete(object) {
    return function () {
      removeFromOrder(object);
    };
  }

  function handleCartReset() {
    order.coatings.concat(order.procedures).forEach((item) => {
      let type = !!item.coating_string ? "coating" : "procedure";
      removeFromOrder({ [type]: item });
    });
    forceCart(cartTypes.normal);
    history.push("/home");
    window.location = "/home";
  }

  const auth = useAuth();
  const handlePrintReceipt = () => {
    const url = RESOURCES.viewOrderPDF(order.id);
    window.open(`${API_URL}${url}?access_token=${auth.getToken()}`, "_blank").focus();
  };

  function handleItemEdit(type, id) {
    return history.push(`/edit/${type}/${id}?${cartType !== cartTypes.normal ? cartTypes[cartType] + "=1&" : ""}add=1`);
  }

  function pageContentMap(textType) {
    return {
      [cartTypes.second_submission]: {
        pageTitle: "Second Submission",
        pageHint: "infobox_order_one_item",
        pageHeader: "Your Order For Second Submission",
        subHead1:
          "Don't want to be resubmitting this item for second submission? Click this button to clear and reset the cart back to normal.",
        subHead2:
          "This item currently has a status of 'Exibit 7' and is being resubmitted for testing. Please add or update any test data or information."
      },
      [cartTypes.new_order]: {
        pageTitle: "Create New Order",
        pageHint: "infobox_order_one_item",
        pageHeader: "Your New Order For Item",
        subHead1:
          "Don't want to be resubmitting this item for a new order? Click this button to clear and reset the cart back to normal.",
        subHead2: "Please add any applicable tests, test data, or attachments."
      },
      [cartTypes.new_order_accepted]: {
        pageTitle: "Create New Order",
        pageHint: "infobox_order_one_item",
        pageHeader: "Your New Order For Item",
        subHead1:
          "Don't want to be resubmitting this item for order? Click this button to clear and reset the cart back to normal.",
        subHead2: "Please add any applicable tests, test data, or attachments."
      },
      [cartTypes.normal]: {
        pageTitle: orderItemsLength ? `Order: #${order.id}` : "",
        pageHint: "infobox_order_current",
        pageHeader: "",
        subHead1: "",
        subHead2: ""
      }
    }[cartType || "normal"][textType];
  }

  const orderItemsLength = order ? order.coatings.length + order.procedures.length : 0;

  const purchaseDisabled = React.useMemo(() => {
    if (!order) {
      return true;
    }

    if (!order.coatings.length && !order.procedures.length) {
      return true;
    }

    let price = 0;

    for (let i = 0; i < order.coatings.length; i++) {
      for (let j = 0; j < order.coatings[i].tests.length; j++) {
        if (order.coatings[i].tests[j].deleted) {
          // Test is deleted and there is no deviation set on it
          if (!order.coatings[i].tests[j].deviation) {
            return true;
          }
        } else {
          // Test is not deleted so we're going to check every property of it
          const result = order.coatings[i].tests[j].properties.some(
            (property) => (property.required && !property.value) || (property.deviation_required && !property.deviation)
          );

          if (result) {
            return true;
          }
        }

        price += order.coatings[i].price;
      }
    }
    if (order.procedures.length == 1 && order.procedures[0].attachment_filename != null) {
      return false;
    }
    return !price;
  }, [order]);

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader title={pageContentMap("pageTitle")} hintSetting={pageContentMap("pageHint")}>
        <Typography variant="h5" className={classes.subheader}>
          {pageContentMap("pageHeader")}
        </Typography>
      </PageHeader>

      <PageContent>
        {cartType != cartTypes.normal ? (
          <Box mt={2} mb={4} className={classes.resetContainer}>
            <Typography className={classes.resetText}>{pageContentMap("subHead1")}</Typography>
            <RoundedButton onClick={handleCartReset} className={classes.resetButton}>
              Clear & Reset
            </RoundedButton>
          </Box>
        ) : (
          ""
        )}

        {orderItemsLength >= MAXIMUM_ALLOWED_ORDER_ITEMS ? (
          <></>
        ) : (
          <Grid
            container
            spacing={4}
            className={classes.buttonContent}
          ></Grid> /*
                      Removed the following code because we no longer use the Create Order page

          <Grid container spacing={4} className={classes.buttonContent}>
            <Grid item xs={6}>
              <RoundedButton
                disabled={orderItemsLength >= MAXIMUM_ALLOWED_ORDER_ITEMS}
                color="primary"
                className={classes.mainButtons}
                fullWidth
                onClick={toggleShowSavedCoatingModal}
              >
                Continue Saved Coating
              </RoundedButton>
            </Grid>
            <Grid item xs={6}>
              <RoundedButton
                disabled={orderItemsLength >= MAXIMUM_ALLOWED_ORDER_ITEMS}
                color="primary"
                className={classes.mainButtons}
                fullWidth
                onClick={toggleShowSavedProcedureModal}
              >
                Continue Saved Procedure
              </RoundedButton>
            </Grid>
            <Grid item xs={6}>
              <RoundedButton
                color="primary"
                startIcon={<AddIcon color="secondary" />}
                fullWidth
                className={classes.mainButtons}
                disabled={orderItemsLength >= MAXIMUM_ALLOWED_ORDER_ITEMS}
                component={Link}
                to={`/new/coating?add=1`}
              >
                Create New Coating
              </RoundedButton>
            </Grid>
            <Grid item xs={6}>
              <RoundedButton
                color="primary"
                startIcon={<AddIcon color="secondary" />}
                fullWidth
                className={classes.mainButtons}
                disabled={orderItemsLength >= MAXIMUM_ALLOWED_ORDER_ITEMS}
                component={Link}
                to={`/new/procedure?add=1`}
              >
                Create New Procedure
              </RoundedButton>
            </Grid>
          </Grid>
                      */
        )}
        <Typography className={classes.subHead2}>{pageContentMap("subHead2")}</Typography>

        <div className={classes.content}>
          {loading ? (
            <Spinner />
          ) : orderItemsLength <= 0 ? (
            <div className={classes.noContent}>
              <Typography className={classes.noContentText}>Nothing in your order yet...</Typography>
            </div> // Removed from above: <img src="/assets/scientist-new-order.svg" />
          ) : (
            [
              ...order.coatings.map((coating, index) => (
                <OrderCoatingCard
                  key={`coating-${coating.id}`}
                  index={index + 1}
                  coating={coating}
                  onDelete={handleDelete({ coating })}
                  onClear={handleCartReset}
                  onEdit={
                    cartType === cartTypes.second_submission || cartType === cartTypes.new_order
                      ? null
                      : () => handleItemEdit("coatings", coating.id)
                  }
                />
              )),
              ...order.procedures.map((procedure, index) => (
                <OrderProcedureCard
                  key={`procedure-${procedure.id}`}
                  index={order.coatings.length + index + 1}
                  procedure={procedure}
                  onDelete={handleDelete({ procedure })}
                  onClear={handleCartReset}
                  onEdit={() => handleItemEdit("procedures", procedure.id)}
                />
              ))
            ]
          )}
        </div>
        <div className={`${classes.content} ${classes.pageFooter}`} style={{ textAlign: "center" }}>
          <Typography className={classes.reviewText} style={{ marginBottom: 24 }}>
            We'll review everything so far before we move on to our secure checkout.
          </Typography>
          <RoundedButton
            color="primary"
            size="large"
            disabled={loading || purchaseDisabled}
            component={Link}
            to="/new/order/checkout"
          >
            Checkout
          </RoundedButton>
          <RoundedButton onClick={handlePrintReceipt} color="secondary" size="large">
            Download Order Draft
          </RoundedButton>
        </div>
      </PageContent>

      {showSavedCoatingModal && <SavedCoatingModal onCancel={toggleShowSavedCoatingModal} onAdd={handleAddCoatingToOrder} />}

      {showSavedProcedureModal && (
        <SavedProcedureModal onCancel={toggleShowSavedProcedureModal} onAdd={handleAddProcedureToOrder} />
      )}
    </div>
  );
}

const styles = (theme) => ({
  subheader: {
    marginTop: theme.spacing(8)
  },
  mainButtons: {
    display: "flex",
    width: "75%",
    margin: "0 auto"
  },
  buttonContent: {
    margin: theme.spacing(0, 0, 7, 0),
    padding: theme.spacing(2, 0),
    borderRadius: 4,
    width: "100%",
    border: "1px solid rgba(112,112,112,0.1)",
    boxShadow: "-3px 3px 12px rgba(0,0,0,0.1)"
  },
  content: {
    margin: theme.spacing(12, 0, 7, 0)
  },
  noContent: {
    marginTop: theme.spacing(4),
    textAlign: "center"
  },
  noContentText: {
    fontSize: 28,
    fontWeight: theme.typography.fontWeightMedium,
    color: "#9e9e9e"
  },
  subHead2: {
    fontSize: 22,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.error.main
  },
  secSubHelpTxtGreen: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.secondary.main
  },
  resetContainer: {
    display: "flex",
    alignItems: "Center"
  },
  resetText: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.error.main,
    maxWidth: 450
  },
  resetButton: { ...theme.buttons.red, marginLeft: theme.spacing(3) },
  pageFooter: {
    borderTop: "1px solid lightGray",
    paddingTop: theme.spacing(3)
  }
});

export default withStyles(styles)(NewOrder);
