import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { useAPI, RESOURCES } from "utils/api";
import Modal from "components/Modal";
import Spinner from "components/Spinner";

const styles = (theme) => ({
  red: { color: theme.palette.error.main },
  bold: { fontWeight: theme.typography.fontWeightMedium }
});

const ViewReport = ({ classes, onCancel, onSuccess }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const api = useAPI();

  return (
    <Modal
      open={true}
      title="View Report"
      actions={
        <>
          {loading && <Spinner size={20} />}

          <Button variant="text" onClick={onCancel} disabled={loading}>
            Cancel
          </Button>
        </>
      }
    >
      <Typography paragraph>This is not the report you were looking for.</Typography>
    </Modal>
  );
};

export default withStyles(styles)(ViewReport);
