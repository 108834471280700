import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { useAPI, RESOURCES } from "utils/api";
import Modal from "components/Modal";
import Spinner from "components/Spinner";

const styles = (theme) => ({
  red: { color: theme.palette.error.main },
  bold: { fontWeight: theme.typography.fontWeightMedium },
  supplierName: { marginBottom: theme.spacing(3) }
});

const DisableSupplierAcctModal = ({ classes, onClose, supplier }) => {
  const [loading, setLoading] = React.useState(false);

  const api = useAPI();

  const handleConfirm = async () => {
    setLoading(true);

    try {
      await api.callWithAuth({
        method: "DELETE",
        url: RESOURCES.currentCompany(supplier.id),
        successMessage: "Supplier account was successfully disabled."
      });

      // Why do that?
      // await api.callWithAuth({ method: "GET", url: RESOURCES.companies });
    } catch (e) {
      return;
    } finally {
      setLoading(false);
    }

    onClose();
  };

  return (
    <Modal
      open={true}
      title="Disable Supplier account"
      actions={
        <>
          {loading && <Spinner size={20} />}

          <Button variant="text" onClick={onClose} disabled={loading}>
            Cancel
          </Button>

          <Button className={classes.red} variant="text" disabled={loading} onClick={handleConfirm}>
            Disable Account
          </Button>
        </>
      }
    >
      <Typography className={classes.supplierName} variant="h4">
        {supplier.name}
      </Typography>
      <Typography className={classes.red} paragraph>
        Are you sure you want to disable this supplier account? Doing so will immediately invalidate any users' credentials
        associated with this account and they will no longer be able to log in.
      </Typography>
    </Modal>
  );
};

export default withStyles(styles)(DisableSupplierAcctModal);
