import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CancelIcon from "@material-ui/icons/Cancel";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import UndoIcon from "@material-ui/icons/Undo";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import RoundedButton from "components/Buttons/RoundedButton";
/*import { property } from "lodash"; */

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(3),
    border: `1px solid #909090`,
    padding: theme.spacing(1, 2)
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  title: {
    fontSize: 22,
    color: theme.palette.info.main,
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: "nowrap",
    flexGrow: 1
  },
  titleStrikethrough: {
    textDecoration: "line-through"
  },
  status: {
    margin: theme.spacing(0, 2),
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap"
  },
  deleteButton: theme.buttons.red,
  green: {
    color: theme.palette.secondary.main
  },
  red: {
    color: theme.palette.error.main
  },
  actions: {
    marginTop: theme.spacing(1),
    "& > :last-child": {
      marginLeft: theme.spacing(1)
    }
  },
  xsmall: {
    fontSize: 12
  },
  subheader: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
    margin: theme.spacing(1.5, 0, 0)
  },
  property: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1),

    "& > :first-child": {
      marginRight: theme.spacing(0.5)
    }
  }
});

function TestCard({ classes, coating, test, onAdd, onEdit, onDelete, onUndoDelete }) {
  const [open, setOpen] = React.useState(false);
  const [presentPropertiesHash, setPresentPropertiesHash] = React.useState({});

  function toggle() {
    setOpen(!open);
  }

  const [testIncomplete, testHasSomeValues] = React.useMemo(() => {
    let incomplete = false;

    if (test.deleted) {
      incomplete = !test.deviation;
    } else {
      incomplete = test.properties.some(
        (property) => (property.required && !property.value) || (property.deviation_required && !property.deviation)
      );
    }

    return [incomplete, test.properties.some((property) => property.value || property.required_value)];
  }, [test]);

  return (
    <Paper variant="outlined" className={classes.root}>
      <div className={classes.header}>
        <div className={classes.title}>
          <span className={test.deleted ? classes.titleStrikethrough : ""}>{test.formatted_name}</span>{" "}
          {test.required ? <span className={classes.red}>*</span> : null}
        </div>
        {testIncomplete ? (
          <div className={`${classes.status} ${classes.red}`}>
            <CancelIcon /> Incomplete
          </div>
        ) : (
          <div className={`${classes.status} ${classes.green}`}>
            <CheckCircleOutlineIcon /> Complete
          </div>
        )}
        <IconButton size="small" onClick={toggle}>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
      </div>

      <Collapse in={open}>
        <div className={classes.actions}>
          {!test.deleted &&
            (testHasSomeValues ? (
              <RoundedButton
                size="small"
                startIcon={<EditOutlinedIcon />}
                color="secondary"
                onClick={onAdd}
                className={classes.xsmall}
              >
                Edit
              </RoundedButton>
            ) : (
              <RoundedButton size="small" color="primary" onClick={onAdd} className={classes.xsmall}>
                Add Results
              </RoundedButton>
            ))}

          {!test.deleted ? (
            <RoundedButton
              size="small"
              startIcon={<DeleteOutlinedIcon />}
              className={`${classes.deleteButton} ${classes.xsmall}`}
              onClick={onDelete}
              component={Link}
              to={`/new/order/${coating.id}/tests/${test.id}/deviation`}
            >
              Delete
            </RoundedButton>
          ) : (
            <>
              <RoundedButton
                size="small"
                startIcon={<UndoIcon />}
                className={`${classes.deleteButton} ${classes.xsmall}`}
                onClick={onUndoDelete}
              >
                Undo
              </RoundedButton>

              <RoundedButton
                size="small"
                color="primary"
                component={Link}
                to={`/new/order/${coating.id}/tests/${test.id}/deviation`}
                className={classes.xsmall}
              >
                {test.deviation ? "Edit Deviation" : "Add Deviation"}
              </RoundedButton>
            </>
          )}
        </div>

        {!test.deleted && (
          <>
            <div className={classes.subheader}>Property</div>
            {test.properties.map((property) =>
              !test.deleted ? (
                <div className={classes.property} key={property.id}>
                  {!!property.value && (!property.deviation_required || property.deviation) ? (
                    <CheckCircleOutlineIcon className={classes.green} />
                  ) : (
                    <CancelIcon className={classes.red} />
                  )}
                  {`${property.name}  ${property.requirement_string}    ${property.layer ? ` (${property.layer}) ` : ""}
                    ${property.deviation_required ? " and deviation" : ""} `}
                  {property.required ? <span className={classes.red}>&nbsp;* </span> : null}
                </div>
              ) : (
                <div className={classes.property} key={property.id}>
                  {property.deviation ? (
                    <CheckCircleOutlineIcon className={classes.green} />
                  ) : (
                    <CancelIcon className={classes.red} />
                  )}
                  {`${property.name}${property.deviation_required ? " - deviation" : ""}`}{" "}
                  <RoundedButton
                    size="small"
                    color="primary"
                    component={Link}
                    to={`/new/order/${coating.id}/tests/${test.id}/deviation/${property.id}`}
                    className={classes.xsmall}
                  >
                    {property.deviation ? "Edit Deviation" : "Add Deviation"}
                  </RoundedButton>
                </div>
              )
            )}
          </>
        )}
      </Collapse>
    </Paper>
  );
}

export default withStyles(styles)(TestCard);
