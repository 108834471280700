import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { useAuth } from "context/auth";
import { useGlobalUI } from "context/globalUI";
import Modal from "components/Modal";
import Spinner from "components/Spinner";

const styles = (theme) => ({
  blue: {
    color: theme.palette.info.main
  },
  emailContainer: {
    textAlign: "center"
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  userEmail: {
    color: theme.palette.info.main,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(2)
  },
  diffEmailBtn: {
    margin: "0 auto"
  }
});

const DeleteUserModal = ({ classes, userEmail = "", onCancel, onSuccess }) => {
  const [emailInput, setEmailInput] = React.useState(userEmail);
  const [useEmail, setUseEmail] = React.useState(userEmail ? true : false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const auth = useAuth();
  const { snackbar } = useGlobalUI();

  const handleInputChange = ({ target }) => setEmailInput(target.value);

  const useDifferentEmail = () => setUseEmail(false);

  const handleConfirm = async () => {
    if (!emailInput) {
      snackbar.open({
        message: "Please supply your email.",
        color: "error",
        position: { vertical: "bottom", horizontal: "center" }
      });
      return;
    }

    setError(null);
    setLoading(true);
    try {
      const res = await auth.forgotPassword(emailInput);
    } catch (e) {
      snackbar.open({ message: e.message, color: "error", position: { vertical: "bottom", horizontal: "center" } });
      setLoading(false);
      return;
    }

    snackbar.open({ message: "Success! An email containing further instructions will arrive momentarily.", color: "success" });
    onSuccess();
  };

  return (
    <Modal
      open={true}
      title="Forgot Password"
      actions={
        <>
          {loading && <Spinner size={20} />}

          <Button variant="text" onClick={onCancel} disabled={loading}>
            Cancel
          </Button>

          <Button className={classes.blue} variant="text" disabled={loading} onClick={handleConfirm}>
            Send
          </Button>
        </>
      }
    >
      <Typography paragraph>
        {useEmail
          ? "An email containing instructions to complete resetting your password will be sent to the email address below."
          : "Provide your email address below. An email containing instructions to complete the 'Password Reset' process will be sent to the address you provide."}
      </Typography>

      {useEmail && (
        <div className={classes.emailContainer}>
          <Typography className={classes.userEmail}>{userEmail}</Typography>
          <Button className={classes.diffEmailBtn} onClick={useDifferentEmail}>
            Use a different email address?
          </Button>
        </div>
      )}

      {!useEmail && (
        <TextField
          // autoFocus
          variant="outlined"
          label="Email Address"
          className={classes.textField}
          fullWidth
          value={emailInput}
          onChange={handleInputChange}
        />
      )}

      {error && <Typography color="error">{error}</Typography>}
    </Modal>
  );
};

export default withStyles(styles)(DeleteUserModal);
