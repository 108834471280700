import React from "react";
import { Link, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import { useOrder } from "context/order";
import RoundedButton from "components/Buttons/RoundedButton";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import Shipping from "./children/Shipping";
import Payment from "./children/Payment";
import Review from "./children/Review";
import Spinner from "components/Spinner";
import { useGlobalUI } from "context/globalUI";
import BackButton from "components/Buttons/BackButton";

const styles = (theme) => ({
  backButton: theme.buttons.back,
  steps: {
    background: "rgba(4, 71, 97, 0.15)",
    padding: theme.spacing(1.5, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly"
  },
  step: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 22,
    color: "rgba(0, 0, 0, 0.33)"
  },
  stepActive: {
    color: theme.palette.primary.main,
    "& > span": {
      borderBottom: `4px solid ${theme.palette.primary.main}`
    }
  },
  icon: {
    color: theme.palette.secondary.main,
    visibility: "hidden",
    marginLeft: theme.spacing(1),
    verticalAlign: "middle"
  },
  iconVisible: {
    visibility: "visible"
  }
});

const STEPS = [
  {
    label: "Return Shipping",
    title: "Return Shipping",
    component: Shipping,
    hintSetting: "infobox_order_shipping"
  },
  {
    label: "Payment",
    title: "Payment Details",
    component: Payment,
    hintSetting: "infobox_order_billing"
  },
  { label: "Review", title: "Review Order", component: Review }
];
const STEPS_NO_COATINGS = [
  {
    label: "Payment",
    title: "Payment Details",
    component: Payment,
    hintSetting: "infobox_order_billing"
  },
  { label: "Review", title: "Review Order", component: Review }
];

function Checkout({ classes }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [stepsLoading, setStepsLoading] = React.useState(true);
  const [stepsToUse, setStepsToUse] = React.useState(null);

  const { order, loading, updatePackage, submit, setLoading, noCoatings } = useOrder();
  const history = useHistory();
  const { navDrawer } = useGlobalUI();

  React.useEffect(() => {
    if (noCoatings) {
      setStepsToUse(STEPS_NO_COATINGS);
      setStepsLoading(false);
    } else {
      setStepsToUse(STEPS);
      setStepsLoading(false);
    }
  }, []);

  async function onShippingSave(data) {
    try {
      await updatePackage(data);
    } catch (e) {
      setLoading(false);
      return;
    }
    setLoading(false);
    setActiveStep(activeStep + 1);
    window.scrollTo(0, 0);
    return;
  }

  async function onPaymentSave() {
    setActiveStep(activeStep + 1);
    window.scrollTo(0, 0);
    return;
  }

  async function onSubmit(data) {
    try {
      await submit(data);
    } catch (e) {
      setLoading(false);
      return;
    }
    setLoading(false);
  }

  if (stepsLoading) {
    return <Spinner />;
  } else {
    const CurrentStepComponent = stepsToUse[activeStep].component;

    return (
      <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
        <PageHeader
          title={stepsToUse[activeStep].title}
          breadcrumbs={activeStep === 0 ? ["Your Order", `${stepsToUse[activeStep].title}`] : []}
          hintSetting={stepsToUse[activeStep].hintSetting}
        >
          {activeStep === 0 && <BackButton url="/new/order" />}
        </PageHeader>

        <PageContent>
          <Box className={classes.steps} mb={2} borderRadius="borderRadius">
            {stepsToUse.map(({ label }, idx) => (
              <div key={idx} className={`${classes.step} ${activeStep === idx ? classes.stepActive : ""}`}>
                {`${idx + 1}. `}
                <span>{label}</span>
                <CheckCircleOutlineIcon className={`${classes.icon} ${idx < activeStep ? classes.iconVisible : ""}`} />
              </div>
            ))}
          </Box>

          {!order ? (
            <Spinner />
          ) : (
            <CurrentStepComponent
              setActiveStep={setActiveStep}
              order={order}
              loading={loading}
              onPaymentSave={onPaymentSave}
              onShippingSave={onShippingSave}
              onSubmit={onSubmit}
            />
          )}
        </PageContent>
      </div>
    );
  }
}

export default withStyles(styles)(Checkout);
