import React, { useState, useEffect, useContext, createContext } from "react";
import { useOrder } from "./order";
import { useHistory } from "react-router-dom";

const tablesContext = createContext();

export function TablesProvider({ children }) {
  const tables = useProvideTables();
  return <tablesContext.Provider value={tables}>{children}</tablesContext.Provider>;
}

export const useTables = () => {
  return useContext(tablesContext);
};

function useProvideTables() {
  const { order } = useOrder();
  const history = useHistory();

  const [itemType, setItemType] = React.useState(null);
  const [id, setId] = React.useState(null);
  const [itemId, setItemId] = React.useState(null);
  const [tableType, setTableType] = React.useState(null);
  const [orderItemId, setOrderItemId] = React.useState(null);
  const [statusExplain, setStatusExplain] = React.useState(null);
  const [modalCallback, setModalCallback] = React.useState(() => {});
  const [showViewReportModal, setShowViewReportModal] = React.useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = React.useState(false);
  const [showSecondOrderModal, setShowSecondOrderModal] = React.useState(false);
  const [showNewOrderModal, setShowNewOrderModal] = React.useState(false);
  const [showRequestAppealModal, setShowRequestAppealModal] = React.useState(false);
  const [showNewOrderAcceptedModal, setShowNewOrderAcceptedModal] = React.useState(false);
  const [showStatusExplainModal, setShowStatusExplainModal] = React.useState(false);
  const [showApproveRequestModal, setShowApproveRequestModal] = useState(false);
  const [showDenyRequestModal, setShowDenyRequestModal] = useState(false);

  const orderInDev = order && order.coatings.concat(order.procedures).length >= 1;

  const modalFuncMap = {
    setShowViewReportModal,
    setShowDeleteItemModal,
    setShowSecondOrderModal,
    setShowNewOrderModal,
    setShowRequestAppealModal,
    setShowNewOrderAcceptedModal,
    setShowStatusExplainModal,
    setShowApproveRequestModal,
    setShowDenyRequestModal
  };

  const modalStates = {
    showViewReportModal,
    showDeleteItemModal,
    showSecondOrderModal,
    showNewOrderModal,
    showRequestAppealModal,
    showNewOrderAcceptedModal,
    showStatusExplainModal,
    showApproveRequestModal,
    showDenyRequestModal
  };

  const modalNames = {
    VIEW_REPORT: "ViewReportModal",
    DELETE_ITEM: "DeleteItemModal",
    SECOND_ORDER: "SecondOrderModal",
    NEW_ORDER: "NewOrderModal",
    NEW_ORDER_ACCEPTED: "NewOrderAcceptedModal",
    REQUEST_APPEAL: "RequestAppealModal",
    STATUS_EXPLAIN: "StatusExplainModal",
    APPROVE_REQUEST: "ApproveRequestModal",
    DENY_REQUEST: "DenyRequestModal"
  };

  function openModal({ itemType, itemId, modalName, orderItemId, statusExplain, callback, tableType, id }) {
    setItemType(itemType);
    setItemId(itemId);
    setId(id);
    setOrderItemId(orderItemId);
    setStatusExplain(statusExplain);
    setModalCallback(() => callback);
    setTableType(tableType);
    modalFuncMap[`setShow${modalName}`](true);
  }
  function closeModal(modalName) {
    modalFuncMap[`setShow${modalName}`](false);
    setItemType(null);
    setItemId(null);
    setOrderItemId(null);
    setStatusExplain(null);
    setModalCallback(null);
    setTableType(null);
  }

  function redirectAction(url) {
    return history.push(url);
  }

  const tables = {
    PENDING_ACC_REQUESTS: "PENDING_ACC_REQUESTS",
    PRIORITY_REQUESTS: "PRIORITY_REQUESTS",
    ITEMS_IN_DEV: "ITEMS_IN_DEV",
    ITEMS_SUBMITTED: "ITEMS_SUBMITTED",
    ITEMS_HISTORY: "ITEMS_HISTORY",
    ACCEPTED_COATINGS: "ACCEPTED_COATINGS",
    ACCEPTED_PROCEDURES: "ACCEPTED_PROCEDURES"
  };

  const tableNames = {
    ITEMS_IN_DEV: "items_in_dev",
    ITEMS_SUBMITTED: "items_submitted",
    ITEMS_HISTORY: "items_history",
    ACCEPTED_COATINGS: "coatings_accepted",
    ACCEPTED_PROCEDURES: "procedures_accepted",
    PENDING_ACC_REQUESTS: "pendingRequests"
  };

  const tableTitles = {
    PENDING_ACC_REQUESTS: "Pending Account Requests",
    PRIORITY_REQUESTS: "Priority Scheduling Requests",
    ITEMS_IN_DEV: "Coatings / Procedures Not Submitted",
    ITEMS_SUBMITTED: "Coatings / Procedure Submitted",
    ITEMS_HISTORY: "Coatings / Procedure Submittal History",
    ACCEPTED_COATINGS: "Metcut CCL Accepted Coatings",
    ACCEPTED_PROCEDURES: "Metcut CCL Accepted Procedures"
  };

  const tableHeaders = {
    [tables.PENDING_ACC_REQUESTS]: [
      { name: "Supplier", key: "company_name" },
      { name: "GE Vendor Code", key: "ge_vendor_code" },
      { name: "Contact Name", key: ["first_name", "last_name"] },
      { name: "Contact Phone", key: "phone" },
      { name: "Contact Email", key: "email" },
      { name: "Date Requested", key: "created" }
    ],
    [tables.PRIORITY_REQUESTS]: [
      { name: "Supplier", key: "company_name" },
      { name: "User Requested", key: ["user_first_name", "user_last_name"] },
      { name: "User Email", key: "user_email" },
      { name: "Coating / Procedure", key: ["coating_string", "procedure_title"] },
      { name: "Type", key: "coating_full" },
      { name: "Priority Fee", key: "stripe_total_charged" },
      { name: "Date Ordered", key: "order_date" },
      { name: "Date Requested", key: "priority_request_date" }
    ],
    [tables.ACCEPTED_COATINGS]: [
      { name: "Coating", key: "coating" },
      { name: "Type", key: "coating_full" },
      { name: "Last Updated", key: "status_date" },
      { name: "Expiration Date", key: "coating_expiration_date" }
    ],
    [tables.ACCEPTED_PRODURES]: [
      { name: "Procedure Title", key: "procedure_title" },
      { name: "Number", key: "procedure_number" },
      { name: "Revision", key: "procedure_revision" },
      { name: "Last Updated", key: "status_date" }
    ],
    [tables.ITEMS_SUBMITTED]: [
      { name: "Order ID", key: "order_id" },
      { name: "Date Ordered", key: "date_ordered" },
      { name: "Coating / Procedure", key: ["coating", "procedure_title"] },
      { name: "Submission", key: "submission_count" },
      { name: "Type", key: "coating_full" },
      {
        name: "Priority",
        key: "priority_status",
        icon: "INFO_ICON",
        tooltipText:
          "Column may contain values 'Requested', 'Approved', or 'Rejected' if someone has submitted the item for priority scheduling."
      },
      {
        name: "Status",
        key: "status_title",
        icon: "INFO_ICON",
        tooltipText:
          "Your items have been submitted for testing. Once testing has begun, check back here to view it's status as it makes it's way from arrival to final outcome."
      }
    ],
    [tables.ITEMS_IN_DEV]: [
      { name: "Date Saved", key: "date_saved" },
      { name: "Coating / Procedure", key: ["coating", "procedure_title"] },
      // { name: "Submission", key: "submission_count" },
      { name: "Type", key: "coating_full" },
      { name: "User", key: "user_full_name" }
    ],
    [tables.ITEMS_HISTORY]: [
      { name: "Order ID", key: "order_id" },
      { name: "Date Ordered", key: "date_ordered" },
      { name: "Submission", key: "submission_count" },
      { name: "Type", key: ["coating_full", "procedure_type"] },
      { name: "Status", key: "status_title" },
      { name: "Last Updated", key: "status_date" }
    ]
  };

  return {
    modals: { open: openModal, close: closeModal, canView: { ...modalStates }, names: { ...modalNames } },
    item: { itemType, itemId, orderItemId, statusExplain, orderInDev, modalCallback, tableType, id },
    redirectAction,
    tableNames,
    tableTitles,
    tableHeaders,
    tables
  };
}
