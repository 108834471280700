import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import pick from "lodash/pick";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import RoundedButton from "components/Buttons/RoundedButton";
import BackButton from "components/Buttons/BackButton";
import { useUserCompany } from "context/userCompany";
import { useGlobalUI } from "context/globalUI";
import { useAPI, RESOURCES } from "utils/api";
import Spinner from "components/Spinner";
import TextField from "components/TextField";
import PageHeader from "components/PageHeader";

const styles = (theme) => ({
  backButton: theme.buttons.back,
  value: {
    fontWeight: theme.typography.fontWeightLight
  },
  right: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    border: "1px solid #8c8c8c",
    borderRadius: 4,
    color: "#8c8c8c",
    fontSize: 18
  }
});

function EditPreferredCarrier({ classes }) {
  const { register, errors, handleSubmit, watch, reset } = useForm({ mode: "onBlur" });
  const data = watch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { companyInfo, updateData } = useUserCompany();
  const { callWithAuth } = useAPI();
  const { snackbar, navDrawer } = useGlobalUI();
  const history = useHistory();

  useEffect(() => {
    reset(pick(companyInfo, ["preferred_carrier_name", "preferred_carrier_account"]));
  }, []);

  async function handleSave() {
    setLoading(true);
    try {
      await callWithAuth({
        method: "PUT",
        url: RESOURCES.preferredCarrier,
        axiosOptions: { data: { carrier_name: data.preferred_carrier_name, account_number: data.preferred_carrier_account } },
        successMessage: "Preferred Carrier information was successfully updated!"
      });

      await updateData();
    } catch (e) {
      return;
    } finally {
      setLoading(false);
    }

    history.goBack();
  }

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader
        title="Edit Preferred Carrier"
        breadcrumbs={["Account", "Edit Preferred Carrier"]}
        hint="Edit your company's preferred carrier details below. This information will be used by Metcut to determine your shipment costs."
      >
        <BackButton url="/account" />
      </PageHeader>

      <Typography variant="h5">Preferred Carrier Details</Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="preferred_carrier_name"
                inputRef={register}
                error={!!errors.preferred_carrier_name}
                label="Preferred Carrier"
                margin="normal"
                variant="outlined"
                color="secondary"
                empty={!data.preferred_carrier_name}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="preferred_carrier_account"
                inputRef={register}
                error={!!errors.preferred_carrier_account}
                label="Account Number"
                margin="normal"
                variant="outlined"
                color="secondary"
                empty={!data.preferred_carrier_account}
                fullWidth
              />
            </Grid>

            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}

            <Grid item xs={12} align="center">
              {loading ? (
                <Spinner />
              ) : (
                <RoundedButton color="primary" onClick={handleSubmit(handleSave)} size="large">
                  SAVE
                </RoundedButton>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.right}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                Company
              </Grid>
              <Grid item xs={7} className={classes.value}>
                {companyInfo.name}
              </Grid>
              <Grid item xs={5}>
                GE Vendor Code
              </Grid>
              <Grid item xs={7} className={classes.value}>
                {companyInfo.ge_vendor_code}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(EditPreferredCarrier);
