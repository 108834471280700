import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";

import MetcutTable from "components/Tables/MetcutTable";
import { useAPI, RESOURCES } from "utils/api";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import BackButton from "components/Buttons/BackButton";
import Spinner from "components/Spinner";
import SupplierInteractions from "./children/SupplierInteractions";
import { useGlobalUI } from "context/globalUI";
import { useTables } from "context/tables";

const styles = (theme) => ({
  supplierName: {
    marginBottom: theme.spacing(2),
    color: theme.palette.info.main
  },
  pageSubHead: {
    marginBottom: theme.spacing(3)
  },
  blue: {
    color: theme.palette.info.main
  },
  table: {
    margin: theme.spacing(2, 0, 7, 0)
  },
  tableGroup: {
    marginBottom: theme.spacing(6)
  },
  tableTitle: {
    marginBottom: theme.spacing(2)
  },
  noData: {
    fontSize: 20,
    margin: theme.spacing(2, 0, 0, 4),
    display: "flex",
    alignItems: "center"
  },
  noDataThumbIcon: {
    color: theme.palette.info.main,
    marginLeft: theme.spacing(1)
  },
  statusExplainHeader: {
    fontSize: 18,
    marginBottom: theme.spacing(3)
  },
  statusExplain: {
    fontSize: 16
  }
});

function ViewSupplier({ classes }) {
  const api = useAPI();
  const params = useParams();
  const { navDrawer } = useGlobalUI();
  const { tables: allTables } = useTables();

  const [supplierID, setSupplierID] = useState(params.id ? +params.id : null);
  const [loading, setLoading] = useState(true);
  const [tablesLoading, setTablesLoading] = useState(true);
  const [supplier, setSupplier] = useState(null);
  const [tables, setTables] = useState(null);

  useEffect(() => {
    if (supplierID) {
      fetchSupplier(supplierID);
      fetchTables(supplierID);
    }
  }, []);

  async function fetchSupplier(supplierID) {
    setLoading(true);
    const result = await api.callWithAuth({ method: "GET", url: RESOURCES.currentCompany(supplierID) });
    setSupplier(result);
    setLoading(false);
  }

  async function fetchTables(supplierID) {
    setTablesLoading(true);
    const result = await api.callWithAuth({ method: "GET", url: `/tables/companies/${supplierID}` });
    setTables(result);
    setTablesLoading(false);
  }

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader
        title="View Supplier"
        hint="View and interact with all current and past orders associated with this supplier. Check for priority scheduling requests and use the context menu on each order to approve or deny the request."
        breadcrumbs={["Suppliers", "View Supplier"]}
      >
        <BackButton url={"/suppliers"} />
      </PageHeader>
      <PageContent>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Typography className={classes.supplierName} variant="h3">
              {supplier.name}
            </Typography>
            <SupplierInteractions supplier={supplier} />

            <div className={`${classes.tableGroup} ${classes.table2}`}>
              <Typography className={classes.tableTitle} variant="h6">
                Coatings/Procedures Submitted
              </Typography>
              {tablesLoading ? (
                <Spinner />
              ) : tables.items_submitted.length > 0 ? (
                <MetcutTable
                  supplierID={supplierID}
                  tableItems={tables.items_submitted}
                  table={allTables.ITEMS_SUBMITTED}
                  usePagination={false}
                  showFooter={true}
                />
              ) : (
                <Typography className={classes.noData}>
                  <ChevronRight className={classes.chevronNoRequests} />
                  No data to display right now <ThumbUpOutlinedIcon className={classes.noDataThumbIcon} />
                </Typography>
              )}
            </div>

            <div className={`${classes.tableGroup} ${classes.table3}`}>
              <Typography className={classes.tableTitle} variant="h6">
                Coating / Procedure Submittal History
              </Typography>
              {tablesLoading ? (
                <Spinner />
              ) : tables.items_history.length > 0 ? (
                <MetcutTable
                  tableItems={tables.items_history}
                  usePagination={false}
                  table={allTables.ITEMS_HISTORY}
                  showFooter={true}
                />
              ) : (
                <Typography className={classes.noData}>
                  <ChevronRight className={classes.chevronNoRequests} />
                  No data to display right now <ThumbUpOutlinedIcon className={classes.noDataThumbIcon} />
                </Typography>
              )}
            </div>

            <div className={`${classes.tableGroup} ${classes.table4}`}>
              <Typography className={classes.tableTitle} variant="h6">
                MetcutCCL Accepted Coatings
              </Typography>
              {tablesLoading ? (
                <Spinner />
              ) : tables.coatings_accepted.length > 0 ? (
                <MetcutTable
                  tableItems={tables.coatings_accepted}
                  table={allTables.ACCEPTED_COATINGS}
                  usePagination={false}
                  showFooter={true}
                />
              ) : (
                <Typography className={classes.noData}>
                  <ChevronRight className={classes.chevronNoRequests} />
                  No data to display right now <ThumbUpOutlinedIcon className={classes.noDataThumbIcon} />
                </Typography>
              )}
            </div>

            <div className={`${classes.tableGroup} ${classes.table5}`}>
              <Typography className={classes.tableTitle} variant="h6">
                MetcutCCL Accepted Procedures
              </Typography>
              {tablesLoading ? (
                <Spinner />
              ) : tables.procedures_accepted.length > 0 ? (
                <MetcutTable
                  tableItems={tables.procedures_accepted}
                  table={allTables.ACCEPTED_PROCEDURES}
                  usePagination={false}
                  showFooter={true}
                />
              ) : (
                <Typography className={classes.noData}>
                  <ChevronRight className={classes.chevronNoRequests} />
                  No data to display right now <ThumbUpOutlinedIcon className={classes.noDataThumbIcon} />
                </Typography>
              )}
            </div>
          </>
        )}
      </PageContent>
    </div>
  );
}

export default withStyles(styles)(ViewSupplier);
