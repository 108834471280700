import React from "react";
import { Link, useHistory } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  backButton: { ...theme.buttons.back, marginTop: theme.spacing(1) }
});

function BackButton({ classes, url, goBack }) {
  const history = useHistory();

  if (goBack) {
    return (
      <>
        <Button onClick={() => history.goBack()} startIcon={<ChevronLeftIcon />} className={classes.backButton} color="primary">
          Back
        </Button>
      </>
    );
  }

  return (
    <>
      <Button component={Link} to={url} startIcon={<ChevronLeftIcon />} className={classes.backButton} color="primary">
        Back
      </Button>
    </>
  );
}

export default withStyles(styles)(BackButton);
