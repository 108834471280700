import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import ChangePasswordModal from "./children/ChangePasswordModal";
import RoundedButton from "components/Buttons/RoundedButton";
import { useUserCompany } from "context/userCompany";
import { useAuth } from "context/auth";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import CompanyInfoGrid from "./children/CompanyInfoGrid";
import BillingAddressGrid from "./children/BillingAddressGrid";
import ShippingAddressGrid from "./children/ShippingAddressGrid";
import PrefferedShippingGrid from "./children/PrefferedShippingGrid";
import PersonalInfoGrid from "./children/PersonalInfoGrid";
import { useGlobalUI } from "context/globalUI";

const styles = (theme) => ({
  pageHeader: {
    border: "1px solid lightGray",
    borderRadius: 4,
    fontSize: 20,
    padding: theme.spacing(3, 5)
  },
  header: {
    marginBottom: theme.spacing(2)
  },
  subheader: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),

    "& > :first-child": {
      marginRight: theme.spacing(4)
    }
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium
  },
  yourAccount: {
    marginBottom: theme.spacing(6)
  },
  userName: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  label: {
    // fontWeight: theme.typography.fontWeightMedium
  },
  value: {
    fontWeight: theme.typography.fontWeightLight
  },
  bottomRow: {
    marginTop: theme.spacing(6)
  }
});

function Account({ classes }) {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const { user, companyInfo } = useUserCompany();
  const auth = useAuth();
  const { navDrawer } = useGlobalUI();

  const labelWidthXs = 5;
  const valueWidthXs = 7;

  function toggleChangePasswordModal() {
    setShowChangePasswordModal(!showChangePasswordModal);
  }

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader title="Account" hint="View and edit your personal and company account information and password.">
        <div className={classes.pageHeader}>
          <Typography>
            <span className={classes.bold}>Account for: </span>
            <Typography className={classes.userName} display="inline" component="span">
              {user.first_name} {user.last_name}
            </Typography>
          </Typography>
          {user.role === "CompanyAdmin" ? (
            <Typography>You are the administrator for this company account</Typography>
          ) : (
            <>
              {!!companyInfo.admin_full_name.length > 2 ? (
                <Typography>
                  <span className={classes.bold}>Your account administrator is: </span> {companyInfo.admin_full_name}
                </Typography>
              ) : (
                ""
              )}
              <Typography>
                <span className={classes.bold}>System user type: </span> {user.formatted_role_name}
              </Typography>
            </>
          )}
          <Typography>
            <span className={classes.bold}>Company Account: </span> {companyInfo.name}
          </Typography>
          <RoundedButton style={{ marginTop: 20 }} color="primary" size="small" onClick={() => auth.logout()}>
            Log Out
          </RoundedButton>
        </div>
      </PageHeader>
      <PageContent>
        <PersonalInfoGrid
          classes={classes}
          user={user}
          labelWidthXs={labelWidthXs}
          valueWidthXs={valueWidthXs}
          toggleChangePasswordModal={toggleChangePasswordModal}
        />
        {(user.role === "CompanyAdmin" || user.role === "SuperAdmin" || user.role === "SystemAdmin") && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.header}>
                Company Account
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CompanyInfoGrid
                labelWidthXs={labelWidthXs}
                valueWidthXs={valueWidthXs}
                classes={classes}
                companyInfo={companyInfo}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BillingAddressGrid
                labelWidthXs={labelWidthXs}
                valueWidthXs={valueWidthXs}
                classes={classes}
                billingAddress={companyInfo.addresses.billing}
              />
            </Grid>
            <Grid className={classes.bottomRow} item xs={12} sm={6}>
              <ShippingAddressGrid
                labelWidthXs={labelWidthXs}
                valueWidthXs={valueWidthXs}
                classes={classes}
                shippingAddress={companyInfo.addresses.shipping}
              />
            </Grid>
            <Grid className={classes.bottomRow} item xs={12} sm={6}>
              <PrefferedShippingGrid
                labelWidthXs={labelWidthXs}
                valueWidthXs={valueWidthXs}
                classes={classes}
                companyInfo={companyInfo}
              />
            </Grid>
          </Grid>
        )}
      </PageContent>

      {showChangePasswordModal && (
        <ChangePasswordModal onCancel={toggleChangePasswordModal} onSuccess={toggleChangePasswordModal} />
      )}
    </div>
  );
}

export default withStyles(styles)(Account);
