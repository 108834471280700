import React from "react";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import TextField from "components/TextField";
import RoundedButton from "components/Buttons/RoundedButton";

const VALIDATION_RULES = {
  address1: { required: true },
  address2: { required: false },
  country: { required: true },
  city: { required: true },
  region: { required: false },
  postal_code: {
    required: false,
    pattern: {
      value: /^[0-9]+$/,
      message: "Only Numbers, please!"
    }
  }
};

const BillingAddressForm = ({ address, onConfirm }) => {
  const { register, errors, handleSubmit, watch } = useForm({ mode: "onBlur", defaultValues: address });
  const data = watch();

  function handleConfirm(data) {
    onConfirm(data);
  }

  return (
    <Box mb={9}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="company_name"
            inputRef={register(VALIDATION_RULES.company_name)}
            error={!!errors.company_name}
            label="Company Name"
            variant="outlined"
            color="secondary"
            empty={!data.company_name}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="address1"
            inputRef={register(VALIDATION_RULES.address1)}
            error={!!errors.address1}
            label="Address 1"
            variant="outlined"
            color="secondary"
            empty={!data.address1}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="address2"
            inputRef={register(VALIDATION_RULES.address2)}
            error={!!errors.address2}
            label="Address 2"
            variant="outlined"
            color="secondary"
            empty={!data.address2}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="city"
            inputRef={register(VALIDATION_RULES.city)}
            error={!!errors.city}
            label="City"
            variant="outlined"
            color="secondary"
            empty={!data.city}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="region"
            inputRef={register(VALIDATION_RULES.region)}
            error={!!errors.region}
            label="State / Province"
            variant="outlined"
            color="secondary"
            empty={!data.region}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="postal_code"
            inputRef={register(VALIDATION_RULES.postal_code)}
            error={!!errors.postal_code}
            helperText={errors.postal_code && errors.postal_code.message}
            label="Postal Code"
            variant="outlined"
            color="secondary"
            empty={!data.postal_code}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="country"
            inputRef={register(VALIDATION_RULES.country)}
            error={!!errors.country}
            label="Country"
            variant="outlined"
            color="secondary"
            empty={!data.country}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} align="center">
          <RoundedButton color="primary" onClick={handleSubmit(handleConfirm)}>
            Confirm Address
          </RoundedButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillingAddressForm;
