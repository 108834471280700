const ROUTES = {
  metcutHome: "/metcut-admin",
  viewFullRequest: "/metcut-admin/request/:id",
  supplierHome: "/home",
  index: "/",
  signUp: "/sign-up",
  login: "/login",
  create: "/new",
  yourOrder: "/new/order",
  newCoating: "/new/coating",
  editCoating: "/edit/coatings/:id",
  newProcedure: "/new/procedure",
  editProcedure: "/edit/procedures/:id",
  testResults: "/new/order/:coatingId/tests/:id",
  testResultsDeviation: "/new/order/:coatingId/tests/:testId/deviation/:id?",
  reviewOrder: "/new/order/review",
  checkout: "/new/order/checkout",
  orderConfirmation: "/new/order/confirmation",
  myAccount: "/account",
  editYourDetails: "/account/edit",
  editCompanyDetails: "/account/company/edit",
  editBillingAddress: "/account/billingAddress/edit",
  editShippingAddress: "/account/shippingAddress/edit",
  editPreferredCarrier: "/account/preferredCarrier/edit",
  orders: "/suppliers",
  viewSupplier: "/suppliers/:id",
  users: "/manage/users",
  createUser: "/manage/users/add",
  createAdmin: "/manage/admins/add",
  editAdmin: "/manage/admins/edit/:id",
  editUser: "/manage/users/edit/:id",
  suppliers: "/admin",
  manageSupplierAccount: "/admin/suppliers/:company_id",
  editSupplierDetails: "/admin/suppliers/:company_id/edit",
  editSupplierUser: "/admin/suppliers/:company_id/users/:user_id/edit",
  support: "/support",
  faq: "/support/faq",
  supplierAllData: "/home/all/:type",
  allData: "/home/all/:type",
  adminAllData: "/suppliers/:id/:type",
  priorityFeePayment: "/misc/payment",
  viewOrderReceipt: "/orders/:id",
  // Not found or Forbidden
  errorForbidden: "/error/forbidden",
  error: "/error"
};

export default ROUTES;
