import React from "react";
import moment from "moment";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import RoundedButton from "components/Buttons/RoundedButton";
import Spinner from "components/Spinner";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import CoatingTextField from "./children/CoatingTextField";
import CoatingSpecList from "./children/CoatingSpecList";
import { useAPI, RESOURCES } from "utils/api";
import { parseQueryString } from "utils/qs";
import { useOrder } from "context/order";
import { useGlobalUI } from "context/globalUI";
import BackButton from "components/Buttons/BackButton";
import cartTypes from "utils/cartTypes";

const { REACT_APP_CCL_ADMIN_EMAIL } = process.env;

const VALIDATION_RULES = {
  title: { required: true },
  reference: { required: true },
  revision: { required: true }
};

const styles = (theme) => ({
  backButton: theme.buttons.back,
  grid: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      width: "100%",
      marginRight: theme.spacing(1)
    }
  },
  blackLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1)
  },
  blueLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.info.main,
    marginBottom: theme.spacing(0.5)
  },
  typeDisclaimer: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(2)
  },
  secSubmitDirections: {
    fontSize: 22,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.error.main
  },
  coverContainer: {
    position: "relative"
  },
  cover: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: "#fff",
    opacity: "0.75"
  },
  control: {
    width: 250
  },
  buttons: {
    "& > :first-child": {
      marginRight: theme.spacing(2)
    }
  },
  buttonContainer: {
    marginBottom: theme.spacing(2),
    "& > *": {
      marginBottom: theme.spacing(2),
      display: "block"
    }
  }
});

function formatSpecItem(item) {
  let value = item ? item.displayID : "";
  let selectedClasses = [];

  if (item) {
    item.classes.filter((cls) => !cls.default && cls.selected).forEach((cls) => (value = `${value} -CL${cls.name}`));
  }

  return value;
}

function parseClassString(str) {
  // Parses string like "-CLA" to "A"
  return str.trim().replace("-CL", "");
}

const COATING_STRING_DELIMITER = " / ";

function EditCoating({ classes }) {
  const params = useParams();
  const id = params.id ? +params.id : null;

  const [loading, setLoading] = React.useState(true);
  const [coating, setCoating] = React.useState(null);
  const [newOrder, setNewOrder] = React.useState(false);
  const [newOrderAccepted, setNewOrderAccepted] = React.useState(false);
  const [labsLoading, setLabsLoading] = React.useState(true);
  const [labDropDowns, setLabDropDowns] = React.useState([]);
  const [selectedLab, setSelectedLab] = React.useState(-1);

  const [coatingSpecs, setCoatingSpecs] = React.useState([]);
  const [powderSpecsIn, setPowderSpecsIn] = React.useState([]);
  const [powderSpecsOut, setPowderSpecsOut] = React.useState([]);
  const [powderSpecsOther, setPowderSpecsOther] = React.useState([]);

  const [selectedCoatingSpec, setSelectedCoatingSpec] = React.useState(null);
  const [selectedPowderSpecs, setSelectedPowderSpecs] = React.useState({});
  const [selectedClasses, setSelectedClasses] = React.useState({});

  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedFull, setSelectedFull] = React.useState(1);
  const [addToOrder, setAddToOrder] = React.useState(false);

  const selectedSpecsArray = React.useMemo(() => {
    const array = [null, null, null, null, null, null, null];

    if (selectedCoatingSpec) {
      array[0] = {
        ...selectedCoatingSpec,
        displayID: selectedCoatingSpec._UniqueCoatingID,
        classes: selectedCoatingSpec._Classes
          ? selectedCoatingSpec._Classes.split(",").map((clsName) => ({
              name: clsName,
              default: selectedCoatingSpec.CoatingSpecClass === clsName,
              selected:
                selectedClasses[selectedCoatingSpec._UniqueCoatingID] &&
                selectedClasses[selectedCoatingSpec._UniqueCoatingID][clsName]
            }))
          : []
      };
    }

    let idx = 1;
    for (const key in selectedPowderSpecs) {
      const spec = selectedPowderSpecs[key];
      array[idx++] = {
        ...spec,
        displayID: spec._UniquePowderID,
        classes: spec._Classes
          ? spec._Classes.split(",").map((clsName) => ({
              name: clsName,
              default: spec.PowderSpecClass === clsName,
              selected: selectedClasses[spec._UniquePowderID] && selectedClasses[spec._UniquePowderID][clsName]
            }))
          : []
      };
    }

    return array;
  }, [selectedCoatingSpec, selectedPowderSpecs, selectedClasses]);

  const order = useOrder();
  const { callWithAuth } = useAPI();
  const history = useHistory();
  const location = useLocation();
  const { snackbar, navDrawer } = useGlobalUI();

  React.useEffect(() => {
    async function loadCoatingSpecs(id) {
      const coatingSpecs = await callWithAuth({ method: "GET", url: RESOURCES.coatingSpecs });
      setCoatingSpecs(coatingSpecs);

      if (id) {
        // Fetch coating
        const coating = await callWithAuth({ method: "GET", url: RESOURCES.singleCoating(id) });
        setCoating(coating);

        setSelectedFull(coating.full ? 1 : 0);
        setSelectedDate(moment(coating.expiration_date));

        const [selectedCoatingSpecUniqueIDWithClasses, ...selectedPowderSpecsUniqueIDsWithClasses] = coating.coating_string.split(
          COATING_STRING_DELIMITER
        );
        const [selectedCoatingSpecUniqueID, ...selectedCoatingSpecClasses] = selectedCoatingSpecUniqueIDWithClasses.split(" ");
        const selectedClasses = {};

        const selectedCoatingSpec = coatingSpecs.find(({ _UniqueCoatingID }) => _UniqueCoatingID === selectedCoatingSpecUniqueID);
        if (!selectedCoatingSpec) {
          throw new Error(`Coating spec ${selectedCoatingSpecUniqueID} was not found in the database`);
        }

        setSelectedCoatingSpec(selectedCoatingSpec);

        selectedClasses[selectedCoatingSpecUniqueID] = {};
        selectedCoatingSpecClasses.forEach((classString) => {
          selectedClasses[selectedCoatingSpecUniqueID][parseClassString(classString)] = 1;
        });

        // Fetch powder specs for the coating
        const powderSpecs = await loadPowderSpecs(selectedCoatingSpecUniqueID);

        const selectedPowderSpecs = selectedPowderSpecsUniqueIDsWithClasses.reduce(
          (acc, selectedPowderSpecsUniqueIDWithClass) => {
            const [selectedPowderSpecsUniqueID, ...selectedPowderSpecClasses] = selectedPowderSpecsUniqueIDWithClass.split(" ");
            acc[selectedPowderSpecsUniqueID] = [...powderSpecs.in, ...powderSpecs.out, ...powderSpecs.other].find(
              ({ _UniquePowderID }) => _UniquePowderID === selectedPowderSpecsUniqueID
            );

            selectedClasses[selectedPowderSpecsUniqueID] = {};
            selectedPowderSpecClasses.forEach((classString) => {
              selectedClasses[selectedPowderSpecsUniqueID][parseClassString(classString)] = 1;
            });

            return acc;
          },
          {}
        );

        setSelectedPowderSpecs(selectedPowderSpecs);

        setSelectedClasses(selectedClasses);
      }

      setLoading(false);
    }

    fetchLabDropDowns();

    const qs = parseQueryString(location.search);
    if (qs.add) {
      setAddToOrder(true);
    }
    order.setCartType(qs);

    loadCoatingSpecs(id);
  }, []);

  const fetchLabDropDowns = async () => {
    const response = await callWithAuth({ method: "GET", url: RESOURCES.settings("lab_dropdown") });
    const labDropDowns = response.value.split("|");
    setLabDropDowns(labDropDowns);
    setLabsLoading(false);
  };

  React.useEffect(() => {
    if (!coating || !labDropDowns) {
      return;
    }

    setSelectedLab(labDropDowns.findIndex((lab) => lab === coating.lab));
  }, [coating, labDropDowns]);

  async function handleCoatingSelect(item) {
    if (selectedCoatingSpec && item._UniqueCoatingID === selectedCoatingSpec._UniqueCoatingID) {
      return;
    }

    setSelectedCoatingSpec(item);
    await loadPowderSpecs(item._UniqueCoatingID);
  }

  async function loadPowderSpecs(uniqueCoatingID, classes) {
    setSelectedPowderSpecs({});
    setPowderSpecsIn([]);
    setPowderSpecsOut([]);
    setPowderSpecsOther([]);

    setLoading(true);
    const params = {};

    if (classes) {
      params.classes = classes.join(",");
    }

    const powderSpecs = await callWithAuth({
      method: "GET",
      url: RESOURCES.powderSpecs(uniqueCoatingID),
      axiosOptions: { params }
    });

    setLoading(false);

    setPowderSpecsIn(powderSpecs.in);
    setPowderSpecsOut(powderSpecs.out);
    setPowderSpecsOther(powderSpecs.other);

    return powderSpecs;
  }

  function handlePowderSelect(item) {
    const newSelectedPowderSpecs = { ...selectedPowderSpecs };
    if (newSelectedPowderSpecs[item._UniquePowderID]) {
      delete newSelectedPowderSpecs[item._UniquePowderID];
    } else {
      if (Object.keys(newSelectedPowderSpecs).length === 6) {
        // Do not allow to select more than 6 powder specs
        snackbar.open({ message: "Only 6 powder specs are allowed", color: "error" });
      } else {
        newSelectedPowderSpecs[item._UniquePowderID] = item;
      }
    }
    setSelectedPowderSpecs(newSelectedPowderSpecs);
  }

  function handleClassChange(item) {
    return function ({ target }) {
      const newSelectedClasses = { ...selectedClasses };
      newSelectedClasses[item.displayID] = {};

      target.value.forEach((clsName) => {
        newSelectedClasses[item.displayID][clsName] = 1;
      });

      setSelectedClasses(newSelectedClasses);

      if (item.CoatingSpec) {
        // A class has been added/removed to/from a coating: updated Powders Lists
        /* await */ loadPowderSpecs(item._UniqueCoatingID, Object.keys(newSelectedClasses[item.displayID]));
      }
    };
  }

  function handleSave(add = false) {
    return async function () {
      setLoading(true);
      const data = {
        coating_string: selectedSpecsArray
          .filter((spec) => !!spec)
          .map(formatSpecItem)
          .join(COATING_STRING_DELIMITER),
        expiration_date: selectedDate.format(moment.HTML5_FMT.DATE),
        lab: labDropDowns[selectedLab],
        full: !!selectedFull
      };

      let coating;

      try {
        if (!id) {
          coating = await callWithAuth({
            method: "POST",
            url: RESOURCES.coatings,
            axiosOptions: { data },
            successMessage: "Coating was successfully added to the database!"
          });
        } else {
          coating = await callWithAuth({
            method: "PUT",
            url: RESOURCES.singleCoating(id),
            axiosOptions: { data },
            successMessage: "Coating was successfully saved!"
          });
        }
      } catch (e) {
        setLoading(false);
        return;
      }

      if (add && addToOrder) {
        const payload = id ? { coating: { id } } : { coating };
        order.addToOrder(payload);

        const urlToPush = pageContentMap("urlToPush");
        history.push(urlToPush);
        return;
      }

      // Just save the coating and redirect to the Home page
      history.push("/");
    };
  }

  function handleCancel() {
    history.goBack();
  }

  function pageContentMap(textType) {
    return {
      [cartTypes.second_submission]: {
        pageTitle: "Second Submission",
        pageHeader: "Make Changes For Second Submission",
        subHead1:
          "For resubmission of an accepted procedure: the only edits that can be made are the 'revision' and 'type' (Major or Minor).",
        subHead2: "",
        saveButton: "SAVE BUTTON",
        backUrl: addToOrder ? `/new/order?${cartTypes.new_order_accepted}=1` : "/home",
        urlToPush: `/new/order?${cartTypes.second_submission}=1`
      },
      [cartTypes.new_order]: {
        pageTitle: "New Order",
        pageHeader: "Make Changes For New Order",
        subHead1:
          "Attention: When creating a new order with this coating, the Type must be 'Full'. That value is preselected by default for you. Review the information on this page and click 'Add To Order' to continue the new order process.",
        subHead2: "",
        backUrl: addToOrder ? `/new/order?${cartTypes.new_order_accepted}=1` : "/home",
        urlToPush: `/new/order?${cartTypes.new_order}=1`,
        saveButton: "ADD TO ORDER"
      },
      [cartTypes.new_order_accepted]: {
        pageTitle: "New Order",
        pageHeader: "Make Limited Changes For New Order",
        subHead1:
          "Attention: When creating a new order with this coating, you must only edit the two enabled fields at the bottom of the form. Review the information on this page and click 'Add To Order' to continue the new order process.",
        subHead2: "",
        backUrl: addToOrder ? `/new/order?${cartTypes.new_order_accepted}=1` : "/home",
        urlToPush: `/new/order?${cartTypes.new_order_accepted}=1`,
        saveButton: "ADD TO ORDER"
      },
      [cartTypes.normal]: {
        pageTitle: id ? "Edit Coating" : "New Coating",
        breadcrumbs: id ? (addToOrder ? ["Your Order", "Edit Coating"] : ["Home", "Edit Coating"]) : ["Create", "New Coating"],
        pageHeader: id ? (addToOrder ? "Make Your Changes" : "Make Your Changes") : "Create A New Coating",
        backUrl: id ? (addToOrder ? "/new/order" : "/home") : "/new",
        urlToPush: id ? (addToOrder ? "/new/order" : "/home") : addToOrder ? "/new/order" : "/new",
        saveButton: addToOrder ? "ADD TO ORDER" : "SAVE"
      }
    }[order.cartType || "normal"][textType];
  }

  // if (loading && id) {
  //   return <Spinner fullScreen={true} />;
  // }

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <PageHeader
          // title={!!id ? (newOrder || newOrderAccepted ? "Create New Order" : "Edit Coating") : "New Order"}
          title={pageContentMap("pageTitle")}
          // breadcrumbs={!!id ? ["Home", "Edit Coating"] : ["New Order", "Build New Coating"]}
          breadcrumbs={pageContentMap("breadcrumbs")}
          hint={
            <span>
              Begin with you're coating spec, then proceed by adding more specs or classes.
              <br />
              <br />
              Be sure to save all you're information when you're done.
              <br />
              <br />
              If a specification is not displayed in the lists below, please contact the CCL administrator at&nbsp;
              {REACT_APP_CCL_ADMIN_EMAIL}
            </span>
          }
        >
          {/* <BackButton url={newOrder || newOrderAccepted ? "/home" : !addToOrder && !id ? "/new" : id ? "/home" : "/new/order"} /> */}
          <BackButton url={"/home"} />
        </PageHeader>

        <PageContent>
          <Typography variant="h5">{id ? "Edit Coating" : "Build New Coating"}</Typography>
          {loading && id ? (
            <Spinner fullScreen={true} />
          ) : (
            <>
              {order.cartType === cartTypes.new_order && (
                <Typography className={classes.secSubmitDirections}>{pageContentMap("subHead1")}</Typography>
              )}

              <Box className={classes.grid} py={2}>
                {selectedSpecsArray.map((item, idx) => (
                  <CoatingTextField key={idx} item={item} disabled={order.cartType !== cartTypes.normal} />
                ))}
              </Box>

              <Box className={classes.grid} py={2}>
                {selectedSpecsArray.map((item, idx) => (
                  <div key={idx}>
                    <Typography className={classes.blackLabel}>Add Class</Typography>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id={`add-class-label-${idx}`}>Class</InputLabel>
                      <Select
                        multiple
                        labelId={`add-class-label-${idx}`}
                        variant="outlined"
                        value={item ? item.classes.filter((cls) => cls.default || cls.selected).map((cls) => cls.name) : []}
                        renderValue={(selected) => selected.join(", ")}
                        onChange={handleClassChange(item)}
                        label="Class"
                        disabled={!item || order.cartType !== cartTypes.normal}
                      >
                        {!!item &&
                          item.classes.map((cls) => (
                            <MenuItem
                              key={cls.name}
                              disabled={cls.default || order.cartType !== cartTypes.normal}
                              button
                              value={cls.name}
                            >
                              <Checkbox checked={!!(cls.default || cls.selected)} />
                              <ListItemText primary={cls.name} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                ))}
              </Box>

              <Box className={classes.grid} pt={2} pb={4}>
                <div>
                  <Typography className={classes.blackLabel}>Coating Spec</Typography>
                  <CoatingSpecList
                    items={coatingSpecs}
                    selected={selectedCoatingSpec}
                    onSelect={handleCoatingSelect}
                    disabled={loading || order.cartType !== cartTypes.normal}
                    loading={!coatingSpecs.length}
                  />
                </div>

                <div>
                  <Typography className={classes.blackLabel}>Powder Specs In Class</Typography>
                  <CoatingSpecList
                    items={powderSpecsIn}
                    powder
                    selected={selectedPowderSpecs}
                    onSelect={handlePowderSelect}
                    disabled={loading || order.cartType !== cartTypes.normal}
                  />
                </div>

                <div>
                  <Typography className={classes.blackLabel}>Powder Specs Out Of Class</Typography>
                  <CoatingSpecList
                    items={powderSpecsOut}
                    powder
                    selected={selectedPowderSpecs}
                    onSelect={handlePowderSelect}
                    disabled={loading || order.cartType !== cartTypes.normal}
                  />
                </div>

                <div>
                  <Typography className={classes.blackLabel}>Other Powder Specs</Typography>
                  <CoatingSpecList
                    items={powderSpecsOther}
                    powder
                    selected={selectedPowderSpecs}
                    onSelect={handlePowderSelect}
                    disabled={loading || order.cartType !== cartTypes.normal}
                  />
                </div>
              </Box>

              <Box pb={4}>
                <Typography className={classes.blueLabel}>Date of last acceptance for this coating</Typography>
                <KeyboardDatePicker
                  className={classes.control}
                  fullWidth
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="MM/DD/YYYY"
                  margin="normal"
                  id="date-of-last-acceptance"
                  value={selectedDate}
                  onChange={setSelectedDate}
                  disabled={newOrder}
                />
              </Box>

              <Box pb={4}>
                <Typography className={classes.blueLabel}>Laboratory that certified last accepted?</Typography>
                <Select
                  className={classes.control}
                  variant="outlined"
                  value={selectedLab}
                  fullWidth
                  disabled={newOrder}
                  onChange={({ target }) => setSelectedLab(target.value)}
                >
                  {labDropDowns.map((val, idx) => {
                    return (
                      <MenuItem key={val} value={idx}>
                        {val}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <Box pb={4}>
                <Typography className={classes.blueLabel}>Type: Full or Partial?</Typography>
                {order.cartType === cartTypes.new_order && (
                  <Typography className={classes.typeDisclaimer}>Value must be "Full" in order to create a new order.</Typography>
                )}
                <Select
                  className={classes.control}
                  variant="outlined"
                  value={order.cartType === cartTypes.new_order ? 1 : selectedFull}
                  onChange={({ target }) => setSelectedFull(target.value)}
                  fullWidth
                  disabled={order.cartType === cartTypes.new_order_accepted}
                >
                  <MenuItem value={1}>Full</MenuItem>
                  <MenuItem value={0}>Partial</MenuItem>
                </Select>
              </Box>

              <div className={classes.buttonContainer}>
                {loading ? (
                  <Spinner style={{ marginRight: "100%" }} />
                ) : (
                  <>
                    {addToOrder && (
                      <RoundedButton
                        color="primary"
                        onClick={handleSave(true)}
                        size="large"
                        disabled={!selectedDate || !selectedCoatingSpec || !order.noCoatings || selectedLab < 0}
                      >
                        ADD TO ORDER
                      </RoundedButton>
                    )}

                    <RoundedButton
                      color="primary"
                      onClick={handleSave(false)}
                      size="large"
                      disabled={!selectedDate || !selectedCoatingSpec || selectedLab < 0}
                    >
                      SAVE
                    </RoundedButton>

                    <RoundedButton color="primary" onClick={handleCancel} size="large">
                      CANCEL
                    </RoundedButton>
                  </>
                )}
              </div>
            </>
          )}
        </PageContent>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default withStyles(styles)(EditCoating);
