import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import AttachmentOutlinedIcon from "@material-ui/icons/AttachmentOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";

import { RESOURCES, useAPI } from "utils/api";
import { useOrder } from "context/order";
import RoundedButton from "components/Buttons/RoundedButton";

const styles = (theme) => ({
  header: {
    borderBottom: `3px solid ${theme.palette.info.main}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  title: {
    fontSize: 22,
    color: theme.palette.info.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  subheader: {
    fontSize: 22,
    color: theme.palette.primary.main
  },
  flex: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  chip: {
    borderRadius: 0,
    padding: theme.spacing(1),
    height: "auto"
  },
  chipLabel: {
    padding: theme.spacing(0, 3),
    color: theme.palette.info.main,
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: "normal"
  },
  deleteButton: theme.buttons.red,
  input: {
    visibility: "hidden",
    position: "absolute",
    left: -9999,
    top: 0
  }
});

const DeviationSourceCard = ({ classes, deviationTypes, source, title, onDelete, onChange }) => {
  const fileUploaderEl = React.useRef(null);
  const blobRef = React.useRef(null);
  const { callWithAuth } = useAPI();

  const [fileUploading, setFileUploading] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [filename, setFilename] = React.useState(source.attachment_filename ? source.attachment_filename : "");
  const [fileURL, setFileURL] = React.useState(source.attachment_url ? source.attachment_url : "");
  const { order } = useOrder();

  function toggle() {
    setOpen(!open);
  }

  async function handleChooseFile(event) {
    event.preventDefault();
    if (!event.target.files.length) {
      return;
    }

    blobRef.current = event.target.files[0];
    setFileUploading(true);

    const formData = new FormData();
    formData.append("attachment", blobRef.current, blobRef.current.name);

    let result;

    try {
      result = await callWithAuth({
        method: "POST",
        url: RESOURCES.attachments(order.id),
        axiosOptions: { data: formData }
      });
    } catch (e) {
      return;
    } finally {
      setFileUploading(false);
    }

    setFileURL(result.url);
    setFilename(result.filename);
    onChange("attachment_guid", result.guid);
  }

  function handleUpload() {
    fileUploaderEl.current.click();
  }

  function handleAttachmentDelete() {
    if (fileUploading) {
      return;
    }

    blobRef.current = null;
    setFilename("");
    onChange("attachment_guid", null);
  }

  function handleAttachmentClick() {
    window.open(fileURL);
  }

  const handleChange = (field) => ({ target }) => {
    onChange(field, target.value);
  };

  return (
    <Box mb={2}>
      <div className={classes.header}>
        <div className={`${classes.title} ${open ? classes.open : ""}`}>
          <span>{title}</span>
        </div>
        <IconButton size="small" onClick={toggle}>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
      </div>

      <Collapse in={open}>
        <Box pt={2}>
          <Box mb={2} className={classes.flex}>
            <RoundedButton color="primary" onClick={handleUpload} disabled={fileUploading || !!source.attachment_guid}>
              Attach Document
            </RoundedButton>

            {(filename || fileUploading) && (
              <Chip
                clickable={!fileUploading}
                label={fileUploading ? <CircularProgress size={16} /> : filename}
                classes={{
                  root: classes.chip,
                  label: classes.chipLabel
                }}
                onDelete={handleAttachmentDelete}
                onClick={handleAttachmentClick}
                icon={<AttachmentOutlinedIcon />}
                deleteIcon={<CloseOutlinedIcon />}
              />
            )}

            <RoundedButton size="small" startIcon={<DeleteOutlinedIcon />} className={classes.deleteButton} onClick={onDelete}>
              Delete
            </RoundedButton>
          </Box>

          <Typography variant="h6" className={classes.subheader}>
            Details
          </Typography>

          <Box my={2}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="select-type-label-1">Deviation Type</InputLabel>
                  <Select
                    labelId="select-type-label-1"
                    className={classes.control}
                    variant="outlined"
                    lable="Deviation Type"
                    value={source.metlab_type_id}
                    fullWidth
                    onChange={handleChange("metlab_type_id")}
                  >
                    {deviationTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  name="id"
                  label="Document ID"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  value={source.document_id}
                  onChange={handleChange("document_id")}
                />
              </Grid>
            </Grid>
          </Box>

          <Typography variant="h6" className={classes.subheader}>
            Additional Information
          </Typography>

          <Box my={2}>
            <Grid container spacing={2}>
              <Grid item sm={4} xs={12}>
                <TextField
                  multiline
                  name="comment"
                  label="Description"
                  rows={4}
                  variant="outlined"
                  fullWidth
                  value={source.comment}
                  onChange={handleChange("comment")}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Collapse>

      <input onChange={handleChooseFile} className={classes.input} type="file" ref={fileUploaderEl} />
    </Box>
  );
};

export default withStyles(styles)(DeviationSourceCard);
