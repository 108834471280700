import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useForm } from "react-hook-form";

import { useAPI, RESOURCES } from "utils/api";
import { useGlobalUI } from "context/globalUI";
import Modal from "components/Modal";
import Spinner from "components/Spinner";
import TextField from "components/TextField";

const ChangePasswordModal = ({ classes, user, onCancel, onSuccess }) => {
  const { register, errors, handleSubmit, watch } = useForm({ mode: "onBlur" });
  const data = watch();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const api = useAPI();
  const { snackbar } = useGlobalUI();

  const handleSave = async ({ old_password, password }) => {
    setError(null);
    setLoading(true);

    try {
      await api.callWithAuth({
        method: "PUT",
        url: RESOURCES.currentUserChangePassword,
        axiosOptions: { data: { old_password, password } },
        successMessage: "Password was sucessfully changed!"
      });
    } catch (e) {
      return;
    } finally {
      setLoading(false);
    }

    onSuccess();
  };

  return (
    <Modal
      open={true}
      title="Change Your Password"
      actions={
        <>
          {loading && <Spinner size={20} />}

          <Button variant="text" onClick={onCancel} disabled={loading}>
            Cancel
          </Button>

          <Button color="primary" variant="text" disabled={loading || !data.password} onClick={handleSubmit(handleSave)}>
            Save
          </Button>
        </>
      }
    >
      <Typography paragraph>
        Input your current password, then type in your new password and confirm it by typing again.
      </Typography>

      <TextField
        name="old_password"
        inputRef={register({ required: true })}
        error={!!errors.old_password}
        helperText="So we're sure it's really you..."
        label="Current Password"
        margin="normal"
        variant="outlined"
        type="password"
        color="secondary"
        empty={!data.old_password}
        leftPadding
        fullWidth
      />

      <TextField
        name="password"
        inputRef={register({ required: true, minLength: { value: 6, message: "Must be 6 characters minimum" } })}
        error={!!errors.password}
        helperText={errors.password ? errors.password.message : "Enter your new password"}
        label="New Password"
        margin="normal"
        variant="outlined"
        type="password"
        color="secondary"
        empty={!data.password}
        leftPadding
        fullWidth
      />

      <TextField
        name="password2"
        inputRef={register({ required: true, validate: (value) => value === data.password })}
        error={!!errors.password2}
        helperText={errors.password2 ? errors.password2.message : "Type it in one more time just to be safe"}
        label="Confirm New Password"
        margin="normal"
        variant="outlined"
        type="password"
        color="secondary"
        empty={!data.password2}
        leftPadding
        fullWidth
      />

      {error && <Typography color="error">{error}</Typography>}
    </Modal>
  );
};

export default ChangePasswordModal;
