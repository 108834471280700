import React from "react";
import { useParams } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import BackButton from "components/Buttons/BackButton";
import Spinner from "components/Spinner";
import RoundedButton from "components/Buttons/RoundedButton";
import HandleRequestModal from "./children/HandleRequestModal";
import { useAPI, RESOURCES } from "utils/api";
import { formatISODate } from "utils/format";
import { useGlobalUI } from "context/globalUI";

const styles = (theme) => ({
  companyName: {
    color: theme.palette.info.main
  },
  label: {
    // fontWeight: theme.typography.fontWeightMedium
  },
  value: {
    fontWeight: theme.typography.fontWeightLight
  },
  grid: {
    margin: theme.spacing(3, 0)
  },
  buttonContainer: {
    paddingTop: theme.spacing(8),
    display: "flex",
    justifyContent: "space-between"
  },
  redButton: theme.buttons.red
});

function ViewRequest({ classes }) {
  const params = useParams();
  const api = useAPI();
  const { navDrawer } = useGlobalUI();
  const APPROVE = "approve";
  const DENY = "deny";
  const labelWidthXs = 4;
  const valueWidthXs = 8;

  const [id, setId] = React.useState(params.id ? +params.id : null);
  const [request, setRequest] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [requestModal, setRequestModal] = React.useState(null);

  React.useEffect(() => {
    const fetchRequest = async (id) => {
      const result = await api.callWithAuth({ method: "GET", url: `${RESOURCES.accountRequests}/${id}` });
      setRequest(result);
      setLoading(false);
    };

    if (id) {
      fetchRequest(id);
    }
  }, []);

  const openRequestModal = (action) => setRequestModal(action);
  const closeRequestModal = () => setRequestModal(null);

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader
        title="View Request"
        breadcrumbs={["Home", "View Request"]}
        hint={
          request &&
          `${request.company_name} has requested an account. Review their information and you then you can approve or deny their request below.`
        }
      >
        <BackButton url="/metcut-admin" />
      </PageHeader>
      <PageContent>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Typography className={classes.companyName} variant="h3">
              {request.company_name}
            </Typography>
            <Grid className={classes.grid} container>
              <Grid container spacing={2} xs={12} sm={6}>
                <Grid className={classes.label} item xs={labelWidthXs}>
                  GE Vendor Code
                </Grid>
                <Grid item xs={valueWidthXs} className={classes.value}>
                  {request.ge_vendor_code}
                </Grid>
                <Grid className={classes.label} item xs={labelWidthXs}>
                  Date Requested
                </Grid>
                <Grid item xs={valueWidthXs} className={classes.value}>
                  {formatISODate(request.created)}
                </Grid>
                <Grid className={classes.label} item xs={labelWidthXs}>
                  Contact
                </Grid>
                <Grid item xs={valueWidthXs} className={classes.value}>
                  {request.first_name} {request.last_name}
                </Grid>

                <Grid className={classes.label} item xs={labelWidthXs}>
                  Contact Email
                </Grid>
                <Grid item xs={valueWidthXs} className={classes.value}>
                  {request.email}
                </Grid>

                <Grid className={classes.label} item xs={labelWidthXs}>
                  Contact Phone
                </Grid>
                <Grid item xs={valueWidthXs} className={classes.value}>
                  {request.phone}
                </Grid>
              </Grid>
              <Grid container spacing={2} xs={12} sm={6} alignContent="flex-start">
                <Grid className={classes.label} item xs={labelWidthXs}>
                  Address
                </Grid>
                <Grid item xs={valueWidthXs} className={classes.value}>
                  {request.address1}
                  <br />
                  {request.address2 || ""}
                </Grid>

                <Grid className={classes.label} item xs={labelWidthXs}>
                  Country
                </Grid>
                <Grid item xs={valueWidthXs} className={classes.value}>
                  {request.country}
                </Grid>
                <Grid className={classes.label} item xs={labelWidthXs}>
                  Region
                </Grid>
                <Grid item xs={valueWidthXs} className={classes.value}>
                  {request.region}
                </Grid>
                <Grid className={classes.label} item xs={labelWidthXs}>
                  City
                </Grid>
                <Grid item xs={valueWidthXs} className={classes.value}>
                  {request.city}
                </Grid>
                <Grid className={classes.label} item xs={labelWidthXs}>
                  Zip
                </Grid>
                <Grid item xs={valueWidthXs} className={classes.value}>
                  {request.postal_code}
                </Grid>
              </Grid>

              <Grid container xs={12} sm={5} className={classes.buttonContainer}>
                <RoundedButton onClick={() => openRequestModal(APPROVE)} color="secondary">
                  Approve Request
                </RoundedButton>
                <RoundedButton onClick={() => openRequestModal(DENY)} className={classes.redButton}>
                  Deny Request
                </RoundedButton>
              </Grid>
            </Grid>
          </>
        )}
      </PageContent>
      {requestModal && <HandleRequestModal action={requestModal} onClose={closeRequestModal} id={id} />}
    </div>
  );
}

export default withStyles(styles)(ViewRequest);
