import React from "react";
import { useTables } from "context/tables";

import ApprovePriorityModal from "./Modals/ApprovePriorityModal";
import CreateNewOrderModal from "./Modals/CreateNewOrderModal";
import CreateSecondOrderModal from "./Modals/CreateSecondOrderModal";
import DeleteItemModal from "./Modals/DeleteItemModal";
import DenyPriorityModal from "./Modals/DenyPriorityModal";
import NewOrderAcceptedModal from "./Modals/NewOrderAcceptedModal";
import RequestAppealModal from "./Modals/RequestAppealModal";
import StatusExplainModal from "./Modals/StatusExplainModal";
import ViewReportModal from "./Modals/ViewReportModal";

export default function TableModals() {
  const {
    modals: {
      open,
      close,
      names,
      canView: {
        showViewReportModal,
        showDeleteItemModal,
        showSecondOrderModal,
        showRequestAppealModal,
        showStatusExplainModal,
        showNewOrderModal,
        showNewOrderAcceptedModal,
        showApproveRequestModal,
        showDenyRequestModal
      }
    },
    item: { itemType, itemId, orderItemId, statusExplain, orderInDev, modalCallback, tableType, id }
  } = useTables();

  return (
    <>
      {/* {showViewReportModal && <ViewReportModal onCancel={toggleViewReportModal} onSuccess={toggleViewReportModal} />} */}
      {showDeleteItemModal && (
        <DeleteItemModal itemId={itemId} itemType={itemType} onClose={() => close(names.DELETE_ITEM)} onSuccess={modalCallback} />
      )}
      {showSecondOrderModal && (
        <CreateSecondOrderModal
          onClose={() => close(names.SECOND_ORDER)}
          itemType={itemType}
          itemId={itemId}
          id={id}
          orderItemId={orderItemId}
          orderInDev={orderInDev}
        />
      )}
      {showRequestAppealModal && (
        <RequestAppealModal onClose={() => close(names.REQUEST_APPEAL)} itemType={itemType} orderItemId={orderItemId} />
      )}
      {showStatusExplainModal && (
        <StatusExplainModal onClose={() => close(names.STATUS_EXPLAIN)} itemType={itemType} statusExplain={statusExplain} />
      )}
      {showNewOrderModal && (
        <CreateNewOrderModal
          onClose={() => close(names.NEW_ORDER)}
          itemId={itemId}
          orderItemId={orderItemId}
          itemType={itemType}
          orderInDev={orderInDev}
        />
      )}
      {showNewOrderAcceptedModal && (
        <NewOrderAcceptedModal
          onClose={() => close(names.NEW_ORDER_ACCEPTED)}
          itemId={itemId}
          orderItemId={orderItemId}
          itemType={itemType}
          orderInDev={orderInDev}
        />
      )}
      {showApproveRequestModal && (
        <ApprovePriorityModal
          onClose={() => close(names.APPROVE_REQUEST)}
          itemType={itemType}
          orderItemId={orderItemId}
          tableType={tableType}
        />
      )}
      {showDenyRequestModal && (
        <DenyPriorityModal
          onClose={() => close(names.DENY_REQUEST)}
          itemType={itemType}
          orderItemId={orderItemId}
          tableType={tableType}
        />
      )}
    </>
  );
}
