import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Box from "@material-ui/core/Box";
import PrintIcon from "@material-ui/icons/Print";

import { useAuth } from "context/auth";
import { API_URL, RESOURCES } from "utils/api";
import RoundedButton from "components/Buttons/RoundedButton";
import { useUserCompany } from "context/userCompany";

function Receipt({
  classes,
  onClose,
  order,
  subTotal,
  calculatedSalesTax,
  grandTotal,
  chosenShippingMethod,
  metcutAddress,
  noCoatings
}) {
  const { companyInfo, user } = useUserCompany();
  const auth = useAuth();
  const {
    id,
    procedures,
    coatings,
    addresses: { shipping, billing }
  } = order || {};

  const getDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return `${mm}/${dd}/${yyyy}`;
  };

  const handlePrintReceipt = () => {
    const isAdmin = user.role === "SuperAdmin" || user.role === "SystemAdmin" || user.role === "MetcutUser";
    const url = isAdmin ? RESOURCES.adminViewOrderPDF(companyInfo.id, id) : RESOURCES.viewOrderPDF(id);
    window.open(`${API_URL}${url}?access_token=${auth.getToken()}`, "_blank").focus();
  };

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={onClose}
      className={classes.dialog}
      PaperProps={{
        style: {
          backgroundColor: "#fff",
          boxShadow: "none"
        }
      }}
    >
      <div className={classes.container}>
        <IconButton id="printPageClose" edge="start" onClick={onClose} className={classes.close}>
          <CloseIcon />
        </IconButton>
        <Grid className={classes.container} container spacing={4}>
          <Grid className={classes.header} item xs={12}>
            <div className={classes.logoContainer}>
              <img src="/assets/logo-dark.png" alt="logo" className={classes.headerLogo} />
              <Typography className={classes.headerTitle}>Metcut</Typography>
            </div>
            <Typography className={classes.headerText}>Thank you for submitting your order with us!</Typography>
          </Grid>
          <Grid container className={classes.body} spacing={3}>
            <Grid item xs={8}>
              <Typography variant="h4">{companyInfo.name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <RoundedButton id="printPageButton" onClick={handlePrintReceipt} color="secondary" startIcon={<PrintIcon />}>
                Download Receipt
              </RoundedButton>
            </Grid>
            <Grid className={classes.orderDeetContainer} item xs={3}>
              <Typography className={classes.orderDeet}>Order Number: {id}</Typography>
            </Grid>
            <Grid className={classes.orderDeetContainer} item xs={3}>
              <Typography className={classes.orderDeet}>Date: {getDate()}</Typography>
              <Typography className={classes.orderDeet}>{order.submitted ? "Submitted" : "Not Submitted"}</Typography>
            </Grid>
            <Grid className={classes.orderDeetContainer} item xs={6}>
              <img
                className={classes.barcode}
                src={`https://alpha.api.metcut.systemsinsight.com/orders/${order.guid}/barcode`}
                alt="barcode"
              />
            </Grid>
            {!!billing && (
              <Grid item xs={6}>
                <Typography className={classes.blurbTitle} variant="h6" color="primary">
                  Billing To:
                </Typography>
                <Typography className={classes.address}>
                  {billing.company_name}
                  <br />
                  {billing.address1}
                  {billing.address2 ? `, ${billing.address2}` : ""}
                  <br />
                  {`${billing.city} ${billing.region}, ${billing.country}, ${billing.postal_code}`}
                </Typography>
              </Grid>
            )}
            {!noCoatings && (
              <Grid item xs={6}>
                <Typography className={classes.blurbTitle} variant="h6" color="primary">
                  Shipping To:
                </Typography>
                <Typography className={classes.address} dangerouslySetInnerHTML={{ __html: metcutAddress }}></Typography>
              </Grid>
            )}
            {!!shipping && !noCoatings && (
              <Grid item xs={6}>
                <Typography className={classes.blurbTitle} variant="h6" color="primary">
                  Return Shipping To:
                </Typography>
                <Typography className={classes.address}>
                  {shipping.company_name}
                  <br />
                  {shipping.address1}
                  {shipping.address2 ? `, ${shipping.address2}` : ""}
                  <br />
                  {`${shipping.city} ${shipping.region}, ${shipping.country}, ${shipping.postal_code}`}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} className={classes.summary}>
              <Grid item xs={12}>
                <Typography className={classes.blurbTitle} variant="h6" color="primary">
                  Order Summary:
                </Typography>
              </Grid>
              <Grid container>
                <Grid item xs={9}>
                  Item
                </Grid>
                <Grid item xs={3}>
                  Price
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {!!coatings.length &&
                coatings.map((item) => {
                  return (
                    <Grid key={item.id} container>
                      <Grid item xs={9}>
                        <Typography>Coating</Typography>
                        <Typography variant="caption">procedure num: {item.coating_string}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.bold}>${item.price.toFixed(2)}</Typography>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
            <Grid item xs={12}>
              {!!procedures.length &&
                procedures.map((item) => {
                  return (
                    <Grid key={item.id} container>
                      <Grid item xs={9}>
                        <Typography>{item.title}</Typography>
                        <Typography variant="caption">procedure num: {item.procedure_number}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.bold}>${item.price.toFixed(2)}</Typography>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
            {!!coatings.length && (
              <Grid container xs={12} className={classes.shippingPriceGroup}>
                <Grid item xs={9}>
                  <Typography>Return Shipping - {chosenShippingMethod.name} Delivery</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.price}>
                    {companyInfo.preferred_carrier_account
                      ? "Determined by carrier"
                      : `$${chosenShippingMethod.price.toFixed(2)}`}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid container xs={12} className={classes.summaryPricesGroup}>
              <Grid item xs={9}>
                <Typography className={classes.summaryLabel}>Sub Total:</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.summaryPrice}>${subTotal}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography className={classes.summaryLabel}>Sales Tax:</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.summaryPrice}>${calculatedSalesTax}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography className={classes.summaryLabel}>Grand Total:</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.summaryPrice}>${grandTotal}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}

const styles = (theme) => ({
  dialog: {
    backgroundColor: "#fff"
  },
  close: {
    position: "absolute",
    top: theme.spacing(4),
    left: theme.spacing(6)
  },
  container: {
    width: 900,
    margin: "0 auto",
    marginTop: theme.spacing(3)
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid lightGray"
  },
  logoContainer: {
    display: "flex"
  },
  headerTitle: {
    fontSize: 40,
    color: "inherit"
  },
  headerLogo: {
    height: 60,
    marginRight: theme.spacing(1)
  },
  headerText: {
    fontSize: 20
  },
  orderDeetContainer: {
    display: "flex",
    alignItems: "center"
  },
  orderDeet: {
    fontSize: 20,
    fontWieght: theme.typography.fontWeightMedium
  },
  barcode: {
    maxWidth: "100%"
  },
  body: {
    padding: theme.spacing(5, 3)
  },
  blurbTitle: {
    fontSize: 16
  },
  address: {
    fontSize: 16
  },
  summary: {
    borderBottom: "1px solid lightGray",
    marginTop: theme.spacing(3)
  },
  shippingPriceGroup: {
    margin: theme.spacing(0, 0, 2, 1)
  },
  summaryPricesGroup: {
    margin: theme.spacing(2, 2, 2, 1)
  },
  summaryLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 18
  },
  summaryPrice: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 18
  }
});

export default withStyles(styles)(Receipt);
