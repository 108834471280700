import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { withStyles } from "@material-ui/core/styles";

import RoundedButton from "components/Buttons/RoundedButton";

const styles = (theme) => ({
  paper: {
    // maxWidth: 300,
    background: theme.palette.common.white
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  message: {
    margin: theme.spacing(0.5, 0, 2, 0),
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 20,
    display: "flex",
    alignItems: "center"
  },
  button: {
    minWidth: 150,
    borderWidth: 2,
    margin: theme.spacing(0.5, 0, 2, 0),
    "&:hover": {
      borderWidth: 2
    }
  }
});

const RouterPromptModal = ({ classes, message, open, onStay, onLeave }) => (
  <Dialog open={open} classes={{ paper: classes.paper }}>
    <DialogContent>
      <Box className={classes.content}>
        <Typography className={classes.message}>
          <ErrorOutline className={classes.icon} />
          {message || "You have unsaved changes."}
        </Typography>
        <RoundedButton color="secondary" className={classes.button} size="small" onClick={onStay}>
          Stay here
        </RoundedButton>
        <RoundedButton className={classes.button} size="small" onClick={onLeave}>
          Leave the page anyway
        </RoundedButton>
      </Box>
    </DialogContent>
  </Dialog>
);

RouterPromptModal.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  onStay: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired
};

export default withStyles(styles)(RouterPromptModal);
