import React from "react";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";

import RoundedButton from "components/Buttons/RoundedButton";
import { useUserCompany } from "context/userCompany";

const styles = (theme) => ({
  interactions: {
    border: "1px solid lightGray",
    borderRadius: 4,
    padding: theme.spacing(3, 5),
    display: "flex",
    width: "max-content",
    "& div:last-child": {
      marginRight: 0
    }
  },
  interaction: {
    minWidth: 100,
    marginRight: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(4)
    },
    minHeight: 120,
    position: "relative",
    "& button:last-child": {
      position: "absolute",
      bottom: 0,
      left: 0
    }
  },
  label: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(0.5)
  },
  valueLight: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightLight
  },
  aTag: {
    color: theme.palette.primary.main,
    textDecoration: "none"
  },
  button: {
    fontSize: 14
  }
});

function SupplierInteractions({ supplier, classes }) {
  const history = useHistory();
  const { user } = useUserCompany();

  return (
    <>
      <Box mb={4} className={classes.interactions}>
        <Box className={classes.interaction}>
          <Typography className={classes.label}>Supplier Admin</Typography>
          <Typography className={classes.valueLight}>
            {supplier.admin_full_name ? supplier.admin_full_name : "No admin for this account"}
          </Typography>
          <Typography className={classes.valueLight}>{supplier.admin_email}</Typography>
          {user.role !== "GEAdmin" && user.role !== "GEUser" && (
            <RoundedButton className={classes.button} color="primary" size="small">
              <a href={`mailto:${supplier.admin_email}`} className={classes.aTag}>
                Email
              </a>
            </RoundedButton>
          )}
        </Box>
        <Box className={classes.interaction}>
          <Typography className={classes.label}>Supplier Info</Typography>
          <Typography className={classes.valueLight}>{supplier.address1}</Typography>
          <Typography className={classes.valueLight}>
            {supplier.city ? `${supplier.city}, ${supplier.country}` : "No Info Yet."}
          </Typography>
          {user.role !== "GEAdmin" && user.role !== "GEUser" && (
            <RoundedButton
              onClick={() => history.push(`/admin/suppliers/${supplier.id}/edit`)}
              color="secondary"
              size="small"
              startIcon={<EditIcon />}
              className={classes.button}
            >
              Edit
            </RoundedButton>
          )}
        </Box>

        <Box className={classes.interaction}>
          <Typography className={classes.label}>Users</Typography>
          <Typography className={classes.valueLight}>{supplier.total_users}</Typography>
          {user.role !== "GEAdmin" && user.role !== "GEUser" && (
            <RoundedButton
              className={classes.button}
              onClick={() => history.push(`/admin/suppliers/${supplier.id}`)}
              color="primary"
              size="small"
            >
              Manage
            </RoundedButton>
          )}
        </Box>
        <Box className={classes.interaction}>
          <Typography className={classes.label}>GE Vendor Code</Typography>
          <Typography className={classes.valueLight}>{supplier.ge_vendor_code}</Typography>
          <Typography className={classes.valueLight}></Typography>
          {/* <RoundedButton className={classes.button} color="secondary" size="small" startIcon={<EditIcon />}>
            Edit
          </RoundedButton> */}
        </Box>
      </Box>
    </>
  );
}

export default withStyles(styles)(SupplierInteractions);
