import React from "react";
import { Link } from "react-router-dom";

import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import RoundedButton from "components/Buttons/RoundedButton";

export default function PrefferedShippingGrid({ labelWidthXs, valueWidthXs, classes, user, toggleChangePasswordModal }) {
  return (
    <>
      <Grid className={classes.yourAccount} container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.header}>
            Your Account
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.subheader}>
            <Typography variant="h6">Personal Information</Typography>
            <RoundedButton color="secondary" size="small" startIcon={<EditIcon />} component={Link} to="/account/edit">
              Edit
            </RoundedButton>
          </div>

          <Grid container spacing={2}>
            <Grid className={classes.label} item xs={labelWidthXs}>
              First Name
            </Grid>
            <Grid item xs={valueWidthXs} className={classes.value}>
              {user.first_name}
            </Grid>

            <Grid className={classes.label} item xs={labelWidthXs}>
              Last Name
            </Grid>
            <Grid item xs={valueWidthXs} className={classes.value}>
              {user.last_name}
            </Grid>

            <Grid className={classes.label} item xs={labelWidthXs}>
              Phone
            </Grid>
            <Grid item xs={valueWidthXs} className={classes.value}>
              {user.phone}
            </Grid>

            <Grid className={classes.label} item xs={labelWidthXs}>
              Email
            </Grid>
            <Grid item xs={valueWidthXs} className={classes.value}>
              {user.email}
            </Grid>

            <Grid className={classes.label} item xs={labelWidthXs}>
              Password
            </Grid>

            <Grid item xs={valueWidthXs} align="left">
              <RoundedButton color="primary" onClick={toggleChangePasswordModal} onSuccess={toggleChangePasswordModal}>
                Change Password
              </RoundedButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
