import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import Modal from "components/Modal";

const styles = (theme) => ({
  red: {
    color: theme.palette.error.main
  }
});

function AddTestModal({ classes, tests, onCancel, onAdd }) {
  const [selectedTestsHash, setSelectedTestsHash] = React.useState({});

  const toggleChange = (id) => ({ target }) => {
    const selectedTestsHashClone = { ...selectedTestsHash };
    if (target.checked) {
      selectedTestsHashClone[id] = 1;
    } else {
      delete selectedTestsHashClone[id];
    }

    setSelectedTestsHash(selectedTestsHashClone);
  };

  function handleAdd() {
    const selectedTests = tests.filter(({ id }) => !!selectedTestsHash[id]);
    onAdd(selectedTests);
  }

  const numberOfSelectedTests = Object.keys(selectedTestsHash).length;

  return (
    <Modal
      open={true}
      title="Add Tests To Coating Evaluation"
      actions={
        <>
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>

          <Button className={classes.red} variant="text" disabled={!numberOfSelectedTests} onClick={handleAdd}>
            Add To Order
          </Button>
        </>
      }
    >
      <Grid container spacing={0}>
        {tests.map(({ id, formatted_name }) => (
          <Grid item xs={6} key={id}>
            <FormControlLabel
              control={<Checkbox checked={!!selectedTestsHash[id]} onChange={toggleChange(id)} />}
              label={formatted_name}
            />
          </Grid>
        ))}
      </Grid>
    </Modal>
  );
}

export default withStyles(styles)(AddTestModal);
