import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import RoundedButton from "components/Buttons/RoundedButton";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import { useGlobalUI } from "context/globalUI";

const { REACT_APP_CCL_ADMIN_EMAIL, REACT_APP_CCL_ADMIN_PHONE } = process.env;

const styles = (theme) => ({
  vidButton: {
    display: "block"
  },
  faqButton: {
    marginTop: theme.spacing(2)
  },
  text: {
    margin: theme.spacing(4, 0)
  },
  green: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  contact: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(3),
    "& > *": {
      marginRight: theme.spacing(2)
    }
  }
});

function Support({ classes }) {
  const { navDrawer } = useGlobalUI();
  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader
        title="Support"
        hint="Check out the videos for a little guidance through the coating or procedure submission process."
      />

      <PageContent>
        {/* <RoundedButton color="primary" className={classes.vidButton}>
          Video Training
        </RoundedButton> */}
        <RoundedButton color="primary" className={classes.faqButton} component={Link} to={`/support/faq`}>
          Frequently Asked Questions
        </RoundedButton>

        {/* <Typography className={classes.text}>
          Your Internal Administrator is <span className={classes.green}>Tom Wonburn</span>
          ${REACT_APP_CCL_ADMIN_EMAIL} removed this from href=mailto
        </Typography> */}

        <div className={classes.contact}>
          <Typography color="primary">Contact Support:</Typography>
          <RoundedButton size="small" color="primary" component="a" href={`mailto:CCL@metcut.com`}>
            Email
          </RoundedButton>
        </div>
      </PageContent>
    </div>
  );
}

export default withStyles(styles)(Support);
