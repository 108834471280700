import React from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { useOrder } from "context/order";
import { useUserCompany } from "context/userCompany";
import { useGlobalUI } from "context/globalUI";
import Radio from "../../children/Radio";
import Spinner from "components/Spinner";
import TextField from "components/TextField";
import RoundedButton from "components/Buttons/RoundedButton";
import DefinitionListItem from "components/Lists/DefinitionListItem";
import DefinitionListItemText from "components/Lists/DefinitionListItemText";
import ShippingAddressForm from "./ShippingAddressForm";

const styles = (theme) => ({
  address: {
    fontSize: 20
  },
  subheader: {
    marginBottom: theme.spacing(2)
  },
  subheaderSubtitle: {
    width: 600,
    fontWeight: theme.typography.fontWeightLight
  },
  subSectContent: {
    marginBottom: theme.spacing(4)
  },
  halfScreen: theme.containers.halfScreen,
  definitionListIcon: {
    minWidth: "auto"
  },
  shippingMessage: {
    border: "1px solid lightGray",
    borderRadius: 4,
    fontSize: 20,
    padding: theme.spacing(3, 5),
    marginBottom: theme.spacing(2)
  },
  shipmentOptionDescription: {
    marginTop: theme.spacing(0.5),
    fontSize: 16,
    color: "initial",

    "& > span": {
      fontWeight: theme.typography.fontWeightLight
    }
  },
  changeAddressButton: {
    fontSize: 18,
    textTransform: "initial",
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(3)
  }
});

function Shipping({ order, classes, onSave, onShippingSave, loading }) {
  const defaultValues = { shipping_method_id: order.shipping_method_id };
  const { register, errors, handleSubmit, watch } = useForm({ mode: "onBlur", defaultValues });

  const data = watch();
  const { snackbar } = useGlobalUI();

  const { companyInfo } = useUserCompany();
  const {
    fetchShippingMethods,
    shippingMethods,
    shippingAddress,
    handleAddressChange,
    setChosenShippingMethod,
    fetchCurrentOrder
  } = useOrder();

  const [showShippingAddressForm, setShowShippingAddressForm] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    fetchCurrentOrder();
    fetchShippingMethods();
  }, []);

  React.useEffect(() => {
    if (!loading) {
      setSaving(false);
    }
  }, [loading]);

  function toggleShowShippingAddressForm() {
    setShowShippingAddressForm(!showShippingAddressForm);
  }

  async function onConfirm(newAddress) {
    try {
      await handleAddressChange(newAddress, "shipping");
    } catch (e) {
      return;
    }
    toggleShowShippingAddressForm();
    // await fetchShippingAddress();
  }

  function handleContinue(data) {
    setSaving(true);

    if (!order.addresses.shipping) {
      snackbar.open({ message: "Shipping Address is required.", color: "error" });
      setSaving(false);
      return;
    }

    const shippingMethod = shippingMethods.find(({ id }) => id == data.shipping_method_id);
    setChosenShippingMethod(shippingMethod);
    onShippingSave(data);
  }

  return (
    <>
      <Box className={classes.shippingMessage}>
        <Typography>
          To prevent delays with return shipping, please include a copy of your commercial invoice with all international
          shipments.
        </Typography>
      </Box>

      <div className={classes.subheader}>
        <Typography variant="h5">Return Shipping Address</Typography>
        <Typography className={classes.subheaderSubtitle} variant="subtitle1">
          Provide the return address that MetcutCCL will use to return your package items. You can edit it here for a
          one-time-change regarding this order.
        </Typography>
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <Box className={classes.subSectContent}>
          {!!order.addresses.shipping ? (
            <Typography className={classes.address} paragraph>
              {order.addresses.shipping.company_name}
              <br />
              {order.addresses.shipping.address1}
              {order.addresses.shipping.address2 ? `, ${order.addresses.shipping.address2}` : ""}
              <br />
              {`${order.addresses.shipping.city} ${order.addresses.shipping.region}, ${order.addresses.shipping.country}, ${order.addresses.shipping.postal_code}`}
            </Typography>
          ) : (
            <Typography className={classes.address} paragraph>
              Use the form below to add your shipping address
            </Typography>
          )}
          <Button
            className={classes.changeAddressButton}
            color="primary"
            endIcon={<KeyboardArrowDownIcon />}
            onClick={toggleShowShippingAddressForm}
          >
            {!!order.addresses.shipping ? "Change Shipping Address" : "Add Shipping Address"}
          </Button>
          <Collapse in={showShippingAddressForm}>
            <ShippingAddressForm address={order.addresses.shipping} onConfirm={onConfirm} />
          </Collapse>
        </Box>
      )}

      {!!companyInfo.preferred_carrier_account && (
        <>
          <Typography style={{ marginBottom: 0 }} variant="h5">
            Preferred Shipping Carrier
          </Typography>
          <Box className={classes.subSectContent}>
            <List style={{ padding: 0, margin: 0 }} className={classes.halfScreen}>
              <DefinitionListItem>
                <DefinitionListItemText primary="Carrier" secondary={companyInfo.preferred_carrier_name} />
              </DefinitionListItem>
              <DefinitionListItem>
                <DefinitionListItemText primary="Account Number" secondary={companyInfo.preferred_carrier_account} />
              </DefinitionListItem>
            </List>
          </Box>
        </>
      )}

      <Typography variant="h5">Return Shipment Options</Typography>

      <Box className={classes.subSectContent}>
        {!shippingMethods.length ? (
          <Spinner />
        ) : (
          shippingMethods.map((method) => {
            return (
              <Radio
                key={method.id}
                name="shipping_method_id"
                value={method.id}
                id={method.name}
                label={`${method.name}...........${
                  companyInfo.preferred_carrier_account ? "Price determined by carrier" : `$${method.price}`
                }`}
                register={register}
              />
            );
          })
        )}
      </Box>

      {loading ? (
        <Spinner align="left" />
      ) : (
        <RoundedButton color="primary" size="large" onClick={handleSubmit(handleContinue)} disabled={loading}>
          Continue To Payment Details
        </RoundedButton>
      )}
    </>
  );
}

export default withStyles(styles)(Shipping);
