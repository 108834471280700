import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import Modal from "components/Modal";

const styles = (theme) => ({
  red: { color: theme.palette.error.main },
  bold: { fontWeight: theme.typography.fontWeightMedium }
});

const StatusExplainModal = ({ classes, onClose, itemType, statusExplain }) => {
  return (
    <Modal
      open={true}
      title="Status Explanation"
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            OK
          </Button>
        </>
      }
    >
      {!!statusExplain ? (
        <Typography className={classes.statusExplain} dangerouslySetInnerHTML={{ __html: statusExplain }} />
      ) : (
        <Typography className={classes.statusExplain}>
          MetcutCCL is awaiting the arrival of your package to begin testing.
        </Typography>
      )}
    </Modal>
  );
};

export default withStyles(styles)(StatusExplainModal);
