import moment from "moment";

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatISODate = (date, format = "MM/DD/YYYY") => {
  const dateMoment = moment.utc(date, moment.ISO_8601, true);
  if (!dateMoment.isValid()) {
    return "—";
  }

  return dateMoment.local().format(format);
};

export const formatNumberSuffix = (i) => {
  let j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
};
