import React, { useEffect, useState, createContext, useContext } from "react";

import Spinner from "components/Spinner";
import { RESOURCES, useAPI } from "utils/api";
import ERRORS from "utils/errors";
import { useAuth } from "./auth";
import { sentryConfigScope } from "utils/sentry";

const userCompanyContext = createContext();

export const UserCompanyProvider = ({ children }) => {
  const value = useUserCompanyContext();
  return (
    <userCompanyContext.Provider value={value}>
      {value.loading ? <Spinner fullScreen size={24} /> : children}
    </userCompanyContext.Provider>
  );
};

export const useUserCompany = () => {
  return useContext(userCompanyContext);
};

function useUserCompanyContext() {
  const { callWithAuth } = useAPI();
  const { setUser: setAuthUser, logout, getToken } = useAuth();

  const [user, setUser] = useState({});
  const [companyInfo, setCompanyInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  const updateData = async () => {
    setLoading(true);

    try {
      const user = await callWithAuth({ method: "GET", url: RESOURCES.currentUserInfo });
      const companyInfo = await callWithAuth({ method: "GET", url: RESOURCES.companyInfo });

      sentryConfigScope(user.email);
      setUser(user);
      setAuthUser(user);
      setCompanyInfo(companyInfo);
    } catch (e) {
      logout();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!getToken()) {
      // User is not authenticated
      setLoading(false);
      return;
    }

    updateData();
  }, []);

  return {
    loading,
    updateData,
    user,
    companyInfo
  };
}
