import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import HttpsIcon from "@material-ui/icons/Https";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { useGlobalUI } from "context/globalUI";
import { useOrder } from "context/order";
import RoundedButton from "components/Buttons/RoundedButton";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import Spinner from "components/Spinner";
import TextField from "components/TextField";
import BillingAddressForm from "./BillingAddressForm";

function Payment({ classes, onPaymentSave, loading, order }) {
  const { handleAddressChange, setStripePaymentDetails, setStripeToken } = useOrder();

  const [showBillingAddressForm, setShowBillingAddressForm] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const { snackbar } = useGlobalUI();
  const stripe = useStripe();
  const elements = useElements();

  function toggleShowBillingAddressForm() {
    setShowBillingAddressForm(!showBillingAddressForm);
  }

  async function onConfirm(newAddress) {
    try {
      await handleAddressChange(newAddress, "billing");
    } catch (e) {
      return;
    }

    toggleShowBillingAddressForm();
  }

  async function handleContinue() {
    setSaving(true);
    if (!stripe || !elements) {
      return;
    }
    if (!order.addresses.billing) {
      snackbar.open({ message: "Billing Address is required.", color: "error" });
      setSaving(false);
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement
    });
    if (error) {
      snackbar.open({ message: error.message, color: "error" });
      setSaving(false);
      return;
    }
    try {
      const stripe_token = await stripe.createToken(cardElement);
      setStripeToken(stripe_token);
    } catch (e) {
      snackbar.open({ message: e.message, color: "error" });
      setSaving(false);
      return;
    }

    setStripePaymentDetails(paymentMethod);
    onPaymentSave();
  }

  return (
    <>
      <Typography variant="h5">Credit Card Information</Typography>
      <Typography>Use the single input below to input your card number, date, and CVC number.</Typography>
      <Grid className={classes.stripeContainer} container spacing={4}>
        <Grid className={classes.iconContainer} item xs={12} sm={3}>
          <div>
            <Typography className={classes.securePayment}>SECURE PAYMENTS</Typography>
            <img className={classes.stripeImg} src="/assets/powered-by-stripe.svg" />
          </div>
          <HttpsIcon className={classes.securityIcon} />
        </Grid>
        <Grid item xs={12} sm={9} className={classes.stripElementContainer}>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "18px",
                  padding: "20px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4"
                  }
                },
                invalid: { color: "#9E1C36" },
                complete: { color: "#259E33" }
              }
            }}
          />
        </Grid>
      </Grid>

      <Typography variant="h5">Billing Address</Typography>

      {loading ? (
        <Spinner />
      ) : (
        <Box mb={4} mt={1}>
          {!!order.addresses.billing ? (
            <Typography className={classes.address} paragraph>
              {order.addresses.billing.company_name}
              <br />
              {order.addresses.billing.address1}
              {order.addresses.billing.address2 ? `, ${order.addresses.billing.address2}` : ""}
              <br />
              {`${order.addresses.billing.city} ${order.addresses.billing.region}, ${order.addresses.billing.country}, ${order.addresses.billing.postal_code}`}
            </Typography>
          ) : (
            <Typography className={classes.address} paragraph>
              Use the form below to add your billing address
            </Typography>
          )}

          <Button
            className={classes.changeAddressButton}
            color="primary"
            endIcon={<KeyboardArrowDownIcon />}
            onClick={toggleShowBillingAddressForm}
          >
            {!!order.addresses.billing ? "Change Billing Address" : "Add Billing Address"}
          </Button>
          <Collapse in={showBillingAddressForm}>
            <BillingAddressForm address={order.addresses.billing} onConfirm={onConfirm} />
          </Collapse>
        </Box>
      )}

      {saving ? (
        <Spinner align="left" />
      ) : (
        <RoundedButton color="primary" size="large" onClick={handleContinue} disabled={loading}>
          Continue To Confirmation
        </RoundedButton>
      )}
    </>
  );
}

const styles = (theme) => ({
  address: {
    fontSize: 20
  },
  changeAddressButton: {
    fontSize: 18,
    textTransform: "initial",
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(3)
  },
  halfScreen: theme.containers.halfScreen,
  stripeContainer: {
    display: "flex",
    alignItems: "center"
  },
  stripElementContainer: {
    height: "auto",
    maxWidth: "500px",
    border: "1px solid #d5d5d5",
    borderRadius: "4px",
    padding: "32px"
  },
  stripeImg: {
    maxWidth: 182
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative"
  },
  securityIcon: {
    color: theme.palette.secondary.main,
    fontSize: 60,
    marginTop: `-${theme.spacing(2)}px`
  },
  securePayment: {
    position: "absolute",
    top: "28%",
    left: 18,
    transform: "translate(16px, -50%)",
    fontWeight: theme.typography.fontWeightLight
  }
});

export default withStyles(styles)(Payment);
