import React from "react";
import { Link, useLocation, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { withStyles } from "@material-ui/core/styles";

import { useAuth } from "context/auth";
import { useGlobalUI } from "context/globalUI";
import RoundedButton from "components/Buttons/RoundedButton";
import SplashScreenButton from "components/Buttons/SplashScreenButton";
import ForgotPasswordModal from "./children/ForgotPasswordModal";
import Spinner from "components/Spinner";
import Splash from "components/Splash";
import { parseQueryString } from "utils/qs";
import ROUTES from "utils/routes";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4)
  },
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(7)
  },
  containerReset: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(4)
  },
  back: {
    position: "absolute",
    left: theme.spacing(8),
    top: theme.spacing(4)
  },
  paper: {
    marginTop: theme.spacing(2),
    backgroundColor: "#FFF",
    opacity: 0.9,
    maxWidth: 500,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(4, 8)
  },
  textField: {
    marginTop: theme.spacing(1)
  },
  h1: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 52,
    color: "#FFF"
  },
  h2: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 22,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2)
  }
});

function Login({ classes }) {
  const { register, errors, handleSubmit, watch, getValues } = useForm({ mode: "onBlur" });
  const data = watch();

  const [loading, setLoading] = React.useState(false);
  const [determiningForm, setDeterminingForm] = React.useState(true);
  const [isReset, setIsReset] = React.useState(false);
  const [isFirstLogin, setIsFirstLogin] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = React.useState(false);
  const [code, setCode] = React.useState(null);
  const [email, setEmail] = React.useState(null);

  const auth = useAuth();
  const location = useLocation();
  const { snackbar } = useGlobalUI();

  React.useEffect(() => {
    const qs = parseQueryString(location.search);
    if (!qs.login && qs.email && qs.code) {
      setCode(qs.code);
      setEmail(qs.email);
      setIsReset(true);
    }
    if (qs.login && qs.email && qs.code) {
      setCode(qs.code);
      setEmail(qs.email);
      setIsFirstLogin(true);
    }
    setDeterminingForm(false);
  }, []);

  const handlePasswordReset = async () => {
    if (getValues().password !== getValues().confirmedPassword) {
      return snackbar.open({
        message: "Password and Confirm Password must be the same.",
        color: "error",
        position: { vertical: "bottom", horizontal: "center" }
      });
    } else {
      setError(null);
      setLoading(true);
      try {
        await auth.submitNewPassword({ email: email, auth_token: code, password: data.confirmedPassword, isFirstLogin });
        setLoading(false);
      } catch (e) {
        snackbar.open({ message: e.message, color: "error", position: { vertical: "bottom", horizontal: "center" } });
        setLoading(false);
      }
    }
  };

  const handleSignIn = async ({ email, password }) => {
    setError(null);
    setLoading(true);

    const prevPath = !!location.state && !!location.state.from ? location.state.from.pathname : null;

    try {
      await auth.login(email, password, prevPath);
    } catch (e) {
      snackbar.open({ message: e.message, color: "error", position: { vertical: "bottom", horizontal: "center" } });
      setLoading(false);
    }
  };

  const toggleForgotPasswordModal = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal);
  };

  const helperText = isFirstLogin
    ? "When setting up your password, it must meet these minimum requirements:"
    : isReset
    ? "Your new password must meet these minimum requirements"
    : "";

  if (determiningForm) {
    return <Spinner fullScreen={true} />;
  } else if (!!auth.getToken() && !!auth.getUserRole() && !isReset && !isFirstLogin) {
    const role = auth.getUserRole();
    const url = role === "CompanyAdmin" || role === "User" ? ROUTES.supplierHome : ROUTES.metcutHome;
    const redirect = !!location.state && !!location.state.from ? location.state.from.pathname : url;
    return <Redirect to={redirect} />;
  }

  return (
    <div className={classes.root}>
      <Splash />

      <div className={isReset ? classes.containerReset : classes.container}>
        {!isReset && (
          <div className={classes.back}>
            <SplashScreenButton component={Link} to="/" startIcon={<ChevronLeftIcon />}>
              Back
            </SplashScreenButton>
          </div>
        )}

        {determiningForm ? (
          <Spinner fullScreen={true} color={"#fff"} />
        ) : (
          <>
            <Typography align="center" className={classes.h1}>
              {isFirstLogin ? "Welcome!" : isReset ? "Reset Password" : "Login"}
            </Typography>

            <Paper elevation={0} className={classes.paper}>
              <form onSubmit={handleSubmit(isFirstLogin ? handlePasswordReset : isReset ? handlePasswordReset : handleSignIn)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography align="center" className={classes.h2}>
                      {isFirstLogin
                        ? "Create a Password"
                        : isReset
                        ? "Create and Confirm a New Password"
                        : "Submit Your Email and Password"}
                    </Typography>
                    <Typography align="center">{helperText}</Typography>
                    {(isReset || isFirstLogin) && (
                      <Typography>
                        <ul style={{ marginBottom: 0 }}>
                          <li>Minimum of 8 characters</li>
                          <li>At least 1 uppercase letter</li>
                          <li>At least 1 number</li>
                          <li>At least 1 special character (!@#&)</li>
                        </ul>
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name={isFirstLogin ? "password" : isReset ? "password" : "email"}
                      inputRef={register({ required: true })}
                      error={isFirstLogin ? !!errors.password : isReset ? !!errors.password : !!errors.email}
                      label={isFirstLogin ? "Password" : isReset ? "New Password" : "Email"}
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      type={isFirstLogin ? "password" : isReset ? "password" : "email"}
                      fullWidth
                      autoFocus={true}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name={isFirstLogin ? "confirmedPassword" : isReset ? "confirmedPassword" : "password"}
                      inputRef={register({ required: true })}
                      error={isFirstLogin ? !!errors.confirmedPassword : isReset ? !!errors.confirmedPassword : !!errors.password}
                      label={isFirstLogin ? "Confirm Password" : isReset ? "Confirm New Password" : "Password"}
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      type={"password"}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} align="center">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <RoundedButton type="submit" color="primary" disabled={loading}>
                        {isReset ? "Submit" : "Log In"}
                      </RoundedButton>
                    )}
                  </Grid>

                  {!isReset && !isFirstLogin && (
                    <Grid item xs={12} align="center">
                      <Button onClick={toggleForgotPasswordModal}>Forgot Password?</Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            </Paper>
          </>
        )}
      </div>
      {showForgotPasswordModal && (
        <ForgotPasswordModal userEmail={data.email} onCancel={toggleForgotPasswordModal} onSuccess={toggleForgotPasswordModal} />
      )}
    </div>
  );
}

export default withStyles(styles)(Login);
