import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";

export default withStyles((theme) => ({
  head: {
    fontSize: 16,
    color: theme.palette.primary.main,
    borderBottom: `3px solid ${theme.palette.info.main}`,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.1
  },
  body: {
    fontSize: 15,
    color: "#666666"
  },
  sizeSmall: {
    padding: theme.spacing(0.5, 1),
    "&$body": {
      padding: theme.spacing(0.75, 1)
    }
  }
}))(TableCell);
