import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import pick from "lodash/pick";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import RoundedButton from "components/Buttons/RoundedButton";
import BackButton from "components/Buttons/BackButton";
import { useUserCompany } from "context/userCompany";
import { useAPI, RESOURCES } from "utils/api";
import TextField from "components/TextField";
import PageHeader from "components/PageHeader";
import { useGlobalUI } from "context/globalUI";

const VALIDATION_RULES = {
  name: { required: true },
  address1: { required: true },
  address2: { required: false },
  country: { required: true },
  city: { required: true },
  region: { required: false },
  postal_code: {
    required: false,
    pattern: {
      value: /^[0-9]+$/,
      message: "Only Numbers, please!"
    }
  }
};

const styles = (theme) => ({
  backButton: theme.buttons.back,
  value: {
    fontWeight: theme.typography.fontWeightLight
  },
  right: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    border: "1px solid #8c8c8c",
    borderRadius: 4,
    color: "#8c8c8c",
    fontSize: 18
  }
});

function EditCompanyInformation({ classes }) {
  const { register, errors, handleSubmit, watch, reset } = useForm({ mode: "onBlur" });
  const data = watch();

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { companyInfo, updateData } = useUserCompany();
  const { callWithAuth } = useAPI();
  const history = useHistory();
  const { navDrawer } = useGlobalUI();

  useEffect(() => {
    reset(pick(companyInfo, ["name", "address1", "address2", "country", "city", "region", "postal_code"]));
  }, []);

  function toggleChangePasswordModal() {
    setShowChangePasswordModal(!showChangePasswordModal);
  }

  async function handleSave() {
    try {
      await callWithAuth({
        method: "PUT",
        url: RESOURCES.companyInfo,
        axiosOptions: { data: { ...data } },
        successMessage: "Company details were updated successfully"
      });
    } catch (e) {
      return;
    }

    updateData();
    history.goBack();
  }

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader
        title="Edit Company Details"
        breadcrumbs={["Account", "Edit Company Detials"]}
        hint="Edit your company information below. The GE Vendor Code cannot be changed."
      >
        <BackButton url="/account" />
      </PageHeader>

      <Typography variant="h5">Company Details</Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="name"
                inputRef={register(VALIDATION_RULES.name)}
                error={!!errors.name}
                label="Company Name"
                margin="normal"
                variant="outlined"
                color="secondary"
                empty={!data.name}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="address1"
                inputRef={register(VALIDATION_RULES.address1)}
                error={!!errors.address1}
                label="Address 1"
                margin="normal"
                variant="outlined"
                color="secondary"
                empty={!data.address1}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="address2"
                inputRef={register(VALIDATION_RULES.address2)}
                error={!!errors.address2}
                label="Address 2"
                margin="normal"
                variant="outlined"
                color="secondary"
                empty={!data.address2}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="country"
                inputRef={register(VALIDATION_RULES.country)}
                error={!!errors.country}
                label="Country"
                margin="normal"
                variant="outlined"
                color="secondary"
                empty={!data.country}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="city"
                inputRef={register(VALIDATION_RULES.city)}
                error={!!errors.city}
                label="City"
                margin="normal"
                variant="outlined"
                color="secondary"
                empty={!data.city}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="region"
                inputRef={register(VALIDATION_RULES.region)}
                error={!!errors.region}
                label="State/Province"
                margin="normal"
                variant="outlined"
                color="secondary"
                empty={!data.region}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="postal_code"
                inputRef={register(VALIDATION_RULES.postal_code)}
                error={!!errors.postal_code}
                helperText={errors.postal_code && errors.postal_code.message}
                label="Zip/Postal Code"
                margin="normal"
                variant="outlined"
                color="secondary"
                empty={!data.postal_code}
                fullWidth
              />
            </Grid>

            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}

            <Grid item xs={12} align="center">
              <RoundedButton color="primary" onClick={handleSubmit(handleSave)} size="large">
                SAVE
              </RoundedButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.right}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                GE Vendor Code
              </Grid>
              <Grid item xs={7} className={classes.value}>
                {companyInfo.ge_vendor_code}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(EditCompanyInformation);
