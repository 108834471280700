import React, { useState, useEffect, useContext, createContext } from "react";

const globalUIContext = createContext();

export function GlobalUIProvider({ children }) {
  const ui = useProvideGlobalUI();
  return <globalUIContext.Provider value={ui}>{children}</globalUIContext.Provider>;
}

export const useGlobalUI = () => {
  return useContext(globalUIContext);
};

function useProvideGlobalUI() {
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    color: "",
    position: {}
  });
  const [feedbackModal, setFeedbackModal] = useState({
    isOpen: false,
    message: "",
    status: "",
    callback: null
  });
  const [navDrawer, setNavDrawer] = useState({
    isOpen: true
  });
  const [approveDenyRequest, setApproveDenyRequest] = React.useState(false);

  function openFeedbackModal({ message, status, title, callback }) {
    setFeedbackModal({
      message,
      status,
      title,
      callback,
      isOpen: true
    });
  }
  function closeFeedbackModal() {
    setFeedbackModal({
      message: "",
      status: "",
      title: "",
      callback: null,
      isOpen: false
    });
  }

  function openSnackbar({ message, color, position }) {
    setSnackbar({
      message,
      color,
      position,
      isOpen: true
    });
  }

  function closeSnackbar() {
    setSnackbar({
      ...snackbar,
      isOpen: false
    });
  }

  function triggerFeedback({ message, color }) {
    openSnackbar({ message, color });
  }

  return {
    snackbar: {
      open: openSnackbar,
      close: closeSnackbar,
      ...snackbar
    },
    feedbackModal: { open: openFeedbackModal, close: closeFeedbackModal, ...feedbackModal },
    apiUI: { triggerFeedback },
    navDrawer: { ...navDrawer, setNavDrawer },
    approveDenyRequest,
    setApproveDenyRequest
  };
}
