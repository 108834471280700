import React from "react";
import { Route, Redirect } from "react-router-dom";

import Spinner from "components/Spinner";
import { useUserCompany } from "context/userCompany";
import { useAuth } from "context/auth";
import ROUTES from "utils/routes";
import { useGlobalUI } from "context/globalUI";

export default ({ component: Component, permission, ...rest }) => {
  const auth = useAuth();
  const { feedbackModal } = useGlobalUI();

  function render(props) {
    if (!auth.user) {
      // User is not logged in
      return <Redirect to={{ pathname: ROUTES.login, state: { from: props.location } }} />;
    }

    if (!permission || auth.hasPermission(permission)) {
      // No specific permission required to access the route or the user has required permission
      return <Component {...props} />;
    }
    if (!auth.hasPermission(permission)) {
      return <Redirect to={ROUTES.errorForbidden} />;
    }

    return <Redirect to={ROUTES.error} />;
  }

  return <Route {...rest} render={render} />;
};
