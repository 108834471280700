import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import CartIcon from "@material-ui/icons/ShoppingCartOutlined";
import Typography from "@material-ui/core/Typography";

import RoundedButton from "components/Buttons/RoundedButton";

const styles = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "flex-start",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 4,
    padding: theme.spacing(2, 4),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      width: "max-content"
    }
  },
  icon: {
    fontSize: 60,
    marginLeft: -12,
    marginRight: theme.spacing(3)
  },
  text: {
    width: 200,
    color: theme.palette.info.main,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(3)
  },
  button: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main
  }
});

function OrderDraftBanner({ classes, history }) {
  return (
    <div className={classes.container}>
      <CartIcon className={classes.icon} color="secondary" />
      <Typography className={classes.text}>You currently have an order saved as a draft</Typography>
      <RoundedButton component={Link} to="/new/order" className={classes.button}>
        Continue Order
      </RoundedButton>
    </div>
  );
}

export default withStyles(styles)(OrderDraftBanner);
