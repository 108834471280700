import React from "react";
import { Link, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { withStyles } from "@material-ui/core/styles";

import { useOrder } from "context/order";
import TableCell from "components/Tables/TableCell";
import RoundedButton from "components/Buttons/RoundedButton";
import BackButton from "components/Buttons/BackButton";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import Spinner from "components/Spinner";
import { useGlobalUI } from "context/globalUI";
import ROUTES from "utils/routes";
import { formatNumberSuffix } from "utils/format";
import cartTypes from "utils/cartTypes";

import { API_URL, RESOURCES, useAPI } from "utils/api";
import { useAuth } from "context/auth";

const styles = (theme) => ({
  backButton: theme.buttons.back,
  totalsContainer: {
    width: 250,
    margin: `${theme.spacing(3)}px 11% 0 auto`
  },
  label: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.info.main
  },
  checkoutButton: {
    marginTop: theme.spacing(4),
    textAlign: "right"
  }
});

function Review({ classes }) {
  const { order, loading, calcSubTotal, cartType } = useOrder();
  const history = useHistory();
  const { navDrawer } = useGlobalUI();

  const [positions, setPositions] = React.useState([]);

  React.useEffect(() => {
    if (!order) {
      return;
    }

    if (!order.procedures.length && !order.coatings.length) {
      history.push("/new/order");
      return;
    }
    const positions = order.procedures.concat(order.coatings);
    setPositions(positions);
  }, [order]);

  const orderItemsLength = order ? order.coatings.length + order.procedures.length : 0;
  const auth = useAuth();
  const handlePrintReceipt = () => {
    const url = RESOURCES.viewOrderPDF(order.id);
    window.open(`${API_URL}${url}?access_token=${auth.getToken()}`, "_blank").focus();
  };

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader title="Review Order" breadcrumbs={["Assemble Order", "Review Order"]} hintSetting="infobox_order_review">
        <BackButton url={cartType !== cartTypes.normal ? `${ROUTES.yourOrder}?${cartType}=1` : ROUTES.yourOrder} />
      </PageHeader>

      <PageContent>
        {loading ? (
          <Spinner />
        ) : !orderItemsLength ? (
          <Spinner />
        ) : (
          <>
            <Typography variant="h5">Your Order</Typography>

            <Box mt={3}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Coating / Procedure</TableCell>
                    <TableCell>Submission</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {positions.map((item, idx) => {
                    let type = item.procedure_number ? item.type : item.full ? "Full" : "Partial";
                    return (
                      <TableRow key={item.order_item_id}>
                        <TableCell>{idx + 1}</TableCell>
                        <TableCell>{item.coating_string ? item.coating_string : item.title}</TableCell>
                        <TableCell>{formatNumberSuffix(item.submission_count)}</TableCell>
                        <TableCell>{type}</TableCell>
                        <TableCell>${item.price.toFixed(2)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>

            <Grid className={classes.totalsContainer} container>
              <Grid item xs={6}>
                <Typography className={classes.label}>Sub Total:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="right">${calcSubTotal(positions, 0)}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.checkoutButton}>
                <RoundedButton component={Link} to="/new/order/checkout" color="primary" size="large">
                  Checkout
                </RoundedButton>
                <RoundedButton onClick={handlePrintReceipt} color="secondary" size="large">
                  Downlaod Order Draft
                </RoundedButton>
              </Grid>
            </Grid>
          </>
        )}
      </PageContent>
    </div>
  );
}

export default withStyles(styles)(Review);
