import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiToolTip from "@material-ui/core/Tooltip";

const Tooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 16,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(2)
  },
  arrow: {
    color: theme.palette.primary.main
  }
}))(MuiToolTip);

export default Tooltip;
