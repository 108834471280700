import React, { useState, useEffect, useContext, createContext } from "react";
import { useHistory } from "react-router-dom";

import { RESOURCES, call } from "utils/api";
import { useGlobalUI } from "./globalUI";
import { sentryConfigScope } from "utils/sentry";
import ROUTES from "utils/routes";
import ERRORS from "utils/errors";

const LOCAL_STORAGE_KEY = "authTokenML";
const USER_ROLE_KEY = "userRoleML";

const authContext = createContext();

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const history = useHistory();
  const { snackbar } = useGlobalUI();

  const getToken = () => localStorage[LOCAL_STORAGE_KEY];
  const setToken = (token) => (localStorage[LOCAL_STORAGE_KEY] = token);
  const getUserRole = () => localStorage[USER_ROLE_KEY];

  const login = async (email, password, prevPath) => {
    const response = await call("POST", RESOURCES.login, null, { data: { email, password } });
    if (response.token) {
      localStorage[LOCAL_STORAGE_KEY] = response.token;
      localStorage[USER_ROLE_KEY] = response.user.role;
      setUser(response.user);
      sentryConfigScope(`${response.user.email}`);
      const routeToPush =
        response.user.role === "SystemAdmin" || response.user.role === "SuperAdmin" ? ROUTES.metcutHome : ROUTES.supplierHome;
      history.push(prevPath || routeToPush);
    } else {
      throw new Error(ERRORS.connection);
    }
  };

  /**
   * Unauthorized flag means that the user were logged out due to API request failed with 401/403 response code
   */
  const logout = (unauthorized) => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    localStorage.removeItem(USER_ROLE_KEY);
    setUser(null);

    if (unauthorized) {
      snackbar.open({
        message: "Please login to view/access that desired function",
        color: "error"
      });
    }
  };

  const hasPermission = (key) => {
    return user && user.permissions[key];
  };

  const forgotPassword = async (email) => {
    const response = await call("POST", RESOURCES.forgotPassword, null, { data: { email } });
    return response;
  };

  const submitNewPassword = async ({ email, auth_token, password, isFirstLogin }) => {
    const message = isFirstLogin
      ? "Welcome! Have a look around and you can make sure your information is correct on the 'Account' page."
      : "Password successfully reset! We highly recommend keeping a secure record of your password.";
    const response = await call("POST", RESOURCES.userResetPassword, null, {
      data: { email, auth_token, password }
    });
    if (response.token && response.user) {
      localStorage[LOCAL_STORAGE_KEY] = response.token;
      localStorage[USER_ROLE_KEY] = response.user.role;
      await setUser(response.user);
      await setToken(response.token);
      snackbar.open({
        message: message,
        color: "success"
      });
      const routeToPush =
        response.user.role === "SystemAdmin" || response.user.role === "SuperAdmin" ? ROUTES.metcutHome : ROUTES.supplierHome;
      history.push(routeToPush);
    }
  };

  return {
    user,
    setUser,
    login,
    logout,
    hasPermission,
    getToken,
    setToken,
    submitNewPassword,
    forgotPassword,
    getUserRole
  };
}
