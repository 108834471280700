import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    background: "rgba(214, 214, 214, .44)"
  },
  input: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    "& > input": {
      textAlign: "center"
    }
  },
  inputSmall: {
    fontSize: 12
  }
});

const CoatingTextField = React.memo(({ classes, item, ...props }) => {
  let value = item ? item.displayID : "";
  let selectedClasses = [];

  if (item) {
    selectedClasses = item.classes.filter((cls) => !cls.default && cls.selected);
    selectedClasses.forEach((cls) => (value = `${value} -CL${cls.name}`));
  }

  return (
    <TextField
      variant="outlined"
      readOnly
      className={classes.root}
      InputProps={{ className: `${classes.input} ${selectedClasses.length ? classes.inputSmall : ""}` }}
      value={value}
      {...props}
    />
  );
});

export default withStyles(styles)(CoatingTextField);
