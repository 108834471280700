import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import { useGlobalUI } from "context/globalUI";
import Modal from "components/Modal";

const styles = (theme) => ({
  red: {
    color: theme.palette.error.main
  },
  green: {
    color: theme.palette.secondary.main
  }
});

const FeedbackModal = ({ classes }) => {
  const { feedbackModal } = useGlobalUI();
  const status = feedbackModal.status;
  const title = (
    <span className={status === "success" ? classes.green : status === "error" ? classes.red : ""}>{feedbackModal.title}</span>
  );

  const handleClose = () => {
    feedbackModal.close();
    if (!!feedbackModal.callback) {
      feedbackModal.callback();
    }
  };

  return (
    <Modal
      open={feedbackModal.isOpen}
      title={title}
      actions={
        <>
          <Button variant="text" onClick={handleClose}>
            Close
          </Button>
        </>
      }
    >
      <Typography paragraph>{feedbackModal.message}</Typography>
    </Modal>
  );
};

export default withStyles(styles)(FeedbackModal);
