import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";

import { formatISODate } from "utils/format";
import { useAPI, RESOURCES } from "utils/api";
import { useUserCompany } from "context/userCompany";
import { useGlobalUI } from "context/globalUI";
import ROUTES from "utils/routes";
import RoundedButton from "components/Buttons/RoundedButton";
import TableCell from "components/Tables/TableCell";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import DeleteUserModal from "./children/DeleteUserModal";
import Spinner from "components/Spinner";

const styles = (theme) => ({
  value: {
    fontWeight: theme.typography.fontWeightLight
  },
  deleteIcon: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(2.5)
  },
  table: {
    marginTop: theme.spacing(5)
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    maxWidth: 600,
    border: "1px solid lightGray",
    borderRadius: 4,
    padding: theme.spacing(2, 2, 0.5, 2),
    ["& > *"]: {
      marginBottom: theme.spacing(1.5)
    }
  }
});

function ManageUsers({ classes }) {
  const [deleteUser, setDeleteUser] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const api = useAPI();
  const { navDrawer } = useGlobalUI();
  const { user: currentUser } = useUserCompany();
  const { companyInfo: cucompanyinfo } = useUserCompany();

  React.useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    const result = await api.callWithAuth({ method: "GET", url: RESOURCES.users });
    setUsers(result);
    setLoading(false);
  };

  const handleDeleteSuccess = () => {
    setDeleteUser(null);
    fetchUsers();
  };

  const openDeleteModal = (user) => () => setDeleteUser(user);
  const closeDeleteModal = () => setDeleteUser(null);

  const addButtonsMap = (buttonType) => {
    const userRole = currentUser.role;
    return {
      addMetcutAdmin: userRole === "SuperAdmin" || userRole === "SystemAdmin",
      addSuperAdmin: userRole === "SuperAdmin",
      addGEUser: userRole === "SuperAdmin" || userRole === "MetcutAdmin" || userRole === "GEAdmin",
      addGEAdmin: userRole === "SuperAdmin" || userRole === "MetcutAdmin" || userRole === "GEAdmin",
      addCompanyUser: userRole === "SuperAdmin" || userRole === "CompanyAdmin"
    }[buttonType];
  };

  const formatEditURL = ({ role, id }) => {
    switch (role) {
      case "SuperAdmin":
        return `/manage/admins/edit/${id}?super=1`;
      case "SystemAdmin":
        return `/manage/admins/edit/${id}?metAdmin=1`;
      case "GEAdmin":
        return `/manage/admins/edit/${id}?geAdmin=1`;
      case "GEUser":
        return `/manage/admins/edit/${id}?ge=1`;
      default:
        return `/manage/users/edit/${id}`;
    }
  };

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader title="Manage Users" hint="View and manage the users associated with this account.">
        <div className={classes.buttonContainer}>
          {addButtonsMap("addCompanyUser") && (
            <RoundedButton
              color="primary"
              size="small"
              startIcon={<AddIcon color="secondary" />}
              component={Link}
              to={`/manage/users/add`}
            >
              Add User
            </RoundedButton>
          )}
          {addButtonsMap("addMetcutAdmin") && (
            <RoundedButton
              color="primary"
              size="small"
              startIcon={<AddIcon color="secondary" />}
              component={Link}
              to={`${ROUTES.createAdmin}?metAdmin=1`}
            >
              Add Metcut Admin
            </RoundedButton>
          )}
          {addButtonsMap("addSuperAdmin") && (
            <RoundedButton
              color="primary"
              size="small"
              startIcon={<AddIcon color="secondary" />}
              component={Link}
              to={`${ROUTES.createAdmin}?super=1`}
            >
              Add Super Admin
            </RoundedButton>
          )}
          {/* {addButtonsMap("addGEAdmin") && ( */}
          {/*   <RoundedButton */}
          {/*     color="primary" */}
          {/*     size="small" */}
          {/*     startIcon={<AddIcon color="secondary" />} */}
          {/*     component={Link} */}
          {/*     to={`${ROUTES.createAdmin}?geAdmin=1`} */}
          {/*   > */}
          {/*     Add GE Admin */}
          {/*   </RoundedButton> */}
          {/* )} */}
        </div>
      </PageHeader>
      <Typography variant="h5">Company: {cucompanyinfo.name}</Typography>
      <Typography variant="h5">All Users Associated With {currentUser.first_name}</Typography>
      <PageContent>
        {loading ? (
          <Spinner fullScreen={true} />
        ) : (
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Date Added</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                return (
                  <TableRow key={user.id}>
                    <TableCell className={classes.bold}>
                      {user.full_name} {user.role === "CompanyAdmin" ? <span>- Company Admin</span> : ""}
                    </TableCell>
                    <TableCell>{formatISODate(user.created)}</TableCell>
                    <TableCell align="right">
                      <IconButton size="small" color="secondary" component={Link} to={formatEditURL(user)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        className={classes.deleteIcon}
                        onClick={openDeleteModal(user)}
                        disabled={user.id === currentUser.id}
                      >
                        <CloseIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </PageContent>

      {deleteUser && <DeleteUserModal user={deleteUser} onClose={closeDeleteModal} onSuccess={handleDeleteSuccess} />}
    </div>
  );
}
export default withStyles(styles)(ManageUsers);
