import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiButton from "@material-ui/core/Button";

const Button = ({ variant, disableElevation, ...props }) => <MuiButton variant="outlined" disableElevation {...props} />;

export default withStyles((theme) => ({
  root: {
    fontSize: 20,
    borderRadius: 22,
    borderWidth: 3,
    transition: "all .4s ease-out",

    "&:hover": {
      backgroundColor: theme.palette.primary.hover,
      transform: "scale(1.02)",
      borderWidth: 3
    }
  },
  outlinedPrimary: {
    borderColor: theme.palette.primary.main
  },
  outlinedSecondary: {
    borderColor: theme.palette.secondary.main,
    "&:hover": {
      transform: "scale(1.02)",
      backgroundColor: theme.palette.secondary.hover
    }
  },
  outlinedSizeSmall: {
    fontSize: 16,
    fontWeight: theme.palette.fontWeightMedium,
    padding: theme.spacing(0.5, 0)
  },
  outlinedSizeLarge: {
    minWidth: 250
  },
  label: {
    textTransform: "none",
    fontSize: "inherit",
    lineHeight: 1.25,
    padding: theme.spacing(0, 2),
    textAlign: "center"
  },
  startIcon: {
    marginRight: 2
  }
}))(Button);
