import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";

import RoundedButton from "components/Buttons/RoundedButton";
import BackButton from "components/Buttons/BackButton";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import Spinner from "components/Spinner";
import AddPropertyModal from "./children/AddPropertyModal";
import EditPropertyValueModal from "./children/EditPropertyValueModal";
import { useGlobalUI } from "context/globalUI";
import { useOrder } from "context/order";
import ROUTES from "utils/routes";
import { property } from "lodash";
/*Changes Below */

const styles = (theme) => ({
  backButton: theme.buttons.back,
  flex: {
    display: "flex",
    alignItems: "top"
  },
  subheader: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),

    "& > :first-child": {
      marginRight: theme.spacing(6)
    }
  },
  propertyName: {
    borderBottom: "1px solid lightGray"
  },
  itemContainer: {
    border: "1px solid lightGray",
    borderRadius: 4,
    width: 450,
    padding: theme.spacing(2.5),
    display: "flex",
    justifyContent: "space-between"
  },
  red: {
    color: theme.palette.error.main
  },
  redButton: theme.buttons.red,
  label: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium
  },
  value: {
    fontSize: 18
  },
  valueLight: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightLight
  },
  valueLight16: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightLight
  },
  deviationRequired: {},
  editDeviationButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    fontSize: 16,
    padding: theme.spacing(0.66, 0.33)
  },
  deviationAdded: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1)
  },
  saveBtn: {
    margin: theme.spacing(4, 0)
  }
});

function TestResults({ classes }) {
  const [showAddPropertyModal, setShowAddPropertyModal] = React.useState(false);
  const [showEditPropertyValueModal, setShowEditPropertyValueModal] = React.useState(false);
  const [editPropertyValue, setEditPropertyValue] = React.useState(null);
  const [editPropertyRequiredValue, setEditPropertyRequiredValue] = React.useState(false);

  const [coating, setCoating] = React.useState(null);
  const [test, setTest] = React.useState(null);
  const [presentPropertiesHash, setPresentPropertiesHash] = React.useState({});
  const [testLoading, setTestLoading] = React.useState(true);

  const params = useParams();
  const history = useHistory();
  const { navDrawer } = useGlobalUI();
  const { order, cartType, testsLoading, setCoatingTestResults } = useOrder();
  /* changes Below Changes Stop */
  function toggleShowAddPropertyModal() {
    setShowAddPropertyModal(!showAddPropertyModal);
  }

  function toggleShowEditPropertyValueModal(property) {
    setShowEditPropertyValueModal(!showEditPropertyValueModal);
  }

  const handleEditValueClick = (property, editPropertyRequiredValue) => () => {
    setEditPropertyValue(property);
    setEditPropertyRequiredValue(editPropertyRequiredValue);
    toggleShowEditPropertyValueModal();
  };

  React.useEffect(() => {
    if (!order) {
      // Order is loading
      return;
    }
    const { coatingId, id: testId } = params;
    const coating = order.coatings.find(({ id }) => id === +coatingId);
    const test = coating.tests.find(({ id }) => id === +testId);

    setCoating(coating);
    setTest(test);

    const presentPropertiesHash = {};

    test.properties.forEach((property) => {
      if (property.present || property.required) {
        presentPropertiesHash[property.id] = property;
        /*presentPropertiesHash[property.id].value = RegVal;*/
      }
    });

    setPresentPropertiesHash(presentPropertiesHash);
    setTestLoading(false);
  }, [order]);

  async function handleSave() {
    try {
      await setCoatingTestResults(coating, test, Object.values(presentPropertiesHash));
    } catch (e) {
      return;
    }

    history.push(ROUTES.yourOrder);
  }

  async function runValueCheck(presentProperties) {
    try {
      await setCoatingTestResults(coating, test, presentProperties);
    } catch (e) {
      return;
    }
  }

  const handleEditValue = async (property, value, requiredValue) => {
    const presentPropertiesHashClone = { ...presentPropertiesHash };

    if (requiredValue) {
      presentPropertiesHashClone[property.id].requirement_user_value = value;
    } else {
      presentPropertiesHashClone[property.id].value = value;
    }

    await runValueCheck(Object.values(presentPropertiesHashClone));

    setPresentPropertiesHash(presentPropertiesHashClone);
    toggleShowEditPropertyValueModal();
  };

  const handleCommentChange = (id) => ({ target }) => {
    const presentPropertiesHashClone = { ...presentPropertiesHash };
    presentPropertiesHashClone[id].comment = target.value;
    setPresentPropertiesHash(presentPropertiesHashClone);
  };
  const handleSuppValueChange = (id) => ({ target }) => {
    const presentPropertiesHashClone = { ...presentPropertiesHash };
    presentPropertiesHashClone[id].value = target.value;
    setPresentPropertiesHash(presentPropertiesHashClone);
  };

  //   const saveEnabled = React.useMemo(() => {
  //     for (const id in presentPropertiesHash) {
  //       if (!presentPropertiesHash[id].value) {
  //         return false;
  //       }
  //     }
  //
  //     return true;
  //   }, [presentPropertiesHash]);

  /*const [value, setValue] = React.useState(requiredValue ? property.required_value || property.required_default : property.value);
  function handleChange({ target }) {
    setValue(target.value);
  }
*/
  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader
        title="Your Test Results"
        breadcrumbs={["New Order", "Results Input"]}
        hint={
          <span>
            Input your results for the required properties. You can add more properties to this test any time.
            <br />
            <br />
            When you're finished, be sure to save your results at the bottom of the page.
          </span>
        }
      >
        <BackButton url={cartType !== "normal" ? `${ROUTES.yourOrder}?${cartType}=1` : ROUTES.yourOrder} />
      </PageHeader>

      <PageContent>
        {!!testLoading ? (
          <Spinner />
        ) : (
          <>
            <Box pb={6}>
              <Box className={[classes.flex, classes.itemContainer].join(" ")}>
                <Box>
                  <Typography className={classes.label}>Coating ID</Typography>
                  <Typography className={classes.valueLight16}>{coating.coating_string}</Typography>
                </Box>
              </Box>
            </Box>

            <div className={classes.subheader}>
              <Typography variant="h5">{test.formatted_name}</Typography>
              {/* <RoundedButton */}
              {/*   color="primary" */}
              {/*   startIcon={<AddIcon color="secondary" />} */}
              {/*   onClick={toggleShowAddPropertyModal} */}
              {/*   disabled */}
              {/* > */}
              {/*   Add A Property */}
              {/* </RoundedButton> */}
            </div>

            {Object.keys(presentPropertiesHash).map((id) => (
              <Box key={id} pt={3} pb={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={classes.propertyName}>
                      <Typography color="primary" variant="h6">
                        {`${presentPropertiesHash[id].name}
                          ${presentPropertiesHash[id].layer ? ` (${presentPropertiesHash[id].layer}) ` : ""} `}
                        {presentPropertiesHash[id].required ? <span className={classes.red}>*</span> : null}
                      </Typography>
                      {presentPropertiesHash[id].requirement_standard ? (
                        <Typography>{presentPropertiesHash[id].requirement_standard}</Typography>
                      ) : null}
                    </div>
                  </Grid>

                  <Grid item sm={3} xs={6}>
                    <Typography gutterBottom className={classes.label}>
                      Required Value
                    </Typography>
                    <Typography gutterBottom className={classes.value}>
                      {presentPropertiesHash[id].requirement_user_value ||
                        presentPropertiesHash[id].requirement_string ||
                        "No value added yet"}
                    </Typography>
                    <RoundedButton
                      size="small"
                      className={classes.redButton}
                      startIcon={<EditIcon />}
                      onClick={handleEditValueClick(presentPropertiesHash[id], true)}
                    >
                      Edit
                    </RoundedButton>
                  </Grid>
                  <Grid item sm={2} xs={6}>
                    <Typography gutterBottom className={classes.label}>
                      Your Value ( {presentPropertiesHash[id].requirement_unit} )
                    </Typography>
                    {/*                    <Typography gutterBottom className={classes.valueLight}>
                      {presentPropertiesHash[id].value || "No value added yet"}
                    </Typography>*/}
                    <TextField
                      multiline
                      rows={1}
                      fullWidth
                      variant="outlined"
                      value={presentPropertiesHash[id].value || ""}
                      onChange={handleSuppValueChange(id)}
                    />
                    {/*<RoundedButton
                      size="small"
                      color="secondary"
                      startIcon={<EditIcon />}
                      /*onClick={handleEdit}
                      onClick={handleEditValueClick(presentPropertiesHash[id], false)}
                    >
                      Edit
                                        <Grid item sm={2} xs={6}>
                    <Typography gutterBottom className={classes.label}>
                      Unit
                    </Typography>
                    {presentPropertiesHash[id].requirement_unit ? (
                      <Typography></Typography>
                    ) : null}
                  </Grid>
                  </RoundedButton>*/}
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Comment"
                      multiline
                      rows={5}
                      variant="outlined"
                      fullWidth
                      value={presentPropertiesHash[id].comment || ""}
                      onChange={handleCommentChange(id)}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    {presentPropertiesHash[id].deviation ? (
                      <Box borderRadius="borderRadius">
                        <Typography className={classes.deviationAdded}>A deviation was added to this property</Typography>

                        <RoundedButton
                          size="small"
                          className={classes.editDeviationButton}
                          component={Link}
                          to={`/new/order/${coating.id}/tests/${test.id}/deviation/${id}`}
                        >
                          Edit Deviation
                        </RoundedButton>
                      </Box>
                    ) : (
                      <Box borderRadius="borderRadius" className={classes.deviationRequired}>
                        <RoundedButton
                          size="small"
                          className={classes.editDeviationButton}
                          component={Link}
                          to={`/new/order/${coating.id}/tests/${test.id}/deviation/${id}`}
                        >
                          Add Deviation
                        </RoundedButton>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ))}

            <RoundedButton className={classes.saveBtn} color="primary" onClick={handleSave} size="large" disabled={testsLoading}>
              SAVE TEST RESULTS
            </RoundedButton>

            {showAddPropertyModal && (
              <AddPropertyModal onCancel={toggleShowAddPropertyModal} onAdd={toggleShowAddPropertyModal} />
            )}
            {showEditPropertyValueModal && (
              <EditPropertyValueModal
                property={editPropertyValue}
                requiredValue={editPropertyRequiredValue}
                onCancel={toggleShowEditPropertyValueModal}
                onEdit={handleEditValue}
                runValueCheck={runValueCheck}
              />
            )}
          </>
        )}
      </PageContent>
    </div>
  );
}

export default withStyles(styles)(TestResults);
