import React from "react";
import { Link, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";

import { useUserCompany } from "context/userCompany";
import { parseQueryString } from "utils/qs";
import { useAuth } from "context/auth";
import { API_URL, RESOURCES, useAPI } from "utils/api";
import RoundedButton from "components/Buttons/RoundedButton";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import Spinner from "components/Spinner";
import Receipt from "./children/Receipt";
import { useGlobalUI } from "context/globalUI";

const styles = (theme) => ({
  green: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 20
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium
  },
  light: {
    fontWeight: theme.typography.fontWeightLight
  },
  summary: {
    display: "flex",
    justifyContent: "space-between"
  },
  address: {
    fontSize: 20
  }
});

function Confirmation({ classes }) {
  const { user, updateData, companyInfo } = useUserCompany();
  const location = useLocation();
  const { navDrawer } = useGlobalUI();

  const [orderId, setOrderId] = React.useState(parseQueryString(location.search)?.id);
  const [order, setOrder] = React.useState(null);
  const [loadingMetAddress, setLoadingMetAddress] = React.useState(true);
  const [metcutAddress, setMetcutAddress] = React.useState(null);
  const [textLoading, setTextLoading] = React.useState(true);
  const [orderConfText, setOrderConfText] = React.useState("");
  const [printText, setPrintText] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [subTotal, setSubTotal] = React.useState(0);
  const [calculatedTax, setCalculatedTax] = React.useState(0);
  const [grandTotal, setGrandTotal] = React.useState(0);
  const [shippingMethod, setShippingMethod] = React.useState(null);

  const api = useAPI();
  const auth = useAuth();

  React.useEffect(() => {
    fetchMetcutAddress();
    fetchConfirmationText();
    fetchPrintText();

    if (orderId) {
      fetchOrder(orderId);
    }

    return () => {
      callsOnUnmount();
    };
  }, []);

  async function fetchOrder(id) {
    const url = RESOURCES.viewOrder(id);
    try {
      const response = await api.callWithAuth({ method: "GET", url: url });
      setOrder(response);
      setShippingMethod(response.shipping_method_name);
      calculateTotals(response);
    } catch (e) {
      setLoading(false);
      return;
    }
  }

  async function callsOnUnmount() {
    await updateData();
  }

  function calculateTotals(response) {
    const shippingPrice = response.shipping_method_price ? response.shipping_method_price : 0;
    const sub = response.coatings.concat(response.procedures).reduce((acc, curr) => {
      acc += curr.price;
      return acc;
    }, 0);
    const calcTax = ((sub + shippingPrice) * response.sales_tax).toFixed(2);

    setSubTotal((sub + shippingPrice).toFixed(2));
    setCalculatedTax(calcTax);
    setGrandTotal((Number(calcTax) + Number(sub) + shippingPrice).toFixed(2));
    setLoading(false);
  }

  async function fetchMetcutAddress() {
    try {
      const response = await api.callWithAuth({ mehtod: "GET", url: RESOURCES.settings("shipping_address") });
      setMetcutAddress(response.value);
    } catch (e) {
      return;
    }
    setLoadingMetAddress(false);
  }
  async function fetchConfirmationText() {
    try {
      const response = await api.callWithAuth({ mehtod: "GET", url: RESOURCES.settings("text_order_confirmation") });
      setOrderConfText(response.value);
    } catch (e) {
      return;
    }
  }
  async function fetchPrintText() {
    try {
      const response = await api.callWithAuth({ mehtod: "GET", url: RESOURCES.settings("text_order_receipt_instructions") });
      setPrintText(response.value);
    } catch (e) {
      setTextLoading(false);
      return;
    }
    setTextLoading(false);
  }

  const handlePrintReceipt = () => {
    const url = RESOURCES.viewOrderPDF(orderId);
    window.open(`${API_URL}${url}?access_token=${auth.getToken()}`, "_blank").focus();
  };

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader title="Order Confirmation" />

      <PageContent>
        <Typography className={classes.green} paragraph>
          Thank you for submitting you order!
        </Typography>

        {loading ? (
          <Spinner />
        ) : (
          <>
            {textLoading ? <Spinner /> : <Typography paragraph dangerouslySetInnerHTML={{ __html: orderConfText }} />}

            <Typography variant="h5" paragraph>
              Print and include the last page of your receipt in your order
            </Typography>

            <Box mb={4}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  {textLoading ? <Spinner /> : <Typography paragraph dangerouslySetInnerHTML={{ __html: printText }} />}
                </Grid>
                <Grid item sm={1} xs={0}></Grid>
                <Grid item sm={3} xs={12}>
                  {loadingMetAddress ? (
                    <Spinner />
                  ) : (
                    <RoundedButton onClick={handlePrintReceipt} color="secondary" size="large">
                      Download Receipt
                    </RoundedButton>
                  )}
                </Grid>
              </Grid>
            </Box>

            <Typography variant="h5" paragraph>
              Order Details
            </Typography>

            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Typography variant="h6" color="primary">
                  Payment Details
                </Typography>
                <Typography>Card Number:</Typography>
                <Typography className={classes.address}>**** **** **** {order.stripe_last4}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" color="primary">
                  Order Contact
                </Typography>
                <Typography className={classes.address}>
                  {order.user_first_name} {order.user_last_name}
                  <br />
                  {order.user_email}
                </Typography>
              </Grid>
              {!!order.addresses.billing && (
                <Grid item xs={4}>
                  <Typography variant="h6" color="primary">
                    Billing Address
                  </Typography>
                  <Typography className={classes.address}>
                    {order.addresses.billing.company_name}
                    <br />
                    {order.addresses.billing.address1}
                    {order.addresses.billing.address2 ? `, ${order.addresses.billing.address2}` : ""}
                    <br />
                    {`${order.addresses.billing.city} ${order.addresses.billing.region}, ${order.addresses.billing.country}, ${order.addresses.billing.postal_code}`}
                  </Typography>
                </Grid>
              )}
              {order.coatings.length >= 1 && (
                <Grid item xs={4}>
                  <Typography variant="h6" color="primary">
                    Return Shipping Address
                  </Typography>
                  <Typography className={classes.address}>
                    {order.addresses.shipping.company_name}
                    <br />
                    {order.addresses.shipping.address1}
                    {order.addresses.shipping.address2 ? `, ${order.addresses.shipping.address2}` : ""}
                    <br />
                    {`${order.addresses.shipping.city} ${order.addresses.shipping.region}, ${order.addresses.shipping.country}, ${order.addresses.shipping.postal_code}`}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={4}>
                <Typography variant="h6" color="primary">
                  Grand Total: {`$${grandTotal}`}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </PageContent>
    </div>
  );
}

export default withStyles(styles)(Confirmation);
