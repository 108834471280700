import { useState, useEffect } from "react";
import { useAuth } from "context/auth";
import { useGlobalUI } from "context/globalUI";
import axios from "axios";

import ERRORS from "./errors";
import { sentryError } from "./sentry";

const { REACT_APP_API_URL } = process.env;

export const RESOURCES = {
  users: "/users",
  login: "/users/login",
  currentUserInfo: "/users/me",
  currentUserChangePassword: "/users/me/password",
  resetPassword: (id) => `/users/${id}/password`,
  userResetPassword: "/users/login/reset_password",
  forgotPassword: "/users/login/forgot_password",
  register: "/companies/register",

  faq: "/misc/faq",

  orders: "/orders",
  procedures: "/procedures",
  coatings: "/coatings",
  singleCoating: (id) => `/coatings/${id}`,
  coatingSpecs: "/metlab/specs/coating",
  powderSpecs: (unique_id) => `/metlab/specs/powder/${unique_id}`,
  deviationTypes: "/metlab/deviationTypes",
  viewOrder: (order_id) => `/orders/${order_id}`,
  viewOrderPDF: (order_id) => `/orders/${order_id}/pdf`,
  adminViewOrder: (company_id, order_id) => `/companies/${company_id}/orders/${order_id}`,
  adminViewOrderPDF: (company_id, order_id) => `/companies/${company_id}/orders/${order_id}/pdf`,
  requestAppeal: (id, commApp) => `/items/${id}/appeal/request${commApp}`,
  resendOrderRec: (id, total) => `/orders/current/submit/resend${id}/${total}`,

  supplierHomeTables: "/tables",
  supplierSingleTable: (type, offset, limit) => `/tables/type/${type}?offset=${offset}&limit=${limit}`,
  companySingleTable: (company_id, type, offset, limit) =>
    `/tables/companies/${company_id}/type/${type}?offset=${offset}&limit=${limit}`,

  currentOrder: "/orders/current",
  currentOrderProcedures: "/orders/current/procedures",
  currentOrderCoatings: "/orders/current/coatings",
  currentOrderCoatingTest: (id, testId) => `/orders/current/coatings/${id}/tests/${testId}`,
  currentOrderCoatingTestDeviation: (id, testId, propertyId) =>
    `/orders/current/coatings/${id}/tests/${testId}/deviation${propertyId ? `/${propertyId}` : ""}`,
  orderBarcode: (id) => `/orders/${id}/barcode`,
  requestPriority: (id) => `/items/${id}/priority/request`,
  approvePriority: (id) => `/items/${id}/priority/approve`,
  rejectPriority: (id) => `/items/${id}/priority/reject`,
  priorityRequestItems: (status) => `/priorityRequests/${status}`,

  companyInfo: "/companies/my",
  companyUsers: (id) => `/companies/${id}/users`,
  companyUser: (company_id, user_id) => `/companies/${company_id}/users/${user_id}`,
  shippingAddress: "/companies/my/addresses/shipping",
  billingAddress: "/companies/my/addresses/billing",
  preferredCarrier: "/companies/my/carrier",
  suppliers: "/suppliers",
  currentCompany: (id) => `/companies/${id}`,
  companies: "/companies",
  changeCompanyAdmin: (company_id, user_id) => `/companies/${company_id}/users/${user_id}/admin`,

  accountRequests: "/companies/requests",
  approveRequest: (id) => `/companies/requests/${id}/approve`,
  denyRequest: (id) => `/companies/requests/${id}/deny`,

  settings: (key) => `/settings/${key}`,
  attachments: (order_id) => `/attachments/${order_id}`
};

export const API_URL = REACT_APP_API_URL;

export class APIError extends Error {
  constructor(message, status, data) {
    super(message);
    this.status = status;
    this.data = data;
  }
}

export const call = async (method, url, token, axiosOptions) => {
  let options = {
    method,
    url: `${REACT_APP_API_URL}${url}`,
    ...axiosOptions
  };

  if (token) {
    options = {
      ...options,
      headers: {
        Authorization: `Bearer ${token}`
      },
      withCredentials: true
    };
  }

  try {
    const { data } = await axios(options);
    return data.data;
  } catch (e) {
    if (e.response && e.response.data && e.response.data.status) {
      sentryError({ error: e.response.data.status, tag: { name: `API ${method}`, value: url }, level: "Error" });
      throw new APIError(e.response.data.status.message, e.response.status, e.response.data.data);
    }

    throw new APIError(ERRORS.connection, e.response ? e.response.status : null);
  }
};

export function useAPI() {
  const auth = useAuth();
  const { apiUI } = useGlobalUI();

  const callWithAuth = async ({ method, url, axiosOptions, successMessage }) => {
    const token = auth.getToken();
    let result;

    try {
      result = await call(method, url, token, axiosOptions);
    } catch (e) {
      if (e.status === 401) {
        auth.logout(true);
        return;
      }
      apiUI.triggerFeedback({ message: e.message, color: "error" });

      throw e;
    }

    if (successMessage) {
      apiUI.triggerFeedback({ message: successMessage, color: "success" });
    }

    return result;
  };

  return {
    callWithAuth
  };
}
