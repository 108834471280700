import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import AddIcon from "@material-ui/icons/Add";
import MenuIcon from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";

import { useAuth } from "context/auth";
import { useGlobalUI } from "context/globalUI";
import { HEADER_HEIGHT } from "./Header";
import ROUTES from "utils/routes";
import Footer from "components/Footer";

const DRAWER_WIDTH_CLOSED = 56;
const DRAWER_WIDTH_OPEN = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: "100%",
    "@media print": {
      display: "none"
    }
  },
  drawerPaper: {
    top: HEADER_HEIGHT,
    bottom: 0,
    border: "none",
    background: theme.palette.common.white,

    "&:hover:not($drawerOpen)": {
      boxShadow: theme.shadows[7],
      width: DRAWER_WIDTH_OPEN,
      paddingRight: theme.spacing(1)
    },

    "&:hover $listItem": {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0
    }
  },
  drawerClosed: {
    width: DRAWER_WIDTH_CLOSED,
    overflowX: "hidden",
    backgroundColor: "#f4f4f4",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.soft,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawerOpen: {
    width: DRAWER_WIDTH_OPEN,
    overflowX: "visible",
    paddingRight: theme.spacing(1),

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),

    "& $listItem": {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0
    }
  },
  drawerButton: {
    padding: theme.spacing(1, 2, 0)
  },
  contentDrwOpen: {
    flexGrow: 1,
    padding: theme.spacing(3, 8),
    width: "100%"
  },
  contentDrwClosed: {
    flexGrow: 1,
    padding: theme.spacing(3, 9, 8, 9.75),
    width: "100%"
  },
  listItemTextTypography: {
    fontSize: 14
  },
  listItem: {
    borderRadius: 24
  },
  listItemSelected: {
    background: "rgba(24, 131, 173, .1) !important",
    marginRight: 10,
    color: theme.palette.info.main,

    "& $listItemIconSelected": {
      color: theme.palette.info.main
    }
  },
  listItemIconSelected: {}
});

const PAGES = [
  { url: ROUTES.supplierHome, icon: <HomeOutlinedIcon />, title: "Home", permission: "procedure.edit" },
  { url: ROUTES.metcutHome, icon: <DashboardOutlinedIcon />, title: "Admin Dashboard", permission: "admin.dashboard.view" },
  /*{ url: ROUTES.create, icon: <AddIcon />, title: "Create Order", permission: "procedure.edit" }, */
  { url: ROUTES.myAccount, icon: <PersonOutlineOutlinedIcon />, title: "My Account" },
  {
    url: ROUTES.orders,
    icon: <ListOutlinedIcon />,
    title: "Orders",
    permission: "admin.suppliers.view"
  },
  {
    url: ROUTES.users,
    icon: <SupervisorAccountOutlinedIcon />,
    title: "Users",
    permission: "account.users.list"
  },
  { url: ROUTES.suppliers, icon: <SettingsIcon />, title: "Suppliers", permission: "admin.suppliers.edit" },
  { url: ROUTES.support, icon: <ContactSupportOutlinedIcon />, title: "Support" }
];

const Layout = ({ classes, children }) => {
  const [open, setOpen] = React.useState(true);
  const { pathname } = useLocation();
  const { hasPermission } = useAuth();
  const { navDrawer } = useGlobalUI();

  const toggleDrawer = () => {
    navDrawer.setNavDrawer({ isOpen: !open });
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        anchor="left"
        variant="permanent"
        classes={{
          root: `${classes.drawer} ${open ? classes.drawerOpen : classes.drawerClosed}`,
          paper: `${classes.drawerPaper} ${open ? classes.drawerOpen : classes.drawerClosed}`
        }}
      >
        <div className={classes.drawerButton}>
          <IconButton edge="start" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
        </div>

        <List className={classes.list}>
          {PAGES.filter(({ permission }) => !permission || hasPermission(permission)).map(({ url, icon, title }) => (
            <ListItem
              key={url}
              classes={{ root: classes.listItem, selected: classes.listItemSelected }}
              selected={pathname.startsWith(url)}
              button
              component={Link}
              to={url}
            >
              <ListItemIcon className={classes.listItemIconSelected}>{icon}</ListItemIcon>
              <ListItemText primary={title} classes={{ primary: classes.listItemTextTypography }} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <main className={open ? classes.contentDrwOpen : classes.contentDrwClosed}>{children}</main>
    </div>
  );
};

Layout.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Layout);
