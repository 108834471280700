import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { withStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import { call, RESOURCES } from "utils/api";
import { useGlobalUI } from "context/globalUI";
import Spinner from "components/Spinner";
import Splash from "../components/Splash";
import { HEADER_HEIGHT } from "components/Header";
import RoundedButton from "../components/Buttons/RoundedButton";
import SplashScreenButton from "../components/Buttons/SplashScreenButton";

const styles = (theme) => ({
  container: {
    position: "absolute",
    zIndex: 1,
    left: 0,
    right: 0,
    top: HEADER_HEIGHT,
    bottom: 0,
    overflowX: "hidden"
  },
  back: {
    position: "absolute",
    left: theme.spacing(3),
    top: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      top: theme.spacing(0.5),
      left: theme.spacing(1),
      fontSize: 10
    },
    [theme.breakpoints.up("md")]: {
      left: theme.spacing(8),
      top: theme.spacing(2)
    }
  },
  top: {
    // marginBottom: theme.spacing(11),
    textAlign: "center",
    padding: theme.spacing(6, 8, 8, 8),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(9, 2, 6, 2)
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6, 10, 8, 10)
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(6, 15, 15, 15)
    },
    [theme.breakpoints.between("1400", "2200")]: {
      padding: theme.spacing(6, 19, 19, 19)
    }
  },
  rrt: {
    whiteSpace: "nowrap"
  },
  topSectionH1: {
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 70,
    lineHeight: 1,
    color: "#FFF",
    [theme.breakpoints.down("xs")]: {
      fontSize: 54
    }
  },
  topSectionH2: {
    fontSize: 30,
    color: "#FFF",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      fontSize: 24
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(4)
    }
  },
  step: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%"
  },
  stepSvg: {
    height: 180,
    [theme.breakpoints.down("xs")]: {
      height: 160
    }
  },
  stepText: {
    fontSize: 20,
    color: "#fff"
  },
  paper: {
    backgroundColor: "#fff",
    padding: theme.spacing(2, 24, 4, 24),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2, 2, 4, 2)
    },
    [theme.breakpoints.between("900", "1200")]: {
      padding: theme.spacing(3, 4)
    }
  },
  form: {
    paddingTop: theme.spacing(2)
  },
  formHeader: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 28,
    [theme.breakpoints.down("xs")]: {
      fontSize: 24
    }
  },
  phoneInput: {
    marginTop: theme.spacing(2)
    // backgroundColor: "#EBEBEB"
  },
  inputPhone: {
    focused: {
      color: theme.palette.secondary.main
    }
  },
  textField: {
    marginBottom: theme.spacing(0.5)
  },
  submitBtn: {
    margin: theme.spacing(1, 0, 6, 0),
    width: "20%",
    fontSize: 24,
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(1, 0, 3, 0),
      width: "75%"
    }
  },
  link: {
    color: theme.palette.primary.main
  }
});

function SignUp({ classes }) {
  const { register, errors, handleSubmit, watch, reset, control } = useForm({ mode: "onBlur" });
  const data = watch();
  const { feedbackModal } = useGlobalUI();
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);

  const handleSignUp = async () => {
    setLoading(true);
    const phone = `+${data.phone}`;
    try {
      await call("POST", RESOURCES.register, null, { data: { ...data, phone } });
      feedbackModal.open({
        title: "Request Submitted!",
        message:
          "Thank you for your request! MetcutCCL will receive your request shortly. You will be notified of the request approval or denial in the next couple of days.",
        status: "success",
        callback: () => history.push("/")
      });
      reset();
      setLoading(false);
    } catch (e) {
      feedbackModal.open({ title: "Error", message: e.message, status: "error" });
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <Splash />
      <div className={classes.back}>
        <SplashScreenButton component={Link} to="/" startIcon={<ChevronLeftIcon />}>
          Back
        </SplashScreenButton>
      </div>
      <div>
        <Box className={classes.top}>
          <Typography className={classes.topSectionH1}>Get Started</Typography>
          <Typography className={classes.topSectionH2}>
            Sign up for <span className={classes.rrt}>Round Robin Testing</span>
          </Typography>
          <Grid container spacing={6}>
            <Grid className={classes.step} item xs={12} sm={4}>
              <img style={{ padding: 10 }} src="/assets/Secure.svg" className={classes.stepSvg} />
              <Typography className={classes.stepText}>
                Securely sign up with us by filling out the form below. We need some of your company's basic information as well
                as your contact information in order to verify your identity through GE.
              </Typography>
            </Grid>
            <Grid className={[classes.step]} item xs={12} sm={4}>
              <img src="/assets/Email.svg" className={classes.stepSvg} />
              <Typography className={classes.stepText}>
                Once you've sent us your information, a MetcutCCL employee will review your application and then be in touch by
                email within just a few days.
              </Typography>
            </Grid>
            <Grid className={[classes.step]} item xs={12} sm={4}>
              <img src="/assets/Settings.svg" className={classes.stepSvg} />
              <Typography className={classes.stepText}>
                Upon approval, you'll be prompted to log in to our system, finish setting up your company's account, and manage
                Coatings, Procedures, and your company's orders.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.paper}>
          <form className={classes.form} onSubmit={handleSubmit(handleSignUp)}>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.formHeader}>Contact Info - Vendor Code</Typography>
                <TextField
                  name="company_name"
                  inputRef={register({ required: true })}
                  error={!!errors.company_name}
                  label="Company Name"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  name="first_name"
                  inputRef={register({ required: true })}
                  error={!!errors.first_name}
                  label="First Name"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  name="last_name"
                  inputRef={register({ required: true })}
                  error={!!errors.last_name}
                  label="Last Name"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                <Controller
                  as={
                    <PhoneInput
                      name="phone"
                      className={classes.textField}
                      containerClass={classes.phoneInput}
                      inputClass={classes.inputPhone}
                      country="us"
                      prefix="+"
                      inputProps={{
                        label: "Phone"
                      }}
                    />
                  }
                  rules={{ required: true }}
                  name="phone"
                  control={control}
                  defaultValue={data.phone}
                />
                <TextField
                  name="email"
                  inputRef={register({ required: true })}
                  error={!!errors.email}
                  label="Email"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  name="ge_vendor_code"
                  inputRef={register({ required: true })}
                  error={!!errors.ge_vendor_code}
                  label="GE Vendor Code"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                <div style={{ paddingTop: 12 }}>
                  <Typography color="primary" variant="p">
                    This code is supplied by General Electric. If you are not a GE Aviation Supplier{" "}
                    <a className={classes.lin} href="#">
                      click here
                    </a>
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.formHeader}>Address</Typography>
                <TextField
                  name="address1"
                  inputRef={register({ required: true })}
                  error={!!errors.address1}
                  label="Address"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  name="address2"
                  inputRef={register({ required: false })}
                  error={!!errors.address2}
                  label="Address 2"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  name="city"
                  inputRef={register({ required: true })}
                  error={!!errors.city}
                  label="City"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  name="region"
                  inputRef={register({ required: true })}
                  error={!!errors.region}
                  label="State/Province"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  name="country"
                  inputRef={register({ required: true })}
                  error={!!errors.country}
                  label="Country"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  name="postal_code"
                  inputRef={register({ required: true })}
                  error={!!errors.postal_code}
                  label="Zip/Postal Code"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              {/* {error && (
                    <Grid item xs={12}>
                      <Typography color="error">{error}</Typography>
                    </Grid>
                  )} */}

              <Grid item xs={12} align="center">
                {loading ? (
                  <Spinner />
                ) : (
                  <RoundedButton className={classes.submitBtn} type="submit" color="primary">
                    SEND
                  </RoundedButton>
                )}
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    </div>
  );
}

export default withStyles(styles)(SignUp);
