import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";

import { useAPI, RESOURCES } from "utils/api";
import MetcutTable from "components/Tables/MetcutTable";
import Pagination from "components/Tables/Pagination";
import PageContent from "components/PageContent";
import Spinner from "components/Spinner";
import PageHeader from "components/PageHeader";
import BackButton from "components/Buttons/BackButton";
import { useGlobalUI } from "context/globalUI";
import { useTables } from "context/tables";

const styles = (theme) => ({
  backButton: theme.buttons.back,
  table: {
    marginTop: theme.spacing(1.25)
  },
  blue: {
    color: theme.palette.info.main
  },
  red: {
    color: theme.palette.error.main
  }
});

function SupplierAllData({ classes }) {
  const params = useParams();
  const api = useAPI();
  const { navDrawer } = useGlobalUI();
  const { tableTitles, tables } = useTables();

  const [tableToFetch, setTableToFetch] = React.useState(params.type ? params.type : null);
  const [tableToUse, setTableToUse] = React.useState(null);
  const [tableItems, setTableItems] = React.useState([]);
  const [limit, setLimit] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (tableToFetch === "items_in_dev") setTableToUse(tables.ITEMS_IN_DEV);
    if (tableToFetch === "items_submitted") setTableToUse(tables.ITEMS_SUBMITTED);
    if (tableToFetch === "items_history") setTableToUse(tables.ITEMS_HISTORY);
    if (tableToFetch === "coatings_accepted") setTableToUse(tables.ACCEPTED_COATINGS);
    if (tableToFetch === "procedures_accepted") setTableToUse(tables.ACCEPTED_PROCEDURES);
    if (tableToFetch) {
      fetchTable(tableToFetch);
    }
  }, [start, limit]);

  const fetchTable = async (table_type) => {
    try {
      const result = await api.callWithAuth({
        method: "GET",
        url: RESOURCES.supplierSingleTable(table_type, start, limit)
      });
      await setTableItems(result);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      return;
    }
  };

  const pageHints = {
    items_in_dev:
      "This page refers to all coatings or procedures that have been created, but have not been submitted for testing.",
    items_submitted: "This page refers to all coatings or procedures that have been paid for and submitted for testing.",
    items_history:
      "This page refers to all coatings or procedures that have previously been submitted as part of an order, but not currently apart of an ongoing order in development or testing.",
    coatings_accepted:
      "This page refers to all procedures that have previously been submitted for testing and have been approved by MetcutCCL.",
    procedures_accepted:
      "This page refers to all procedures that have previously been submitted for testing and have been approved by MetcutCCL."
  };

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader
        title="View All Table Data"
        hint={pageHints[tableToFetch]}
        breadcrumbs={["Home", "View All Table Data"]}
        hintIcon={<ThumbUpOutlinedIcon color="primary" />}
      >
        <BackButton className={classes.backButton} url="/home" />
      </PageHeader>
      <PageContent>
        <Grid style={{ marginBottom: 24 }} container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="h5">{tableTitles[tableToUse]}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Pagination
              disabled={false}
              limit={limit}
              start={start}
              onChangeLimit={setLimit}
              onChangeStart={setStart}
              totalRows={!!tableItems.length ? tableItems[0].count : 0}
            />
          </Grid>
        </Grid>
        {loading ? (
          <Spinner fullScreen={true} />
        ) : !!tableItems.length ? (
          <MetcutTable tableItems={tableItems} table={tableToUse} showFooter={false} />
        ) : (
          <Spinner fullScreen={true} />
        )}
      </PageContent>
    </div>
  );
}

export default withStyles(styles)(SupplierAllData);
