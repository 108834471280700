import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";

export default withStyles((theme) => ({
  root: {
    padding: 0,
    alignItems: "start"
  }
}))(ListItem);
