import { createMuiTheme } from "@material-ui/core/styles";
import { green, grey, red, amber, orange } from "@material-ui/core/colors";

const rawTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#044761",
      hover: "#ccdee6"
    },
    secondary: {
      main: "#259E33",
      hover: "#daf0dc"
    },
    warning: {
      main: "#ffc071"
    },
    success: {
      main: "#009577"
    },
    info: {
      main: "#1883AD",
      orig: "#1883AD",
      dark: "#135771"
    },
    error: {
      main: "#9E1C36",
      hover: "#f5dce1"
    }
  },
  typography: {
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700
  }
});

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      main: "#1B465E",
      text: "#FFF"
    }
  },
  typography: {
    ...rawTheme.typography,
    h1: {
      ...rawTheme.typography.h1,
      fontSize: 60,
      color: "#212121"
    },
    h5: {
      // Subheader
      ...rawTheme.typography.h5,
      fontSize: 32,
      fontWeight: rawTheme.typography.fontWeightMedium,
      color: rawTheme.palette.info.main
    },
    h6: {
      ...rawTheme.typography.h6,
      fontSize: 24,
      fontWeight: rawTheme.typography.fontWeightMedium,
      color: rawTheme.palette.primary.main
    }
  },
  buttons: {
    splashScreenBack: {
      color: "#FFF",
      textTransform: "auto"
    },
    back: {
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightRegular,
      padding: rawTheme.spacing(0, 0.5)
    },
    red: {
      color: rawTheme.palette.error.main,
      borderColor: rawTheme.palette.error.main,
      borderWidth: 3,
      "&:hover": {
        borderWidth: 3,
        backgroundColor: rawTheme.palette.error.hover
      }
    }
  },
  utils: {
    flexCenter: {
      display: "flex",
      alignItems: "center"
      // justifyContent: "space-between"
    }
  },
  containers: {
    halfScreen: {
      [rawTheme.breakpoints.up("sm")]: {
        maxWidth: 450
      }
    }
  },
  overrides: {
    MuiPaper: {
      elevation8: {
        backgroundColor: "#ffffff"
      }
    }
  }
};

export default theme;
