import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import Modal from "components/Modal";
import Spinner from "components/Spinner";

const styles = (theme) => ({
  red: { color: theme.palette.error.main },
  green: { color: theme.palette.secondary.main },
  bold: { fontWeight: theme.typography.fontWeightMedium },
  supplierName: { marginBottom: theme.spacing(3) }
});

const MakeUserAdminModal = ({ classes, onClose, user, supplier, loading, handleConfirm }) => {
  console.log("[USER]: ", user);
  return (
    <Modal
      open={true}
      title="Make This User Account Admin"
      actions={
        <>
          {loading && <Spinner size={20} />}

          <Button variant="text" onClick={onClose} disabled={loading}>
            Cancel
          </Button>

          {loading ? (
            <Spinner />
          ) : (
            <Button className={classes.green} variant="text" disabled={loading} onClick={handleConfirm}>
              Approve
            </Button>
          )}
        </>
      }
    >
      <Typography>
        Are you sure you want to make {user.first_name} {user.last_name} the account administrator for {supplier.name}?
      </Typography>
    </Modal>
  );
};

export default withStyles(styles)(MakeUserAdminModal);
