import React from "react";
import { Link, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CheckmarkIcon from "@material-ui/icons/CheckSharp";
import ChevronRight from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";

import RoundedButton from "components/Buttons/RoundedButton";
import ContextMenu from "components/ContextMenu";
import Spinner from "components/Spinner";
import TableCell from "components/Tables/TableCell";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import DisableAcctModal from "./children/DisableAcctModal";
import ApproveAcctModal from "./children/ApproveAcctModal";
import DenyAcctModal from "./children/DenyAcctModal";
import { useAPI, RESOURCES } from "utils/api";
import { useGlobalUI } from "context/globalUI";

const styles = (theme) => ({
  autocomplete: {
    marginTop: theme.spacing(7)
  },
  autocompletePaper: {
    background: theme.palette.common.white
  },
  table: {
    marginTop: theme.spacing(1.25)
  },
  tableTitle: {
    marginBottom: theme.spacing(3)
  },
  blue: {
    color: theme.palette.info.main
  },
  green: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  red: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  checkmark: {
    marginLeft: theme.spacing(4),
    color: theme.palette.info.main
  },
  footer: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& > *": {
      marginRight: theme.spacing(4)
    }
  },
  footerText: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium
  }
});

function Administration({ classes }) {
  const [suppliers, setSuppliers] = React.useState([]);
  const [selectedSupplier, setSelectedSupplier] = React.useState(null);
  const [actionAcctModal, setActionAcctModal] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const api = useAPI();
  const history = useHistory();
  const { navDrawer } = useGlobalUI();

  async function fetchSuppliers() {
    setLoading(true);
    let result;

    try {
      result = await api.callWithAuth({ method: "GET", url: RESOURCES.companies });
    } catch (e) {
      return;
    } finally {
      setLoading(false);
    }

    setSuppliers(result);
  }

  React.useEffect(() => {
    fetchSuppliers();
  }, []);

  function handleSupplierSelect(evt, value) {
    return history.push(`/admin/suppliers/${value.id}`);
  }

  function openActionAcctModal({ supplier, modal }) {
    setSelectedSupplier(supplier);
    setActionAcctModal(modal);
  }
  function closeActionAcctModal() {
    setActionAcctModal(null);
    setSelectedSupplier(null);
  }

  function setContextOptions(supplier) {
    if (supplier.active) {
      return [
        {
          name: "Manage Account",
          icon: <ChevronRight className={classes.blue} />,
          itemHandler: () => history.push(`/admin/suppliers/${supplier.id}`)
        },
        {
          name: "Disable Supplier Acct.",
          icon: <CloseIcon color="error" />,
          itemHandler: () => openActionAcctModal({ supplier, modal: "disable" })
        }
      ];
      //TODO: add correct logic based on acct. status
    } else {
      return [
        {
          name: "Approve Account",
          icon: <AddIcon color="secondary" />,
          itemHandler: () => openActionAcctModal({ supplier, modal: "approve" })
        },
        {
          name: "Deny Account",
          icon: <CloseIcon color="error" />,
          itemHandler: () => openActionAcctModal({ supplier, modal: "deny" })
        }
      ];
    }
  }

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader
        title="Suppliers"
        hint="Use the text field to enter the vendor code of the Supplier you wish to view. Use the context menu on each Supplier to manage their account and the users associated with that Supplier's account."
      >
        {suppliers.length <= 0 && loading ? (
          ""
        ) : (
          <Autocomplete
            options={suppliers}
            getOptionLabel={(supplier) => `${supplier.ge_vendor_code ? `${supplier.ge_vendor_code} - ` : ""} ${supplier.name}`}
            renderInput={(params) => <TextField {...params} label="Search by Name or Vendor Code" variant="outlined" fullWidth />}
            value={selectedSupplier}
            onChange={handleSupplierSelect}
            classes={{ root: classes.autocomplete, paper: classes.autocompletePaper }}
          />
        )}
      </PageHeader>
      <PageContent>
        <Typography className={classes.tableTitle} variant="h5">
          All Suppliers
        </Typography>

        {suppliers.length <= 0 && loading ? (
          <Spinner fullScreen={true} />
        ) : (
          <>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Supplier</TableCell>
                  <TableCell>GE Vendor Code</TableCell>
                  <TableCell>Number of Users</TableCell>
                  <TableCell>Acct. Setup Complete</TableCell>
                  <TableCell>Account Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {suppliers.map((supplier) => {
                  return (
                    <TableRow key={supplier.id}>
                      <TableCell>{supplier.name}</TableCell>
                      <TableCell>{supplier.ge_vendor_code}</TableCell>
                      <TableCell style={{ paddingLeft: 20 }}>{supplier.users_count}</TableCell>
                      <TableCell>
                        {supplier.account_setup_complete ? <CheckmarkIcon className={classes.checkmark} /> : ""}
                      </TableCell>
                      <TableCell style={{ paddingLeft: 20 }}>
                        {supplier.active ? (
                          <span className={classes.green}>Active</span>
                        ) : (
                          <span className={classes.red}>Disabled</span>
                        )}
                      </TableCell>
                      <TableCell>
                        <ContextMenu options={setContextOptions(supplier)} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {/* <div className={classes.footer}>
              <Typography color="primary" className={classes.footerText}>
                Showing 5 of 25
              </Typography>
              <RoundedButton color="primary" size="small" component={Link} to="/home/all/procedures/accepted">
                View All
              </RoundedButton>
            </div>{" "} */}
          </>
        )}
      </PageContent>
      {actionAcctModal === "disable" && <DisableAcctModal supplier={selectedSupplier} onClose={closeActionAcctModal} />}
      {actionAcctModal === "approve" && <ApproveAcctModal supplier={selectedSupplier} onClose={closeActionAcctModal} />}
      {actionAcctModal === "deny" && <DenyAcctModal supplier={selectedSupplier} onClose={closeActionAcctModal} />}
    </div>
  );
}

export default withStyles(styles)(Administration);
