import React, { Component } from "react";
import * as Sentry from "@sentry/browser";

import Splash from "./Splash";

const styles = {
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  paper: {
    padding: 20,
    width: "95%",
    maxWidth: 600,
    marginTop: "-8%",
    textAlign: "center",
    backgroundColor: "#fff",
    borderRadius: 3,
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    color: "#272727",
    opacity: 0.85
  },
  button: {
    borderRadius: 3,
    maxWidth: 150,
    margin: "40px auto 28px auto",
    padding: "12px 18px",
    color: "#fff",
    backgroundColor: "#044761",
    cursor: "pointer"
  }
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
        scope.setUser({
          username: JSON.parse(localStorage.getItem("username"))
        });
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { children } = this.props;

    if (this.state.error) {
      return (
        <div style={styles.root}>
          <Splash />
          <div style={styles.paper}>
            <h1 variant="h6">It's us, not you...</h1>
            <h3>Something went wrong on our end - sorry about that!</h3>
            <p>
              If you'd like, submit feedback to let us know what you were trying to do when the error occurred. Also try
              refreshing the page to see if that helps.
            </p>
            <div style={styles.button} onClick={() => Sentry.showReportDialog()}>
              Provide Feedback
            </div>
          </div>
        </div>
      );
    } else {
      // no error
      return children;
    }
  }
}

export default ErrorBoundary;
