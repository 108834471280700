import React from "react";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import RoundedButton from "components/Buttons/RoundedButton";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import { useGlobalUI } from "context/globalUI";

/*
const styles = (theme) => ({
  card: {
    borderRadius: 4,
    border: "1px solid rgba(112, 112, 112, 0.6)",
    textAlign: "center",
    width: "80%",
    margin: "0 auto",
    padding: theme.spacing(6),
    transition: "all .3s ease-in-out",

    "&:hover": {
      borderColor: "transparent",
      boxShadow: theme.shadows[4]
    }
  },
  text: {
    [theme.breakpoints.up("md")]: {
      minHeight: 200
    }
  },
  heading: {
    fontWeight: theme.typography.fontWeightRegular
  }
});
*/

function New({ classes }) {
  // const { navDrawer } = useGlobalUI();

  // MT-560: Remove create page
  return <Redirect to="/new/order" />;

  /*
  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader title="Create" hintSetting="infobox_order_create">
        <Typography variant="h5">What would you like to start with?</Typography>
      </PageHeader>

      <PageContent>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box className={classes.card}>
              <Typography variant="h5" paragraph className={classes.heading}>
                Order
              </Typography>
              <Typography paragraph className={classes.text}>
                You can begin a new order with a pre-existing coating or procedure. You can also start from scratch with a new
                coating or procedure.
              </Typography>
              <RoundedButton color="primary" component={Link} to="/new/order">
                Select
              </RoundedButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.card}>
              <Typography variant="h5" paragraph className={classes.heading}>
                Coating
              </Typography>
              <Typography paragraph className={classes.text}>
                Simply create a new coating to add to your company records. You can begin a new order with this item at any point
                in the future.
              </Typography>
              <RoundedButton color="primary" component={Link} to="/new/coating">
                Select
              </RoundedButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.card}>
              <Typography variant="h5" paragraph className={classes.heading}>
                Procedure
              </Typography>
              <Typography paragraph className={classes.text}>
                Simply create a new procedure to add to your company records. You can begin a new order with this item at any
                point in the future.
              </Typography>
              <RoundedButton color="primary" component={Link} to="/new/procedure">
                Select
              </RoundedButton>
            </Box>
          </Grid>
        </Grid>
      </PageContent>
    </div>
  );
  */
}

export default New;
