import React from "react";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { useAPI, RESOURCES } from "utils/api";
import RoundedButton from "components/Buttons/RoundedButton";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import Spinner from "components/Spinner";
import { useGlobalUI } from "context/globalUI";

const collabsiblePaperStyles = (theme) => ({
  paper: {
    background: "#dce5e9",
    marginBottom: theme.spacing(2)
  },
  question: {
    cursor: "pointer",
    userSelect: "none",
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightMedium
  },
  answer: {
    padding: theme.spacing(2)
  }
});

const faqStyles = (theme) => ({
  backButton: theme.buttons.back
});

const CollapsiblePaper = withStyles(collabsiblePaperStyles)(({ classes, question, children }) => {
  const [open, setOpen] = React.useState(false);

  function toggle() {
    setOpen(!open);
  }

  return (
    <Paper elevation={0} className={classes.paper}>
      <div className={classes.question} onClick={toggle}>
        {question}
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </div>
      <Collapse in={open}>
        <div className={classes.answer}>{children}</div>
      </Collapse>
    </Paper>
  );
});

function FAQ({ classes }) {
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const api = useAPI();
  const { navDrawer } = useGlobalUI();

  async function fetchItems() {
    setLoading(true);
    const result = await api.callWithAuth({ method: "GET", url: RESOURCES.faq });
    setItems(result);
    setLoading(false);
  }

  React.useEffect(() => {
    fetchItems();
  }, []);

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader
        title="Frequently Asked Questions"
        hint="Check out the videos for a little guidance through the coating or procedure submission process."
        breadcrumbs={["Support", "Frequently Asked Questions"]}
      >
        <Button component={Link} to="/support" startIcon={<ChevronLeftIcon />} className={classes.backButton} color="primary">
          Back
        </Button>
      </PageHeader>

      <PageContent>
        {loading ? (
          <Spinner />
        ) : (
          <Grid container>
            <Grid item sm={12} md={8}>
              {items.map(({ id, question, answer }) => (
                <CollapsiblePaper question={question}>
                  <div dangerouslySetInnerHTML={{ __html: answer }} />
                </CollapsiblePaper>
              ))}
            </Grid>
          </Grid>
        )}
      </PageContent>
    </div>
  );
}

export default withStyles(faqStyles)(FAQ);
