import React from "react";

import { useAPI, RESOURCES } from "utils/api";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(9),
    position: "relative"
  },
  divider: {
    height: 5,
    backgroundColor: theme.palette.primary.main,
    border: "none"
  },
  copyRightText: {
    textAlign: "center"
  }
});

let footerTextGlobal;
let footerTextLoadingGlobal = false;

const Footer = ({ classes }) => {
  const [footerText, setFooterText] = React.useState(footerTextGlobal);

  const api = useAPI();

  async function loadFooterText() {
    try {
      footerTextLoadingGlobal = true;
      const response = await api.callWithAuth({ method: "GET", url: RESOURCES.settings("global_footer") });
      footerTextGlobal = response.value.replace("[[Year]]", new Date().getFullYear());
      setFooterText(footerTextGlobal);
    } catch {
      footerTextGlobal = `Copyright ${new Date().getFullYear()} - Metcut`;
      setFooterText(footerTextGlobal);
    } finally {
      footerTextLoadingGlobal = false;
    }
  }

  React.useEffect(() => {
    if (!footerTextGlobal && !footerTextLoadingGlobal) {
      // Only load it for the first time
      loadFooterText();
    }
  }, []);

  return (
    <footer className={classes.root}>
      <hr className={classes.divider} />
      <p className={classes.copyRightText}>{footerText}</p>
    </footer>
  );
};

export default withStyles(styles)(Footer);
