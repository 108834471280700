import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";

import { formatISODate } from "utils/format";
import { useAPI, RESOURCES } from "utils/api";
import RoundedButton from "components/Buttons/RoundedButton";
import TableCell from "components/Tables/TableCell";
import Spinner from "components/Spinner";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import BackButton from "components/Buttons/BackButton";
import SupplierInfoGrid from "./children/SupplierInfoGrid";
import DeleteUserModal from "./children/DeleteUserModal";
import Suppliers from "pages/Suppliers";
import { useGlobalUI } from "context/globalUI";

const styles = (theme) => ({
  pageSubHead: {
    marginBottom: theme.spacing(3)
  },
  supplierName: {
    marginBottom: theme.spacing(4)
    // color: theme.palette.info.main
  },
  subheader: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),

    "& > :first-child": {
      marginRight: theme.spacing(4)
    }
  },
  label: {
    // fontWeight: theme.typography.fontWeightMedium
  },
  value: {
    fontWeight: theme.typography.fontWeightLight
  },
  deleteIcon: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(2.5)
  },
  usersTableHeader: {
    marginTop: theme.spacing(5)
  },
  table: {
    margin: theme.spacing(3, 0, 10, 0),
    width: 960
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium
  }
});

function ManageSupplierAcct({ classes }) {
  const api = useAPI();
  const params = useParams();
  const { navDrawer } = useGlobalUI();

  const [loading, setLoading] = React.useState(true);
  const [companyId, setCompanyId] = React.useState(params.company_id ? +params.company_id : null);
  const [supplier, setSupplier] = React.useState(null);
  const [supplierUsers, setSupplierUsers] = React.useState(null);
  const [deleteUser, setDeleteUser] = React.useState(false);

  React.useEffect(() => {
    if (companyId) {
      fetchSupplier(companyId);
    }
  }, []);

  async function fetchSupplier(company_id) {
    const supplier = await api.callWithAuth({ method: "GET", url: RESOURCES.currentCompany(company_id) });
    const supplierUsers = await api.callWithAuth({ method: "GET", url: RESOURCES.companyUsers(company_id) });
    setSupplier(supplier);
    setSupplierUsers(supplierUsers);
    setLoading(false);
  }

  const openDeleteModal = (user) => () => setDeleteUser(user);
  const closeDeleteModal = () => setDeleteUser(null);

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader
        title="Manage Supplier Account"
        hint="Use the text field to enter the vendor code of the supplier you wish to view. Interact with supplier's orders, coatings, and procedures."
        breadcrumbs={["Administration", "Manage Account"]}
      >
        <BackButton url={"/admin"} />
      </PageHeader>
      <PageContent>
        {loading || !supplier ? (
          <Spinner />
        ) : (
          <>
            <Typography className={classes.supplierName} variant="h3">
              {supplier.name}
            </Typography>
            <SupplierInfoGrid companyInfo={supplier} classes={classes} />

            <Typography className={classes.usersTableHeader} variant="h5">
              All Users Associated With This Account
            </Typography>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Date Added</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {supplierUsers.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>
                      {user.full_name} {user.role === "CompanyAdmin" ? <span>- Company Admin</span> : ""}
                    </TableCell>
                    <TableCell>{formatISODate(user.created)}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        color="secondary"
                        component={Link}
                        to={`/admin/suppliers/${companyId}/users/${user.id}/edit`}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton size="small" className={classes.deleteIcon} onClick={openDeleteModal(user)}>
                        <CloseIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </PageContent>
      {deleteUser && (
        <DeleteUserModal user={deleteUser} companyId={companyId} onClose={closeDeleteModal} onSuccess={fetchSupplier} />
      )}
    </div>
  );
}

export default withStyles(styles)(ManageSupplierAcct);
