import React from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Spinner from "components/Spinner";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    height: 300,
    overflowY: "auto",
    border: `1px solid #707070`,
    borderRadius: 4
  },
  item: {
    "&$itemSelected": {
      backgroundColor: "rgba(24, 131, 173, .35)"
    }
  },
  itemSelected: {}
});

const CoatingSpecList = ({ classes, items, selected, disabled, loading, powder, onSelect, ...props }) => {
  const handleClick = (item) => () => {
    onSelect(item);
  };

  return (
    <List className={classes.root}>
      {loading ? (
        <Spinner />
      ) : (
        items.map((item) => (
          <ListItem
            classes={{ root: classes.item, selected: classes.itemSelected }}
            key={powder ? item._UniquePowderID : item._UniqueCoatingID}
            button
            selected={powder ? !!selected[item._UniquePowderID] : selected && item._UniqueCoatingID === selected._UniqueCoatingID}
            disabled={disabled}
            onClick={handleClick(item)}
          >
            <ListItemText primary={powder ? item._UniquePowderID : item._UniqueCoatingID} />
          </ListItem>
        ))
      )}
    </List>
  );
};

export default withStyles(styles)(CoatingSpecList);
