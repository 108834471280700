import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";

import { useOrder } from "context/order";
import { useAuth } from "context/auth";
import { useUserCompany } from "context/userCompany";
import { parseQueryString } from "utils/qs";
import { API_URL, useAPI, RESOURCES } from "utils/api";
import RoundedButton from "components/Buttons/RoundedButton";
import BackButton from "components/Buttons/BackButton";
import PageHeader from "components/PageHeader";
import PageContent from "components/PageContent";
import Spinner from "components/Spinner";
import Receipt from "./children/Receipt";
import { useGlobalUI } from "context/globalUI";

const styles = (theme) => ({
  green: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 20
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium
  },
  orderGuid: {
    fontSize: 20
  },
  orderIdLabel: {
    fontWeight: theme.typography.fontWeightMedium
  },
  viewReceiptButton: {
    display: "flex",
    alignItems: "center"
  },
  light: {
    fontWeight: theme.typography.fontWeightLight
  },
  summary: {
    display: "flex",
    justifyContent: "space-between"
  },
  address: {
    fontSize: 20
  }
});

function ViewOrderReceipt({ classes }) {
  const { companyInfo, user, updateData } = useUserCompany();
  const location = useLocation();
  const params = useParams();
  const { navDrawer } = useGlobalUI();
  const api = useAPI();
  const auth = useAuth();

  const [orderId, setOrderId] = useState(params.id ? params.id : "null");
  const [order, setOrder] = useState(null);
  const [isAdmin, setIsAdmin] = useState(user.role === "SuperAdmin" || user.role === "SystemAdmin" || user.role === "MetcutUser");
  const [supplierID, setSupplierID] = useState(parseQueryString(location.search).supplierID);
  const [receiptOpen, setReceiptOpen] = useState(false);
  const [loadingMetAddress, setLoadingMetAddress] = useState(true);
  const [metcutAddress, setMetcutAddress] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subTotal, setSubTotal] = useState(0);
  const [calculatedTax, setCalculatedTax] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [shippingMethod, setShippingMethod] = useState(null);
  const [error, setError] = React.useState(null);

  useEffect(() => {
    if (orderId) {
      fetchOrder(orderId);
    }
    fetchMetcutAddress();
  }, []);

  async function fetchOrder(id) {
    const url = isAdmin && supplierID ? RESOURCES.adminViewOrder(supplierID, id) : RESOURCES.viewOrder(id);
    try {
      const response = await api.callWithAuth({ method: "GET", url: url });
      setOrder(response);
      setShippingMethod(response.shipping_method_name);
      calculateTotals(response);
    } catch (e) {
      setLoading(false);
      return;
    }
  }

  function calculateTotals(response) {
    const shippingPrice = response.shipping_method_price ? response.shipping_method_price : 0;
    const sub = response.coatings.concat(response.procedures).reduce((acc, curr) => {
      acc += curr.price;
      return acc;
    }, 0);
    const calcTax = ((sub + shippingPrice) * response.sales_tax).toFixed(2);

    setSubTotal((sub + shippingPrice).toFixed(2));
    setCalculatedTax(calcTax);
    setGrandTotal((Number(calcTax) + Number(sub) + shippingPrice).toFixed(2));
    setLoading(false);
  }

  async function fetchMetcutAddress() {
    try {
      const response = await api.callWithAuth({ method: "GET", url: RESOURCES.settings("shipping_address") });
      setMetcutAddress(response.value);
    } catch (e) {
      return;
    }
    setLoadingMetAddress(false);
  }

  //const { sendSystemMessage } = require('/utils/email');

  function toggleReceipt() {
    const url = isAdmin && supplierID ? RESOURCES.adminViewOrderPDF(supplierID, orderId) : RESOURCES.viewOrderPDF(orderId);
    window.open(`${API_URL}${url}?access_token=${auth.getToken()}`, "_blank").focus();
  }

  const resendEmail = async () => {
    setError(null);
    setLoading(true);

    try {
      await api.callWithAuth({
        method: "POST",
        url: RESOURCES.resendOrderRec(order.id, grandTotal),
        successMessage: `Order ${order.id} was successfully emailed!`
      });
    } catch (e) {
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader title="View Order & Receipt">
        <BackButton goBack={true} />
      </PageHeader>

      <PageContent>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {!!isAdmin && (
              <Box mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h3">{order.company_name}</Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h4" paragraph>
                    {order.submitted ? `Order # ${order.id}` : `NOT Submitted`}
                  </Typography>
                  <Typography className={classes.orderGuid}>
                    <span className={classes.orderIdLabel}>Order id: </span>
                    {order.guid}
                  </Typography>
                </Grid>

                <Grid className={classes.viewReceiptButton} item sm={6} xs={12}>
                  <RoundedButton onClick={toggleReceipt} color="secondary" size="large">
                    Download Receipt
                  </RoundedButton>
                  <RoundedButton onClick={resendEmail} color="secondary" size="large">
                    Resend Email
                  </RoundedButton>
                </Grid>
              </Grid>
            </Box>

            <Typography variant="h5" paragraph>
              Order Details
            </Typography>

            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Typography variant="h6" color="primary">
                  Payment Details
                </Typography>
                <Typography>Card Number:</Typography>
                <Typography className={classes.address}>**** **** **** {order.stripe_last4}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" color="primary">
                  Order Contact
                </Typography>
                <Typography className={classes.address}>
                  {order.user_first_name} {order.user_last_name}
                  <br />
                  {order.user_email}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="h6" color="primary">
                  Billing Address
                </Typography>
                <Typography className={classes.address}>
                  {order.addresses.billing.company_name}
                  <br />
                  {order.addresses.billing.address1}
                  {order.addresses.billing.address2 ? `, ${order.addresses.billing.address2}` : ""}
                  <br />
                  {`${order.addresses.billing.city} ${order.addresses.billing.region}, ${order.addresses.billing.country}, ${order.addresses.billing.postal_code}`}
                </Typography>
              </Grid>

              {order.coatings.length >= 1 && (
                <Grid item xs={4}>
                  <Typography variant="h6" color="primary">
                    Return Shipping Address
                  </Typography>
                  <Typography className={classes.address}>
                    {order.addresses.shipping.company_name}
                    <br />
                    {order.addresses.shipping.address1}
                    {order.addresses.shipping.address2 ? `, ${order.addresses.shipping.address2}` : ""}
                    <br />
                    {`${order.addresses.shipping.city} ${order.addresses.shipping.region}, ${order.addresses.shipping.country}, ${order.addresses.shipping.postal_code}`}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={4}>
                <Typography variant="h6" color="primary">
                  Grand Total: {`$${grandTotal}`}
                </Typography>
              </Grid>
            </Grid>
            {receiptOpen && (
              <Receipt
                onClose={toggleReceipt}
                order={order}
                noCoatings={order.coatings.length <= 0}
                grandTotal={grandTotal}
                subTotal={subTotal}
                calculatedSalesTax={calculatedTax}
                metcutAddress={metcutAddress}
              />
            )}
          </>
        )}
      </PageContent>
    </div>
  );
}

export default withStyles(styles)(ViewOrderReceipt);
