import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { useAPI, RESOURCES } from "utils/api";
import { useGlobalUI } from "context/globalUI";
import { capitalizeFirstLetter } from "utils/format";
import Modal from "components/Modal";
import Spinner from "components/Spinner";

const styles = (theme) => ({
  red: { color: theme.palette.error.main },
  bold: { fontWeight: theme.typography.fontWeightMedium }
});

const DenyPriorityModal = ({ classes, onClose, itemType, orderItemId, tableType }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const api = useAPI();
  const { approveDenyRequest, setApproveDenyRequest } = useGlobalUI();

  const handleConfirm = async () => {
    setLoading(true);
    setError(null);

    try {
      await api.callWithAuth({
        method: "POST",
        url: RESOURCES.rejectPriority(orderItemId),
        successMessage: `${capitalizeFirstLetter(itemType)} was successfully denied for priority scheduling.`
      });
    } catch (e) {
      setLoading(false);
      return;
    }
    setApproveDenyRequest(!approveDenyRequest);
    onClose();
  };

  return (
    <Modal
      open={true}
      title={`Deny Priority Scheduling For This ${capitalizeFirstLetter(itemType)}`}
      actions={
        <>
          <Button variant="text" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          {loading ? (
            <Spinner />
          ) : (
            <Button className={classes.red} variant="text" onClick={handleConfirm}>
              Deny
            </Button>
          )}
        </>
      }
    >
      <Typography paragraph>
        Are you sure you want to deny this priority scheduling request for this item? Upon rejecting, the administrator for this
        supplier account will receive an email informing them of the rejection. No more action is needed.
      </Typography>
    </Modal>
  );
};

export default withStyles(styles)(DenyPriorityModal);
