import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { useAPI, RESOURCES } from "utils/api";
import { HEADER_HEIGHT } from "./Header";
import Spinner from "./Spinner";

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: `calc(${HEADER_HEIGHT}px - 20px)`
  },
  hint: {
    borderRadius: 4,
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    color: theme.palette.info.main,
    opacity: ".9",
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    display: "flex",
    alignItems: "center",
    "& > :last-child": {
      marginLeft: theme.spacing(2)
    },
    border: "1px solid rgba(112,112,112,0.15)",
    boxShadow: "-3px 3px 12px rgba(0,0,0,0.16)"
  },
  breadcrumbs: {
    fontWeight: theme.typography.fontWeightLight,
    color: "#707070"
  },
  crumbSeperator: {
    margin: "0px 10px"
  }
});

const PageHeader = ({ classes, title, children, hint, hintSetting, hintIcon, breadcrumbs }) => {
  const [hintFromSettings, setHintFromSettings] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const api = useAPI();

  React.useEffect(() => {
    const getHint = async () => {
      const response = await api.callWithAuth({ method: "GET", url: RESOURCES.settings(hintSetting) });
      setHintFromSettings(response.value);
      // setLoading(false)
    };

    if (hintSetting) {
      getHint();
    }
  }, [hintSetting]);

  React.useEffect(() => {
    if (!title) {
      document.title = "Metcut CCL";
    } else {
      document.title = `${title} – Metcut CCL`;
    }
  }, [title]);

  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item sm={6} xs={12}>
        <Typography variant="h1" gutterBottom>
          {title}
        </Typography>

        {/* {breadcrumbs && <Typography className={classes.breadcrumbs}>{breadcrumbs}</Typography>} */}
        {breadcrumbs &&
          breadcrumbs.map((crumb, idx) => {
            const isLast = idx >= breadcrumbs.length - 1;
            return isLast ? (
              <Typography key={idx} display="inline" className={classes.breadcrumbs}>
                {crumb}
              </Typography>
            ) : (
              <span key={idx}>
                <Typography display="inline" className={classes.breadcrumbs}>
                  {crumb}
                  <span className={classes.crumbSeperator}>></span>
                </Typography>
              </span>
            );
          })}
        <div>{children}</div>
      </Grid>

      {hintFromSettings && !hint && (
        <Grid item sm={6} xs={12}>
          <Box className={classes.hint}>
            {hintIcon}
            <Box dangerouslySetInnerHTML={{ __html: hintFromSettings }} />
          </Box>
        </Grid>
      )}
      {hint && (
        <Grid item sm={6} xs={12}>
          <Box className={classes.hint}>
            {hintIcon}
            <Box>{hint}</Box>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

PageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  hint: PropTypes.node
};

export default withStyles(styles)(PageHeader);
