import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import { useAPI, RESOURCES } from "utils/api";
import Modal from "components/Modal";
import Spinner from "components/Spinner";

const styles = (theme) => ({
  red: { color: theme.palette.error.main },
  bold: { fontWeight: theme.typography.fontWeightMedium }
});

const DeleteItemModal = ({ classes, onClose, onSuccess, itemType, itemId }) => {
  const [loading, setLoading] = React.useState(false);

  const api = useAPI();

  const handleConfirm = async () => {
    setLoading(true);
    const itemTypePlural = itemType.concat("s");

    try {
      await api.callWithAuth({
        method: "DELETE",
        url: `${RESOURCES[itemTypePlural]}/${itemId}`,
        successMessage: `The ${itemType.charAt(0).toUpperCase() + itemType.slice(1)} was successfully deleted`
      });
      await onSuccess("items_in_dev");
    } catch (e) {
      setLoading(false);
      return;
    }
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      open={true}
      title={`Delete ${itemType}`}
      actions={
        <>
          <Button variant="text" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          {loading ? (
            <Spinner size={20} />
          ) : (
            <Button className={classes.red} variant="text" disabled={loading} onClick={handleConfirm}>
              {`Delete ${itemType}`}
            </Button>
          )}
        </>
      }
    >
      <Typography paragraph>
        Are you sure you want to delete this {itemType}? Once deleted, the item will be removed from you company's account records
        and will not be able to be accessed again.
      </Typography>
    </Modal>
  );
};

export default withStyles(styles)(DeleteItemModal);
