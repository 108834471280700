import React from "react";
import { BrowserRouter, Route, Switch, Prompt } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ThemeProvider } from "@material-ui/core/styles";
import { Elements } from "@stripe/react-stripe-js";
import { getStripePromise } from "utils/stripe";

import AsyncComponent from "components/HOC/AsyncComponent";
import ProtectedRoute from "components/HOC/ProtectedRoute";
import ErrorBoundary from "components/ErrorBoundary";
import Header from "components/Header";
import Layout from "components/Layout";
import RouterPromptModal from "components/RouterPromptModal";
import theme from "./styles/theme";
import CustomSnackbar from "components/Snackbar";
import FeedbackModal from "components/FeedbackModal";
import TableModals from "components/Tables/TableModals";
import { AuthProvider } from "./context/auth";
import { UserCompanyProvider } from "./context/userCompany";
import { GlobalUIProvider } from "./context/globalUI";
import { OrderProvider } from "./context/order";
import { TablesProvider } from "./context/tables";
import GoogleAnalytics from "./context/GoogleAnalytics";
import ROUTES from "utils/routes";

import Index from "./pages/Index";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Home from "./pages/Home";
import AllData from "./pages/All";
import MetcutHome from "./pages/MetcutHome";
import ViewRequest from "./pages/MetcutHome/ViewRequest";
import Suppliers from "./pages/Suppliers";
import ViewSupplier from "./pages/Suppliers/ViewSupplier";
import Administration from "./pages/Administration";
import ManageSupplierAcct from "./pages/Administration/ManageSupplierAcct";
import AdminEditSupplierUser from "./pages/Administration/EditSupplierUser";
import AdminEditSupplierInfo from "./pages/Administration/EditSupplierInfo";
import Account from "./pages/Account";
import AccountEditInformation from "./pages/Account/EditInformation";
import AccountEditCompanyInformation from "./pages/Account/EditCompanyInformation";
import AccountEditShippingAddress from "./pages/Account/EditShippingAddress";
import AccountEditBillingAddress from "./pages/Account/EditBillingAddress";
import AccountEditPreferredCarrier from "./pages/Account/EditPreferredCarrier";
import ManageUsers from "./pages/ManageUsers";
import ManageUsersEdit from "./pages/ManageUsers/EditUser";
import EditAdmin from "./pages/ManageUsers/EditAdmin";
import Support from "./pages/Support";
import SupportFAQ from "./pages/Support/FAQ";
import Payment from "./pages/Miscellaneous/Payment";
import New from "./pages/New";
import Order from "./pages/Order";
import ViewOrderReceipt from "./pages/Miscellaneous/ViewOrderReceipt";
import OrderEditProcedure from "./pages/Order/EditProcedure";
import OrderEditCoating from "./pages/Order/EditCoating";
import OrderTestResults from "./pages/Order/TestResults";
import OrderTestResultsDeviation from "./pages/Order/TestResultsDeviation";
import OrderReview from "./pages/Order/Review";
import OrderCheckout from "./pages/Order/Checkout";
import OrderConfirmation from "./pages/Order/Confirmation";
import Error from "./pages/Error";
import ErrorForbidden from "./pages/ErrorForbidden";
import ScrollToTop from "components/ScrollToTop";

import "./styles/global.css";

// const AdminAppAsync = AsyncComponent(() => import('apps/Admin'));

const stripePromise = getStripePromise();

function Main() {
  const [showRouterPrompt, setShowRouterPrompt] = React.useState(false);
  const [routerPromptMessage, setRouterPromptMessage] = React.useState("");
  const getUserConfirmationCallback = React.useRef(null);

  function leaveRoute() {
    setShowRouterPrompt(false);
    getUserConfirmationCallback.current(true);
  }

  function stayOnRoute() {
    setShowRouterPrompt(false);
    getUserConfirmationCallback.current(false);
  }

  function getUserConfirmation(message, callback) {
    getUserConfirmationCallback.current = callback;
    setRouterPromptMessage(message);
    setShowRouterPrompt(true);
  }

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <GoogleAnalytics.Provider>
          <BrowserRouter getUserConfirmation={getUserConfirmation}>
            <GoogleAnalytics.RouteTracker />
            <RouterPromptModal message={routerPromptMessage} open={showRouterPrompt} onStay={stayOnRoute} onLeave={leaveRoute} />
            <ScrollToTop />
            <GlobalUIProvider>
              <AuthProvider>
                <CustomSnackbar />
                <FeedbackModal />
                <Header />

                <Switch>
                  <Route exact path={ROUTES.index} component={Index} />
                  <Route exact path={ROUTES.login} component={Login} />
                  <Route exact path={ROUTES.signUp} component={SignUp} />

                  <UserCompanyProvider>
                    <OrderProvider>
                      <TablesProvider>
                        <Elements stripe={stripePromise}>
                          <TableModals />
                          <Layout>
                            <Route
                              render={({ location }) => {
                                return (
                                  <TransitionGroup>
                                    <CSSTransition timeout={300} classNames="fade" key={location.key}>
                                      <Switch location={location}>
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.supplierHome}
                                          component={Home}
                                          permission="coating.edit"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.allData}
                                          component={AllData}
                                          permission="coating.edit"
                                        />

                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.metcutHome}
                                          component={MetcutHome}
                                          permission="admin.dashboard.view"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.viewFullRequest}
                                          component={ViewRequest}
                                          permission="admin.dashboard.view"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.myAccount}
                                          component={Account}
                                          permission="coating.edit"
                                        />
                                        <ProtectedRoute exact path={ROUTES.create} component={New} permission="procedure.edit" />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.yourOrder}
                                          component={Order}
                                          permission="coating.create"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.viewOrderReceipt}
                                          component={ViewOrderReceipt}
                                          permission="coating.view"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.newProcedure}
                                          component={OrderEditProcedure}
                                          permission="procedure.edit"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.editProcedure}
                                          component={OrderEditProcedure}
                                          permission="procedure.edit"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.newCoating}
                                          component={OrderEditCoating}
                                          permission="coating.edit"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.editCoating}
                                          component={OrderEditCoating}
                                          permission="coating.edit"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.testResults}
                                          component={OrderTestResults}
                                          permission="coating.edit"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.testResultsDeviation}
                                          component={OrderTestResultsDeviation}
                                          permission="coating.edit"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.reviewOrder}
                                          component={OrderReview}
                                          permission="coating.edit"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.checkout}
                                          component={OrderCheckout}
                                          permission="coating.edit"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.orderConfirmation}
                                          component={OrderConfirmation}
                                          permission="coating.edit"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.priorityFeePayment}
                                          component={Payment}
                                          permission="coating.edit"
                                        />

                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.suppliers}
                                          component={Administration}
                                          permission="admin.suppliers.view"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.manageSupplierAccount}
                                          component={ManageSupplierAcct}
                                          permission="admin.suppliers.view"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.editSupplierUser}
                                          component={AdminEditSupplierUser}
                                          permission="admin.suppliers.edit"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.editSupplierDetails}
                                          component={AdminEditSupplierInfo}
                                          permission="admin.suppliers.edit"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.orders}
                                          component={Suppliers}
                                          permission="admin.suppliers.view"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.viewSupplier}
                                          component={ViewSupplier}
                                          permission="admin.suppliers.view"
                                        />

                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.editYourDetails}
                                          component={AccountEditInformation}
                                          permission="procedure.view"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.editCompanyDetails}
                                          component={AccountEditCompanyInformation}
                                          permission="account.company.edit"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.editShippingAddress}
                                          component={AccountEditShippingAddress}
                                          permission="account.shipping.edit"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.editBillingAddress}
                                          component={AccountEditBillingAddress}
                                          permission="account.billing.edit"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.editPreferredCarrier}
                                          component={AccountEditPreferredCarrier}
                                          permission="account.shipping.partner.edit"
                                        />

                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.users}
                                          component={ManageUsers}
                                          permission="account.users.list"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.createUser}
                                          component={ManageUsersEdit}
                                          permission="account.users.create"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.editUser}
                                          component={ManageUsersEdit}
                                          permission="account.users.create"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.createAdmin}
                                          component={EditAdmin}
                                          permission="admin.systemadmin.create"
                                        />
                                        <ProtectedRoute
                                          exact
                                          path={ROUTES.editAdmin}
                                          component={EditAdmin}
                                          permission="admin.systemadmin.create"
                                        />

                                        <ProtectedRoute exact path={ROUTES.support} component={Support} />
                                        <ProtectedRoute exact path={ROUTES.faq} component={SupportFAQ} />

                                        <ProtectedRoute exact path={ROUTES.error} component={Error} />
                                        <ProtectedRoute exact path={ROUTES.errorForbidden} component={ErrorForbidden} />
                                        <ProtectedRoute component={Error} />
                                      </Switch>
                                    </CSSTransition>
                                  </TransitionGroup>
                                );
                              }}
                            />
                          </Layout>
                        </Elements>
                      </TablesProvider>
                    </OrderProvider>
                  </UserCompanyProvider>

                  <Route component={Error} />
                </Switch>
              </AuthProvider>
            </GlobalUIProvider>
          </BrowserRouter>
        </GoogleAnalytics.Provider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default Main;
