import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    position: "fixed",
    zIndex: -1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundImage: "url(/assets/splash.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center top"
  }
});

function Splash({ classes }) {
  return <div className={classes.root} />;
}

export default withStyles(styles)(Splash);
