import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { useAPI, RESOURCES } from "utils/api";
import { useGlobalUI } from "context/globalUI";
import Modal from "components/Modal";
import Spinner from "components/Spinner";

const styles = (theme) => ({
  red: {
    color: theme.palette.error.main
  },
  textFieldInput: {
    textAlign: "center",
    color: theme.palette.error.main
  }
});

const DeleteUserModal = ({ classes, user, companyId, onClose, onSuccess }) => {
  const [deleteInput, setDeleteInput] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const api = useAPI();
  const { snackbar } = useGlobalUI();

  const handleDeleteInputChange = ({ target }) => setDeleteInput(target.value);

  const handleConfirm = async () => {
    setLoading(true);

    try {
      await api.callWithAuth({
        method: "DELETE",
        url: RESOURCES.companyUser(companyId, user.id),
        successMessage: "User was successfully deleted."
      });

      await onSuccess(companyId);
    } catch (e) {
      return;
    } finally {
      setLoading(false);
    }

    onClose();
  };

  return (
    <Modal
      open={true}
      title={`Delete ${user.full_name}?`}
      actions={
        <>
          {loading && <Spinner size={20} />}

          <Button variant="text" onClick={onClose} disabled={loading}>
            Cancel
          </Button>

          <Button className={classes.red} variant="text" disabled={loading || deleteInput !== "DELETE"} onClick={handleConfirm}>
            Confirm
          </Button>
        </>
      }
    >
      <Typography paragraph>
        To delete the user, type in the word <span className={classes.red}>DELETE</span> in the text field below, the click
        confirm.
      </Typography>

      <TextField
        autoFocus
        variant="outlined"
        error={true}
        InputProps={{
          classes: {
            input: classes.textFieldInput
          }
        }}
        fullWidth
        value={deleteInput}
        onChange={handleDeleteInputChange}
      />
    </Modal>
  );
};

export default withStyles(styles)(DeleteUserModal);
