import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import Modal from "components/Modal";
import Spinner from "components/Spinner";
import { useAPI } from "utils/api";
import cartTypes from "utils/cartTypes";

const styles = (theme) => ({
  warning: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(2)
  },
  bold: { fontWeight: theme.typography.fontWeightMedium },
  blue: { color: theme.palette.info.main }
});

const NewOrderAccepted = ({ classes, onClose, itemType, itemId, orderInDev, orderItemId }) => {
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();
  const api = useAPI();

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await api.callWithAuth({ method: "POST", url: "/orders/new", axiosOptions: { data: { order_item_id: orderItemId } } });
    } catch (e) {
      setLoading(false);
      return;
    }
    setLoading(false);
    onClose();
    history.push(`/edit/${itemType}s/${itemId}?${cartTypes.new_order_accepted}=1`);
  };

  return (
    <Modal
      open={true}
      title="Create Order For Second Submission"
      actions={
        <>
          <Button variant="text" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          {loading ? (
            <Spinner size={20} />
          ) : (
            <Button className={classes.blue} variant="text" disabled={loading} onClick={handleConfirm}>
              Continue
            </Button>
          )}
        </>
      }
    >
      {orderInDev ? (
        <Typography className={classes.warning}>
          There is currently an order in development. Creating this order for second submission will clear out that order and
          replace it with this one. *Your coatings and procedures will not be lost, but any test or deviation information you've
          already input will be lost.
        </Typography>
      ) : (
        ""
      )}
      <Typography paragraph>
        If you click continue, you will be redirected to the page containing this item's details. You will be able to edit only a
        few fields, then add this item to a new order, and checkout.
      </Typography>
    </Modal>
  );
};

export default withStyles(styles)(NewOrderAccepted);
