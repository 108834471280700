import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";

import { useOrder } from "context/order";
import Modal from "components/Modal";

const styles = (theme) => ({
  red: {
    color: theme.palette.error.main
  }
});

const SavedCoatingModal = ({ classes, onCancel, onAdd }) => {
  const { coatings, fetchCoatings } = useOrder();
  const [selectedCoatingId, setSelectedCoatingId] = React.useState("");

  React.useEffect(() => {
    fetchCoatings();
  }, []);

  function handleChangeCoating({ target }) {
    setSelectedCoatingId(target.value);
  }

  function handleAdd() {
    const selectedCoating = coatings.find(({ id }) => id === selectedCoatingId);
    onAdd(selectedCoating);
  }

  return (
    <Modal
      open={true}
      title="Saved Coating"
      actions={
        <>
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>

          <Button className={classes.red} variant="text" disabled={!selectedCoatingId} onClick={handleAdd}>
            Add To Order
          </Button>
        </>
      }
    >
      <Typography paragraph>Select an existing coating from the drop down, then click 'ADD' to add it to your order.</Typography>

      <FormControl variant="outlined" fullWidth>
        <InputLabel id="select-coating-label-1">Coating</InputLabel>
        <Select
          labelId="select-coating-label-1"
          variant="outlined"
          value={selectedCoatingId}
          label="Coating"
          onChange={handleChangeCoating}
        >
          {coatings.length ? (
            coatings.map(({ id, coating_string }) => (
              <MenuItem key={id} value={id}>
                {coating_string}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No coatings available</MenuItem>
          )}
        </Select>
      </FormControl>
    </Modal>
  );
};

export default withStyles(styles)(SavedCoatingModal);
