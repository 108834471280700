import React from "react";
import { Link, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";

import RoundedButton from "components/Buttons/RoundedButton";
import MetcutTable from "components/Tables/MetcutTable";
import PageContent from "components/PageContent";
import PageHeader from "components/PageHeader";
import Spinner from "components/Spinner";
import OrderDraftBanner from "./children/OrderDraftBanner";
import { useUserCompany } from "context/userCompany";
import { useAPI, RESOURCES } from "utils/api";
import { useGlobalUI } from "context/globalUI";
import { useOrder } from "context/order";
import { useTables } from "context/tables";

const REFRESH_TIMEOUT = 60000;

const styles = (theme) => ({
  welcome: {
    fontSize: 20,
    fontWeight: theme.typography.fontWeightMedium
  },
  subheader: {
    display: "flex",
    alignItems: "baseline",
    marginBottom: theme.spacing(4),

    "& > :first-child": {
      marginRight: theme.spacing(4)
    }
  },
  tableGroup: {
    marginBottom: theme.spacing(6)
  },
  tableTitle: {
    marginBottom: theme.spacing(1.25)
  },
  noData: {
    fontSize: 20,
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    border: "1px solid lightGray",
    borderRadius: 4,
    padding: theme.spacing(2, 4),
    width: "max-content"
  },
  noDataThumbIcon: {
    color: theme.palette.info.main,
    marginLeft: theme.spacing(1)
  }
});

function Home({ classes }) {
  const [loading, setLoading] = React.useState(true);
  const [tables, setTables] = React.useState(null);
  const timeoutRef = React.useRef(null);

  const { user, companyInfo } = useUserCompany();
  const { order } = useOrder();
  const history = useHistory();
  const { callWithAuth } = useAPI();
  const { navDrawer } = useGlobalUI();
  const { tables: allTables } = useTables();

  React.useEffect(() => {
    fetchTables();

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  const orderItemsLength = order ? order.coatings.length + order.procedures.length : 0;
  const MAXIMUM_ALLOWED_ORDER_ITEMS = 1;
  /**
   * Background flag means that no spinner will be shown
   */
  const fetchTables = async (background = false) => {
    clearTimeout(timeoutRef.current);

    if (!background) {
      setLoading(true);
    }

    const res = await callWithAuth({ method: "GET", url: RESOURCES.supplierHomeTables });
    setTables(res);
    setLoading(false);

    timeoutRef.current = setTimeout(() => fetchTables(true), REFRESH_TIMEOUT);
  };

  const handleRefreshRequest = (table) => () => fetchTables(true);

  const orderInDev = order && order.coatings.concat(order.procedures).length >= 1;

  return (
    <div className={navDrawer.isOpen ? "page--drawer-open" : "page--drawer-closed"}>
      <PageHeader title="Home" hintSetting="infobox_home_supplier" hintIcon={<ThumbUpOutlinedIcon color="primary" />}>
        <Typography color="secondary" className={classes.welcome}>
          Welcome {user.first_name} {user.last_name}, {companyInfo.name} user!
        </Typography>
        <Typography>View all of your orders and most up to date information below.</Typography>
      </PageHeader>
      <PageContent>
        {orderInDev && <OrderDraftBanner history={history} />}
        <div className={classes.subheader}>
          <Typography variant="h5">Your Coatings & Procedures</Typography>
          <RoundedButton
            color="primary"
            size="small"
            component={Link}
            disabled={orderItemsLength >= MAXIMUM_ALLOWED_ORDER_ITEMS}
            to={`/new/coating?add=1`}
            startIcon={<AddIcon color="secondary" />}
          >
            Create New Order for Coating
          </RoundedButton>
          <RoundedButton
            color="primary"
            size="small"
            component={Link}
            disabled={orderItemsLength >= MAXIMUM_ALLOWED_ORDER_ITEMS}
            to={`/new/procedure?add=1`}
            startIcon={<AddIcon color="secondary" />}
          >
            Create New Order for Procedure
          </RoundedButton>
        </div>

        <div className={`${classes.tableGroup} ${classes.table1}`}>
          <Typography className={classes.tableTitle} variant="h6">
            Coatings/Procedures - Not Submitted
          </Typography>
          {loading ? (
            <Spinner />
          ) : tables.items_in_dev.length > 0 ? (
            <MetcutTable
              table={allTables.ITEMS_IN_DEV}
              tableItems={tables.items_in_dev}
              usePagination={false}
              showFooter={true}
              onRefreshRequest={handleRefreshRequest(allTables.ITEMS_IN_DEV)}
            />
          ) : (
            <Typography className={classes.noData}>
              No data to display right now <ThumbUpOutlinedIcon className={classes.noDataThumbIcon} />
            </Typography>
          )}
        </div>

        <div className={`${classes.tableGroup} ${classes.table2}`}>
          <Typography className={classes.tableTitle} variant="h6">
            Coatings/Procedures - Submitted
          </Typography>
          {loading ? (
            <Spinner />
          ) : tables.items_submitted.length > 0 ? (
            <MetcutTable
              supplierID={user.company_id}
              table={allTables.ITEMS_SUBMITTED}
              tableItems={tables.items_submitted}
              usePagination={false}
              showFooter={true}
              onRefreshRequest={handleRefreshRequest(allTables.ITEMS_SUBMITTED)}
            />
          ) : (
            <Typography className={classes.noData}>
              No data to display right now <ThumbUpOutlinedIcon className={classes.noDataThumbIcon} />
            </Typography>
          )}
        </div>

        <div className={`${classes.tableGroup} ${classes.table3}`}>
          <Typography className={classes.tableTitle} variant="h6">
            Coating / Procedure History (Past 90 Days)
          </Typography>
          {loading ? (
            <Spinner />
          ) : tables.items_history.length > 0 ? (
            <MetcutTable
              supplierID={user.company_id}
              table={allTables.ITEMS_HISTORY}
              tableItems={tables.items_history}
              usePagination={false}
              showFooter={true}
            />
          ) : (
            <Typography className={classes.noData}>
              No data to display right now <ThumbUpOutlinedIcon className={classes.noDataThumbIcon} />
            </Typography>
          )}
        </div>

        <div className={`${classes.tableGroup} ${classes.table4}`}>
          <Typography className={classes.tableTitle} variant="h6">
            MetcutCCL Accepted Coatings (Past 4 years)
          </Typography>
          {loading ? (
            <Spinner />
          ) : tables.coatings_accepted.length > 0 ? (
            <MetcutTable
              supplierID={user.company_id}
              table={allTables.ACCEPTED_COATINGS}
              tableItems={tables.coatings_accepted}
              usePagination={false}
              showFooter={true}
            />
          ) : (
            <Typography className={classes.noData}>
              No data to display right now <ThumbUpOutlinedIcon className={classes.noDataThumbIcon} />
            </Typography>
          )}
        </div>

        <div className={`${classes.tableGroup} ${classes.table5}`}>
          <Typography className={classes.tableTitle} variant="h6">
            MetcutCCL Accepted Procedures
          </Typography>
          {loading ? (
            <Spinner />
          ) : tables.procedures_accepted.length > 0 ? (
            <MetcutTable
              supplierID={user.company_id}
              table={allTables.ACCEPTED_PROCEDURES}
              tableItems={tables.procedures_accepted}
              usePagination={false}
              showFooter={true}
            />
          ) : (
            <Typography className={classes.noData}>
              No data to display right now <ThumbUpOutlinedIcon className={classes.noDataThumbIcon} />
            </Typography>
          )}
        </div>
      </PageContent>
    </div>
  );
}

export default withStyles(styles)(Home);
